import {
  GET_ALL_TEAMS,
  GET_TEAM_DATA,
  SUCCESS,
  GET_TEAM_DATA_AFFECTED,
  DATA_RECLAMATION_SELECTED
} from './tickets';

const initState = {
  listTeams: [],
  teamsData: {},
  success: false,
  teamInformation: {},
  dataReclamationSelected: {}
};

const listTeams = (state = initState, {type, payload} = {}) => {
  switch (type) {
    case GET_ALL_TEAMS:
      return {...state, listTeams: payload};

    case GET_TEAM_DATA:
      return {...state, teamsData: payload.data, success: true};

    case SUCCESS:
      return {...state, success: false};
    case GET_TEAM_DATA_AFFECTED:
      return {...state, teamInformation: payload.data};
    case DATA_RECLAMATION_SELECTED:
      return {...state, dataReclamationSelected: payload};

    default:
      return state;
  }
};

export default listTeams;
