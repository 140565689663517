import {
  GET_ALL_POSTE_PAYMENT_INVOICES,
  GET_ALL_POSTE_PAYMENT_ORGANISMS,
  GET_COMMERCIAL_PIC,
  GET_INVOICE_PIC
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  organisms: [],
  invoice: [],
  totalPages: 0,
  totalItems: 0,
  totalAmount: 0,
  commercialPic: null,
  invoicePic: null
};

const invoicesReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_POSTE_PAYMENT_ORGANISMS:
      return {
        ...state,
        organisms: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_INVOICE_PIC:
      return {
        ...state,
        invoicePic: action.payload
      };
    case GET_COMMERCIAL_PIC:
      return {
        ...state,
        commercialPic: action.payload
      };
    case GET_ALL_POSTE_PAYMENT_INVOICES:
      return {
        ...state,
        invoice: action.payload.data,
        totalPages: action.payload.totalPages,
        totalAmount: action.payload.totalAmount ? action.payload.totalAmount : 0,
        totalItems: action.payload.totalItems ? action.payload.totalItems : 0
      };

    default:
      return state;
  }
};

export default invoicesReducer;
