// import socketio from 'socket.io-client';
// import {SOCKET_URL} from '../shared/constants/AppConst';
// import React from 'react';

// //useContext hook to provide SocketContext to entire app.
// export const socket = socketio.connect(SOCKET_URL);
// export const SocketContext = React.createContext();

import socketio from 'socket.io-client';
import {SOCKET_URL} from '../shared/constants/AppConst';
import React from 'react';

//useContext hook to provide SocketContext to entire app.
//export const socket = socketio.connect(SOCKET_URL);
// const id_user = localStorage.getItem('idUser');
// socket.emit('send userId', id_user);
// console.log('count notification---------> context id_user', id_user);

// export const SocketContext = React.createContext();
export const SocketContext = React.createContext();
