import {
  GET_ALL_FACIAL,
  GET_ALL_OPERATORS,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_ALL_VOUCHER_DOCUMENTS,
  GET_EMPTY_DOCUMENTS,
  GET_ALL_VOUCHER_SETTINGS,
  GET_ALL_VOUCHER_TRANSACTIONS,
  GET_ALL_VOUCHER_STOCK,
  GET_STOCK_VOUCHER,
  GET_ALL_HISTORY_COMMAND,
  GET_ALL_VOUCHER,
  GET_ALL_TRANSACTION,
  GET_ALL_NOT_INSERD
} from 'shared/constants/ActionTypes';
import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';

export const getAllHistoryCommand = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/order', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: GET_ALL_HISTORY_COMMAND,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditOrder = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put(`/voucher/order/${id}`, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Le statut a été modifié avec succès'
          });
          // let timeout;
          // timeout = setTimeout(() => {
          //   dispatch(getAllHistoryCommand());
          // }, 500);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllFacial = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/facial', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'Facial retrieved') {
            dispatch({
              type: GET_ALL_FACIAL,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_ALL_FACIAL,
              payload: {
                items: [],
                totalPages: 0
              }
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllOperators = (params) => {
  const {messages} = appIntl();

  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/operator', {params: params})
      .then((res) => {
        dispatch({type: FETCH_SUCCESS});
        if (res.status === 200) {
          if (res.data.message === 'Operator retrieved') {
            dispatch({
              type: GET_ALL_OPERATORS,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_ALL_OPERATORS,
              payload: {
                items: [],
                totalPages: 0
              }
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onCreateLotVoucher = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/voucher', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Le document a été importé avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        if (error.response.data.code === '00') {
          dispatch({type: FETCH_ERROR, payload: 'Le fichier est invalide'});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Vous ne pouvez pas importer un fichier pour le moment'
          });
        }
      });
  };
};
export const getAllDocuments = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/document', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'Document retrieved') {
            dispatch({
              type: GET_ALL_VOUCHER_DOCUMENTS,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_EMPTY_DOCUMENTS,
              payload: []
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        // //dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getAllVoucherStock = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/stock/document', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2202) {
            dispatch({
              type: GET_ALL_VOUCHER_STOCK,
              payload: res.data.data
            });
          } else if (res.data.code === 2203) {
            dispatch({
              type: GET_EMPTY_DOCUMENTS,
              payload: {items: [], totalPages: 0}
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onActivateDocument = (id, status) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = {
      status: status
    };
    dispatch({type: FETCH_START});
    dataAxios
      .put(`/voucher/document/${id}`, body)
      .then((data) => {
        if (data.status === 200) {
          if (data.data.status === 'error') {
            if (data.data.code === 2005) {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Vous ne pouvez pas activez ce document'
              });
            }
          } else {
            if (data.data.code === 2011) {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: SHOW_MESSAGE,
                payload: 'Le document a été rejeté avec succès'
              });
            } else if (data.data.code === 2012) {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: SHOW_MESSAGE,
                payload: 'Le document a été accepté avec succès'
              });
            }
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        if (error.response.data.code === '00') {
          dispatch({type: FETCH_ERROR, payload: 'Fichier non valide'});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: "Un erreur s'est produit! Réessayer plus tard"
          });
        }
      });
  };
};
export const onCreateSetting = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/voucher/settings', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        if (error.response.data.code === '2001') {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Setting already exist lpease try another Operator and facial'
          });
        } else {
          dispatch({type: FETCH_ERROR});
        }
      });
  };
};
export const getAllVoucherSettings = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/settings', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'Settings retrieved') {
            dispatch({
              type: GET_ALL_VOUCHER_SETTINGS,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_ALL_VOUCHER_SETTINGS,
              payload: {items: [], totalPages: 0}
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditSetting = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/voucher/settings/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditFacial = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/voucher/facial/' + id, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditOperator = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/voucher/operator/' + id, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteSetting = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/voucher/settings/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteFacial = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/voucher/facial/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteOperatorVoucher = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/voucher/operator/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllVoucherTrancations = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/transaction', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'List of transactions.') {
            dispatch({
              type: GET_ALL_VOUCHER_TRANSACTIONS,
              payload: res.data
            });
            console.log('resdata', res.data);

            dispatch({
              type: FETCH_SUCCESS,
              payload: 'Settings retrieved successfully'
            });
          } else {
            dispatch({
              type: GET_EMPTY_DOCUMENTS,
              payload: []
            });
            dispatch({
              type: FETCH_ERROR,
              payload: 'Pas de données trouvées'
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onValidateVoucherTr = (id, status, typeServiceId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = {
      status: status,
      typeServiceId: typeServiceId
    };
    dispatch({type: FETCH_START});
    dataAxios
      .put(`/voucher/transactionByAdmin/${id}`, body)
      .then((data) => {
        if (data.data.status === 'success') {
          if (data.data.code === 1001) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Transaction rejetée avec succès'
            });
          } else {
            if (data.data.code === 1111) {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: SHOW_MESSAGE,
                payload: 'Transaction crée avec succès'
              });
            }
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        if (error.response.data.code === '00') {
          dispatch({type: FETCH_ERROR, payload: 'Fichier invalide'});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: "Un erreur s'est produit! Réessayez plus tard"
          });
        }
      });
  };
};
export const getStockVoucher = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/getStock', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_STOCK_VOUCHER,
            payload: res.data
          });
          dispatch({
            type: FETCH_SUCCESS,
            payload: 'Settings retrieved successfully'
          });
          // }
          //  else {
          //   // dispatch({
          //   //   type: GET_EMPTY_DOCUMENTS,
          //   //   payload: [],
          //   // });
          //   dispatch({
          //     type: FETCH_ERROR,
          //     payload: 'Pas de données trouvées',
          //   });
          // }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateFacial = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/voucher/facial', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateOperator = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/voucher/operator', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onUpdateBalance = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    //  const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/topup/serialeport/balance')
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetSerial = (serial) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`voucher/serial?serial=${serial}`, {serial: serial})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_TRANSACTION,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
