import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const HotelConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/import/statics-data',
        component: React.lazy(() => import('./Pages/ImportStaticsData'))
      },
      {
        exact: true,
        path: '/Historique-reservation',
        component: React.lazy(() => import('./Pages/History'))
      },
      {
        exact: true,
        path: '/Annulation-reservation',
        component: React.lazy(() => import('./Pages/Annulation'))
      }
    ]
  }
];
