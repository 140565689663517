import initialState from '../../../shared/constants/initialState';
import {ADD_TICKET} from './lists';

export const UPDATE_TICKET = '[ticket] Update ticket';
export const LIST_RECLAMATION = 'LIST_RECLAMATION';
export const GET_ALL_TEAMS = 'GET_ALL_TEAMS';
export const GET_TEAM_DATA = 'GET_TEAM_DATA';
export const SUCCESS = 'SUCCESS';
export const GET_TEAM_DATA_AFFECTED = 'GET_TEAM_DATA_AFFECTED';
export const DATA_RECLAMATION_SELECTED = 'DATA_RECLAMATION_SELECTED';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';

// Action Creators
export function updateTicketAction(payload) {
  return {type: UPDATE_TICKET, payload};
}

// REDUCER
export function ticketsReducer(state = initialState.tickets, action = {}) {
  switch (action.type) {
    case ADD_TICKET:
      return addTicket(state, action);
    case UPDATE_TICKET:
      return updateTicket(state, action);
    case LIST_RECLAMATION:
      return addTicketToListReclamation(state, action);
    default:
      return state;
  }
}

function addTicket(state, action) {
  return updateTicketInList(state, action);
}

function updateTicket(state, action) {
  return updateTicketInList(state, action);
}

function updateTicketInList(state, action) {
  return {
    ...state,
    [action.payload.id]: action.payload
  };
}
function addTicketToListReclamation(state, action) {
  const ticketIds = {
    name: action?.payload?.name,
    description: action?.payload?.description,
    createdBy: action?.payload?.user?.firstname,
    comments: action?.payload?.comments,
    images: action?.payload?.images,
    id: action?.payload?.id,
    priority: action?.payload?.periorite?.label,
    createdAt: action?.payload?.createdAt,
    service: action?.payload?.service?.name[0],
    periorityImage: action?.payload?.periorite?.images,
    teamId: action?.payload?.teams,
    pictureOwner: action?.payload?.user?.images?.[0]?.file
  };

  return {...state, [action.payload.id]: ticketIds, isLoading: false};
}
