import React from 'react';
import List from '@material-ui/core/List';

import routesConfig from '../../../../modules/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';

const Navigation = () => {
  const role = localStorage.getItem('role');

  return (
    <List>
      {routesConfig.map((item) =>
        item.role.includes(role) ? (
          <React.Fragment key={item.id}>
            {item.type === 'group' && <VerticalNavGroup item={item} level={0} />}

            {item.type === 'collapse' && <VerticalCollapse item={item} level={0} />}

            {item.type === 'item' && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ) : null
      )}
    </List>
  );
};

export default Navigation;
