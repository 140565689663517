import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_ALL_SUPPLIER_SERVICE,
  GET_SERVICE_BY_ID,
  GET_POSTE_BY_ID,
  GET_WALLET_BY_ID,
  GET_TOPUP_BY_ID,
  GET_FILTRED_SERV_SUPP,
  GET_TYPE,
  GET_ACTIVITY,
  GET_COMPANY,
  GET_ALL_OPERATORS_TOPUP,
  GET_ALL_SIM_TOPUP,
  GET_CATEGORY_PROFILE,
  GET_ONE_COMPANY,
  GET_COMMISSION_BY_COMPANY_ID,
  GET_RECHARGE_CARD_BY_ID,
  GET_RECHARGE_CARD,
  GET_ALL_CANALS,
  GET_ROLES,
  GET_COMMERCIAL_TYPE_USER,
  GET_ONE_CONFIG_SERVICE,
  GET_ALL_ORGANISME_TOPUP,
  GET_ALL_CARD_TOPUP,
  GET_COMMERCIAL,
  SET_EMPTY_COMPANY,
  GET_ALL_SCOPES,
  GET_ALL_ROLES,
  GET_DOCUMENTS_C_EMPTY,
  GET_DOCUMENTS_P_EMPTY,
  GET_PROFILE_PIC_EMPTY,
  GET_GALLERY_EMPTY,
  SET_EMPTY_FEED_WALLET_BY_USER,
  GET_DETAIL_BY_ID,
  SET_EMPTY_HISTORY_WALLET,
  SET_RECIEVED_CODE_FROM_EMAIL,
  SET_IS_FIRST_CONNECTION,
  SET_OPEN_CHANGE_PASSWORD_DIALOG,
  GET_ALL_REGIONS,
  GET_ALL_EXTERNAL_VOUCHER,
  GET_COMMERCIAL_DEMANDS,
  GET_COMMERCIAL_LIST,
  UPDATE_BALANCE_SIM,
  POST_NEW_COMMENT_USER,
  GET_ALL_COMMENTS_USER,
  GET_ALL_LIST_VISIT_PDV,
  GET_ALL_COMMERCIAL_VISIT,
  GET_ALL_USERS_VISIT,
  GET_COMPANY_DELETED,
  GET_ALL_IMPORTED_PROSPECTION,
  GET_ALL_LIST_CONTACT,
  GET_PROSPECTION_BY_ID,
  GET_ALL_PDV,
  GET_COMMISSION_TOPUP_BY_ID,
  GET_COMMISSION_BY_ID,
  GET_COMMISSION_BY_ID_VOUCHER,
  GET_COMMISSION_BY_ID_RW,
  GET_COMMISSION_BY_ID_RC,
  GET_COMMISSION_BY_ID_PF,
  GET_COMMISSION_BY_ID_BOO,
  GET_COMMISSION_BY_ID_GAMING,
  GET_COMMISSION_BY_ID_DIWEN,
  GET_COMMISSION_BY_ID_INTERNET_SABBA,
  POS_CREATED
} from 'shared/constants/ActionTypes';
import {feedDocumentsBaseurl, imgBaseUrl} from 'shared/constants/AppConst';
import {onUploadImages} from '.';
import {fetchError, fetchStart} from './Common';

export const onCreateCompany = (company) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = company;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/admin/company/default', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === '2003') {
            dispatch({
              type: FETCH_ERROR,
              payload: res.data.message
            });
          } else if (res.data.status == 'success') {
            dispatch({
              type: SHOW_MESSAGE,
              payload: res.data.message
            });
            dispatch({
              type: POS_CREATED,
              payload: {}
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Ce PDV n'a pas été ajouté correctement"
        });
      });
  };
};
export const onCreateCompanyVip = (company) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = company;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/admin/company_vip', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === '2003') {
            if (res.data.error === 'Phone already exist') {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Numéro de téléphone existe déjà'
              });
            } else if (res.data.error === 'email already exist') {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Email saisi existe déjà Veuillez réessayer!'
              });
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Nom utilisateur saisi existe déjà Veuillez réessayer!'
              });
            }
          } else if (res.data.message === ' Added!') {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Le POS a été ajouté avec succès'
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Ce PDV n'a pas été ajouté correctement"
        });
      });
  };
};
export const postNewComment = (param) => {
  console.log('parammmmm', param);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/user-management/comment', param)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: POST_NEW_COMMENT_USER,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: res.data.message
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getAllCommentUser = (paramm) => {
  //console.log('PARAMMMmmm', paramm);
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/user-management/company/${paramm}/comments`)

      .then((res) => {
        console.log('res.data.datares.data.data', res.data.data);
        if (res.status === 200) {
          if (res.data.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_COMMENTS_USER,
              payload: {items: [], totalPages: 1}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_COMMENTS_USER,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllVisitPDV = (params) => {
  // console.log('PARAMMMmmm', paramm);
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/visit', {params: params})
      .then((res) => {
        console.log('res.data.datares.data.data', res.data);
        if (res.status === 200) {
          if (res.data.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_LIST_VISIT_PDV,
              payload: {items: [], totalPages: 1}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_LIST_VISIT_PDV,
              payload: res.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllCommercialVisit = (params) => {
  // console.log('PARAMMMmmm', paramm);
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/commercial', {params: params})
      .then((res) => {
        console.log('res.data.datares.data.data', res.data.data);
        if (res.status === 200) {
          if (res.data.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_COMMERCIAL_VISIT,
              payload: {items: [], totalPages: 1}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_COMMERCIAL_VISIT,
              payload: res.data.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getAllUserVisit = (params) => {
  // console.log('PARAMMMmmm', paramm);
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/visit/usersId', {params: params})
      .then((res) => {
        console.log('res.data.datares.data.data', res.data.data);
        if (res.status === 200) {
          if (res.data.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_USERS_VISIT,
              payload: {items: [], totalPages: 1}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_USERS_VISIT,
              payload: res.data.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetAllSerSupp = (params) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      await dataAxios.get('wallet/supplier-service', {params: params}).then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: GET_ALL_SUPPLIER_SERVICE,
            payload: data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      });
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
};
export const onGetServiceById = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id, {params: params})
      .then((res) => {
        console.log('res.data.datares.data.data', res.data.data);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SERVICE_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetTopupById = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id, {params: params})
      .then((res) => {
        console.log('88888888888888888', res.data.data);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_TOPUP_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetWalletById = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_WALLET_BY_ID,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetRechargeCardById = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_RECHARGE_CARD,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onDeleteContact = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete(`/user-management/contact/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Contact supprimé avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getProspectionById = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/report/prospection/${id}`)
      .then((res) => {
        console.log('***********Prosppppeppe', res);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_PROSPECTION_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetAllImportedProspection = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/prospection', {params: params})
      .then((res) => {
        if (res.status === 200) {
          console.log('res.statusres.status', res.status);
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Modification effectuée avec succès'
          });
          dispatch({
            type: GET_ALL_IMPORTED_PROSPECTION,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((err) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onConvertAllProspection = (params) => {
  const body = params;

  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/report/prospection/convert', body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Le document a été convertie avec succès '
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((err) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetAllListContact = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/contact', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: GET_ALL_LIST_CONTACT,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((err) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onImportProspection = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/report/prospection/import', body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Le document a été importé avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onPatchProspection = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/report/prospection/${id}`, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'La prospection a été convertie avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetPosteById = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_POSTE_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetServiceComm = (service, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    if (service) {
      service.map((serv, index) => {
        if (serv.code === 'TU') {
          dispatch(onGetTopupById(serv.id, params));
        } else if (serv.code === 'Voucher') {
          dispatch(onGetServiceById(serv.id, params));
        } else if (serv.code === 'RW') {
          dispatch(onGetWalletById(serv.id, params));
        } else if (serv.name === 'Postal services') {
          dispatch(onGetPosteById(serv.id, params));
        } else if (serv.code === 'PF') {
          dispatch(onGetPosteById(serv.id, params));
        } else if (serv.code === 'RC') {
          dispatch(onGetRechargeCardById(serv.id, params));
        }
      });
    } else {
      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong']
      });
    }
  };
};
export const deleteSpecificCommission = (body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post(`/admin/company/specific`, body)
      .then((res) => {
        console.log('dadada', res.data.data);
        if (res.status === 200 || res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetTopupByCompanyId = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/byCompanyId/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          console.log('951357', res.data.data);
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_TOPUP_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetSpecificCommissionTopup = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetSpecificCommissionVoucher = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID_VOUCHER,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetSpecificCommissionRW = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID_RW,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetSpecificCommissionRC = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID_RC,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetSpecificCommissionPF = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID_PF,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetSpecificCommissionBOO = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID_BOO,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetSpecificCommissionGaming = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID_GAMING,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetSpecificCommissionDiwen = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID_DIWEN,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetSpecificCommissionInternetSabba = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission/byService', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_BY_ID_INTERNET_SABBA,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getCommissionTopup = (params) => {
  console.log('paramsparams', params);
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/commission', {params: params})
      .then((res) => {
        if (res.status === 200) {
          console.log('res.dat987', res.data);
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_TOPUP_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetWalletByCompanyId = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/byCompanyId/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_WALLET_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetRechargeCardByCompanyId = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/byCompanyId/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_RECHARGE_CARD_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetPosteByCompanyId = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/byCompanyId/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_POSTE_BY_ID,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetServiceByCompanyId = (id, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service/byCompanyId/' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SERVICE_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetServiceCommByCompany = (service, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    if (service) {
      service.map((serv, index) => {
        if (serv.code === 'TU') {
          dispatch(onGetTopupByCompanyId(serv.id, params));
        } else if (serv.code === 'Voucher') {
          dispatch(onGetServiceByCompanyId(serv.id, params));
        } else if (serv.code === 'RW') {
          dispatch(onGetWalletByCompanyId(serv.id, params));
        } else if (serv.code === 'PF') {
          dispatch(onGetPosteByCompanyId(serv.id, params));
        }
      });
    } else {
      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong']
      });
    }
  };
};
export const onGetServiceSuppfiltred = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_FILTRED_SERV_SUPP,
            payload: res.data.data.items
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllTypePDV = (param) => {
  const {messages} = appIntl();
  //console.log(param, 'params');
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/type-user', {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_TYPE,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllRoles = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/scopes')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ROLES,
            payload: res.data.scopes
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetActivity = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/activity')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ACTIVITY,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const setEmptycompany = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dispatch({type: SET_EMPTY_COMPANY});
    dispatch({type: SET_EMPTY_HISTORY_WALLET});
  };
};

export const onGetCompanyDeleted = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/profile/company', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2203) {
            dispatch({
              type: GET_COMPANY_DELETED,
              payload: {data: [], totalPages: 0}
            });
          } else {
            dispatch({
              type: GET_COMPANY_DELETED,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetCompany = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/profile/company', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2203) {
            dispatch({
              type: GET_COMPANY,
              payload: {data: [], totalPages: 0}
            });
          } else {
            dispatch({
              type: GET_COMPANY,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
// get All List Pdv
export const onGetAllPdv = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/profile/all/company', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2203) {
            dispatch({
              type: GET_ALL_PDV,
              payload: {data: [], totalPages: 0}
            });
          } else {
            dispatch({
              type: GET_ALL_PDV,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetTeamList = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/profile', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2203) {
            dispatch({
              type: GET_COMPANY,
              payload: {data: [], totalPages: 0}
            });
          } else {
            dispatch({
              type: GET_COMPANY,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetDemandsPos = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/profile/all', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2202) {
            dispatch({
              type: GET_COMPANY,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_COMPANY,
              payload: {data: [], totalPages: 0}
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetComercial = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/profile', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2203) {
            dispatch({
              type: GET_COMMERCIAL,
              payload: {data: [], totalPages: 0}
            });
          } else {
            dispatch({
              type: GET_COMMERCIAL,
              payload: res.data.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteProfile = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/user-management/profile/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: "L'utilisateur a été supprimé avec succès"
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetOneCompany = (id) => {
  const {messages} = appIntl();

  return (dispatch) => {
    dispatch({type: FETCH_START});

    if (id) {
      dataAxios
        .get('/user-management/user/' + id)
        .then((res) => {
          console.log('test987123', res.data.data);
          if (res.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_DOCUMENTS_C_EMPTY
            });
            dispatch({
              type: GET_DOCUMENTS_P_EMPTY
            });
            dispatch({
              type: GET_PROFILE_PIC_EMPTY
            });
            dispatch({
              type: GET_GALLERY_EMPTY
            });
            dispatch({
              type: GET_ONE_COMPANY,
              payload: res.data.data
            });
            if (res.data.data.profile.Image) {
              dispatch(
                onUploadImages(
                  {
                    url: res.data.data.profile.Image.path?.slice(1),
                    port: imgBaseUrl
                  },
                  'profile'
                )
              );
            }

            let a = res.data.data.profile?.Company.documentsC;
            // //console.log(a);
            if (a?.length > 0) {
              for (let k = 0; k < a.length; k++) {
                dispatch(onUploadImages({url: a[k].file?.slice(1), port: imgBaseUrl}, 'documentC'));
              }
            }
            let g = res.data.data.profile?.Company.images;
            //console.log(g, 'gallery');
            if (g?.length > 0) {
              for (let m = 0; m < g.length; m++) {
                dispatch(onUploadImages({url: g[m].path?.slice(1), port: imgBaseUrl}, 'gallerie'));
              }
            }
            let b = res.data.data.profile?.documentsP;
            ////console.log(b);
            if (b?.length > 0) {
              for (let k = 0; k < b.length; k++) {
                dispatch(onUploadImages({url: b[k].file?.slice(1), port: imgBaseUrl}, 'documentP'));
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages['message.somethingWentWrong']
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR});
        });
    } else {
      dispatch({
        type: GET_ONE_COMPANY,
        payload: null
      });
    }
  };
};
export const onEditProfilePatch = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .patch('/updateuser/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditCompany = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .patch('/admin/company/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onEditCompanyPatch = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .patch('/user-management/company/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onUpdatePdv = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/user-management/profile/updated_deleted/${id}`, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onUpdateProfilePatch = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/user-management/profile/${id}`, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onEditTeam = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .patch('/update-team/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateOperatorInfo = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/operator', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditOperatorInfo = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/topup/operator/' + id, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onEditBalanceSim = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .put('/topup/serialeport/balance/sim/' + id)
      .then((res) => {
        if (res.code === 2022) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: UPDATE_BALANCE_SIM,
            payload: res
          });
        } else if (res.code === 2009) {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onDeleteOperator = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/topup/operator/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getAllOperatorsTop = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/operator', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_OPERATORS_TOPUP,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateSimCard = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/simcard', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllSimCard = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/simcard', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_SIM_TOPUP,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteSim = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/topup/simcard/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditSim = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/topup/simcard/' + id, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateCard = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/card', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllCard = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/card', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_CARD_TOPUP,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteCard = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/topup/card/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditCard = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/topup/card/' + id, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateOrganismeToCard = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/card/organisme', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const initModem = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/serialport/init', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const updateBalanceModem = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/topup/serialport/balance', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const updateBalanceSim = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/serialport/balance', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateOrganisme = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/organisme', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllOrganisme = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/organisme', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_ORGANISME_TOPUP,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteOrganisme = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/topup/organisme/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditOrganisme = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/topup/organisme/' + id, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetCategoriesProfile = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/category')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_CATEGORY_PROFILE,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetCommissionByCompanyId = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      //  .delete('/wallet/commission/delete', {params: params})
      .get('/wallet/commission', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === 'no commission found') {
            //console.log('');
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_COMMISSION_BY_COMPANY_ID,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        // //dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const ongetCanals = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/type-canal', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_CANALS,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const ongetRegions = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/region', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_REGIONS,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllCommercial = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/type-user', {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMERCIAL_TYPE_USER,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllCommercialDemands = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/feed/wallet', {params: param})
      .then((res) => {
        dispatch({type: FETCH_SUCCESS});
        if (res.status === 200) {
          if (res.data.code === 2202) {
            dispatch({
              type: GET_COMMERCIAL_DEMANDS,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_COMMERCIAL_DEMANDS,
              payload: {totalPages: 0, data: {}}
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllExternalVoucher = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/history-conversion', {params: param})
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 2202) {
            console.log(res);
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_EXTERNAL_VOUCHER,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetOneConfigService = (id, property) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/wallet/${property}/` + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2202) {
            dispatch({
              type: GET_ONE_CONFIG_SERVICE,
              payload: res.data.data
            });
            ////console.log(res.data.data.data.documents, 'documents');
            if (res.data.data.documents) {
              res.data.data.documents.map((img) =>
                dispatch(
                  onUploadImages(
                    {
                      url: img?.path?.slice(1),
                      port: feedDocumentsBaseurl
                    },
                    'wallet'
                  )
                )
              );
            }
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        // //dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const setEmptyDocs = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    dispatch({type: FETCH_SUCCESS});
    dispatch({type: SET_EMPTY_FEED_WALLET_BY_USER});
  };
};
export const ongetScopes = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/api-endpoints', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_SCOPES,
            payload: res.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const ongetRoles = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/scopes', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_ROLES,
            payload: res.data.scopes
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onUpdatePermissions = (name, service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .put(`/api-endpoints/${name}`, service)
      .then((res) => {
        if (res.status === 204) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Votre modification a été effectué avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onChangePassword = (params, setChangeDialogOpen) => {
  // ////console.log('helloooooooooooo')
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/change-password-code', params)
      .then((res) => {
        ////console.log(res, 'change');
        if (res.status === 200) {
          if (res.data?.data === 2202) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Un email a été envoyé!'
            });
            if (setChangeDialogOpen) {
              setChangeDialogOpen(false);
            } else {
              // dispatch({type: SET_OPEN_CHANGE_PASSWORD_DIALOG, payload: false});
              dispatch({type: SET_IS_FIRST_CONNECTION, payload: false});
            }
            dispatch({
              type: SET_RECIEVED_CODE_FROM_EMAIL,
              payload: {openDialog: true, ...params}
            });
          } else if (res.data?.code === 2008) {
            if (setChangeDialogOpen) {
              setChangeDialogOpen(false);
            }
            dispatch({
              type: FETCH_ERROR,
              payload: 'Mot de passe saisi est invalide! Veuillez réessayer'
            });
            dispatch({type: SET_OPEN_CHANGE_PASSWORD_DIALOG, payload: false});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
          dispatch({type: SET_OPEN_CHANGE_PASSWORD_DIALOG, payload: false});
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onSendCodeByEmail = (params, fromDialog) => {
  // ////console.log('helloooooooooooo')
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/change-password', params)
      .then((data) => {
        ////console.log(data, 'change');
        if (data.status === 200) {
          if (data.data.code === 2202) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Votre Mot de pass a été changé avec succès!'
            });
            dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});
          } else if (data.data.code === 2015) {
            // if (fromDialog) {
            //   dispatch({type: SET_IS_FIRST_CONNECTION, payload: true});
            // }
            // dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});
            dispatch({
              type: FETCH_ERROR,
              payload: 'Le code saisi est invalide Veuillez réessayer!'
            });
          } else if (data.data.code === 2018) {
            if (fromDialog) {
              dispatch({type: SET_IS_FIRST_CONNECTION, payload: true});
            }
            dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});
            dispatch({
              type: FETCH_ERROR,
              payload: 'Le code saisi a été expiré Veuillez réessayer!'
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Le code saisi est invalide Veuillez réessayer'
          });
          dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
        dispatch({type: SET_RECIEVED_CODE_FROM_EMAIL, payload: false});
      });
  };
};
export const onGetDetailById = (id, params, endpoint) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(endpoint + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_DETAIL_BY_ID,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditTpeVersion = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .patch('/user-management/type-canal/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateExternalStockVoucher = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/voucher/history-conversion', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
