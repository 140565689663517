import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_CARDS,
  SHOW_MESSAGE,
  GET_ALL_TRANSACTION,
  GET_ALL_AFFILIATION,
  GET_DOCUMENTS_C,
  GET_DOCUMENTS_P,
  GET_PROFILE_PIC,
  GET_GALLERY_PIC,
  GET_FEED_WALLET_BY_USER,
  GET_INVOICE_PIC,
  GET_COMMERCIAL_PIC,
  GET_TPE_IMAGES,
  GET_DOC_IMAGES,
  GET_TPE_IMAGES_BANK,
  GET_TPE_BON_TRANSFERT,
  GET_TPE_MAINTENANCE_IMAGES,
  GET_FACTURE_TPE_VENTE_IMAGES,
  GET_TPE_VENTE_IMAGES,
  GET_RECLAMATION_BY_ID,
  GET_ALL_JOURNEY_HISTORY
} from 'shared/constants/ActionTypes';
import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';

export const getAllCard = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/poste/card', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_CARDS,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateCard = (service) => {
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/poste/card', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: res.data.message
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteCard = (id) => {
  const body = {deletedBy: id};
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/poste/card/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: res.data.message
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditCard = (service, id) => {
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/poste/card/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: res.data.data
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getBalance = (service) => {
  const body = service;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/poste/balance', body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.data.GetSoldeResult,
            balance: res.data.data.solde
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: res.data.data.GetSoldeResult
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const affiliateMarchant = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/poste/affiliation-commercant', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const alimentationPOS = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/poste/alimentation-pos', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const collectFond = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/poste/collect-fond', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAffiliationMerchant = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/poste/affiliation-commercant', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_AFFILIATION,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllTransaction = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/poste/transaction-transfer', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_TRANSACTION,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onUploadFiles = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/upload', params)
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
          dispatch({type: FETCH_SUCCESS});
          if (res.data.path) {
            window.open(res.data.path, '_blank');
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Vous ne pouvez générer le document pour le moment'
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Vous ne pouvez générer le document pour le moment'
        });
      });
  };
};
export const onUploadImages = (params, value) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //console.log(params, 'paramshef');
    dataAxios
      .post('/upload', params)
      .then((res) => {
        console.log('rrrrrrrrrrrrrrrr**rrrrrrrrrrrrrrrr', res.data);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.path) {
            if (value === 'documentC') {
              dispatch({
                type: GET_DOCUMENTS_C,
                payload: res.data.path
              });
            } else if (value === 'documentP') {
              dispatch({
                type: GET_DOCUMENTS_P,
                payload: res.data.path
              });
            } else if (value === 'profile') {
              dispatch({
                type: GET_PROFILE_PIC,
                payload: res.data.path
              });
            } else if (value === 'invoice') {
              dispatch({
                type: GET_INVOICE_PIC,
                payload: res.data.path
              });
            } else if (value === 'gallerie') {
              dispatch({
                type: GET_GALLERY_PIC,
                payload: res.data.path
              });
            } else if (value === 'wallet') {
              dispatch({
                type: GET_FEED_WALLET_BY_USER,
                payload: res.data.path
              });
            } else if (value === 'commercial') {
              dispatch({
                type: GET_COMMERCIAL_PIC,
                payload: res.data.path
              });
            } else if (value === 'tpe') {
              dispatch({
                type: GET_TPE_IMAGES,
                payload: res.data.path
              });
            } else if (value === 'bankName') {
              dispatch({
                type: GET_TPE_IMAGES_BANK,
                payload: res.data.path
              });
            } else if (value === 'pathBorderaux') {
              console.log('pathBorderaux');
              dispatch({
                type: GET_TPE_VENTE_IMAGES,
                payload: res.data.path
              });
            } else if (value === 'pathFacture') {
              console.log('pathFacture');
              dispatch({
                type: GET_FACTURE_TPE_VENTE_IMAGES,
                payload: res.data.path
              });
            } else if (value === 'path') {
              console.log('path');
              dispatch({
                type: GET_RECLAMATION_BY_ID,
                payload: res.data.path
              });
            } else if (value === 'pathJourney') {
              console.log('path');
              dispatch({
                type: GET_ALL_JOURNEY_HISTORY
                //payload: res.data.Images.file,
              });
            } else if (value === 'docs') {
              dispatch({
                type: GET_DOC_IMAGES,
                payload: res.data.path
              });
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: res.data.path
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Vous ne pouvez générer le document pour le moment'
        });
      });
  };
};
