import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const CompanyConfig = [
  {
    auth: authRole.user,
    routes: [
      /* {
        exact: true,
        path: '/Liste-PDV',
        component: React.lazy(() => import('./company')),
      },*/
      {
        exact: true,
        path: '/Liste-PDV',
        component: React.lazy(() => import('../pdv_V2/pages/pdv'))
      },
      {
        exact: true,
        path: '/Liste-PDV-wallet-negative',
        component: React.lazy(() => import('./company/Negative_wallet_company/index'))
      },
      {
        exact: true,
        path: '/Liste-PDV-wallet-superieur-2000Dt',
        component: React.lazy(() => import('./company/wallet_company_superieur_2000Dt/index'))
      },

      {
        exact: true,
        path: '/Liste-PDV/Details-Tpe',
        component: React.lazy(() => import('./DetailsTpe/DetailsTpe'))
      },

      {
        exact: true,
        path: '/Liste-PDV/Details-Tpe/Ajouter',
        component: React.lazy(() => import('./DetailsTpe/AddDetaildTpe'))
      },
      {
        exact: true,
        path: '/Liste-PDV/Details-Contrat',
        component: React.lazy(() => import('./DetailsContract/DetailsContract'))
      },
      {
        exact: true,
        path: '/Liste-PDV/Details-Contrat/Ajouter',
        component: React.lazy(() => import('./DetailsContract/AddContract'))
      },
      {
        exact: true,
        path: '/Liste-PDV-Archivé',
        component: React.lazy(() => import('./companyDeleted'))
      },
      {
        exact: true,
        path: '/Liste-PDV-Archivé/details/',
        component: React.lazy(() => import('./company/CompanyDetail'))
      },
      {
        exact: true,
        path: '/Liste-Wallets',
        component: React.lazy(() => import('./Wallet/ListeWallets'))
      },
      {
        exact: true,
        path: '/PDV-Ville',
        component: React.lazy(() => import('./pdvCity/PDVCity'))
      },
      {
        exact: true,
        path: '/statistique-Ville',
        component: React.lazy(() => import('./statistic/StatiscticCity'))
      },

      {
        exact: true,
        path: '/Liste-Wallets/commissions',
        component: React.lazy(() => import('./Wallet/ListeCommissions'))
      },
      {
        exact: true,
        path: '/Liste-Wallets/CAService/:id',
        component: React.lazy(() => import('./Wallet/CAService'))
      },
      {
        exact: true,
        path: '/Liste-Demandes',
        component: React.lazy(() => import('./demandes/DemandesUser'))
      },
      {
        exact: true,
        path: '/Liste-PDV/Modifier/:id',
        component: React.lazy(() => import('./updatePos/index.js'))
      },
      {
        exact: true,
        path: '/Liste-PDV/details/:id',
        component: React.lazy(() => import('./company/CompanyDetail'))
      },
      {
        exact: true,
        path: '/demands/details',
        component: React.lazy(() => import('./demandes/ContactDetail'))
      },
      {
        exact: true,
        path: '/Liste-PDV/alimenter',
        component: React.lazy(() => import('./company/CompanyTable/FeedPdvWallet'))
      }
    ]
  },
  {
    auth: authRole.daf,
    routes: [
      {
        exact: true,
        path: '/Liste-PDV',
        component: React.lazy(() => import('./company'))
      },
      {
        exact: true,
        path: '/Liste-PDV-wallet-negative',
        component: React.lazy(() => import('./company/Negative_wallet_company/index'))
      },
      {
        exact: true,
        path: '/Liste-PDV-wallet-superieur-2000Dt',
        component: React.lazy(() => import('./company/wallet_company_superieur_2000Dt/index'))
      },

      {
        exact: true,
        path: '/Liste-PDV/Details-Tpe',
        component: React.lazy(() => import('./DetailsTpe/DetailsTpe'))
      },

      {
        exact: true,
        path: '/Liste-PDV/Details-Tpe/Ajouter',
        component: React.lazy(() => import('./DetailsTpe/AddDetaildTpe'))
      },
      {
        exact: true,
        path: '/Liste-PDV/Details-Contrat',
        component: React.lazy(() => import('./DetailsContract/DetailsContract'))
      },
      {
        exact: true,
        path: '/Liste-PDV/Details-Contrat/Ajouter',
        component: React.lazy(() => import('./DetailsContract/AddContract'))
      },
      {
        exact: true,
        path: '/Liste-PDV/Details-Contrat/Ajouter',
        component: React.lazy(() => import('./DetailsContract/AddContract'))
      },
      {
        exact: true,
        path: '/Liste-PDV/Modifier/:id',
        component: React.lazy(() => import('./updatePos/index.js'))
      },
      {
        exact: true,
        path: '/Liste-PDV/details/:id',
        component: React.lazy(() => import('./company/CompanyDetail'))
      },
      {
        exact: true,
        path: '/demands/details',
        component: React.lazy(() => import('./demandes/ContactDetail'))
      },
      {
        exact: true,
        path: '/Liste-PDV/alimenter',
        component: React.lazy(() => import('./company/CompanyTable/FeedPdvWallet'))
      }
    ]
  },
  {
    auth: authRole.comptableNew,
    routes: [
      {
        exact: true,
        path: '/Ajout-PDV',
        component: React.lazy(() => import('./add/index.js'))
      }
    ]
  },
  {
    auth: authRole.admin,
    routes: [
      {
        exact: true,
        path: '/Ajout-PDV-VIP',
        component: React.lazy(() => import('./add/indexVip.js'))
      }
    ]
  },
  {
    auth: authRole.onlYSupport,
    routes: [
      {
        exact: true,
        path: '/Demande-PDV',
        component: React.lazy(() => import('./add/DemandPos'))
      }
    ]
  }
  /* {
    auth: authRole.superAdmin,
    routes: [
      {
        exact: true,
        path: '/voucher_notInserted',
        component: React.lazy(() => import('./statistic/voucherNotInsered')),
      },
    ],
  },*/
];
