import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ADMIN_COMMISSIONS,
  GET_USER_STATS,
  GET_USER_STATS_BYMONTH,
  GET_USER_STATS_BYREG,
  GET_USER_STOCK,
  SET_AUTH_TOKEN,
  SET_IS_FIRST_CONNECTION,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  GET_ALL_PDV_CITY,
  GET_ENABLED_SERVICES
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {appIntl} from '@crema/utility/Utils';
import dataAxios from '@crema/services/data/axiosData';

export const onJwtUserSignUp = ({username, password, name}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {username, name, password};
    try {
      const res = await jwtAxios.post('users', body);

      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = ({username, password}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {username, password};
    try {
      jwtAxios.post('/login', body).then((res) => {
        if (res.data.code === 2001) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Nom utilisateur non valide! Veuillez réessayer'
          });
        } else if (res.data.code === 2008) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Mot de passe non valide! Veuillez réessayer'
          });
        } else if (res.data.code === 2007) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Ce compte est déactivé'
          });
        } else if (res.data.code === 2017) {
          dispatch({
            type: FETCH_ERROR,
            payload: "Vous n'avez pas encore le droit de vous connecter"
          });
        } else {
          localStorage.setItem('token', res.data.token?.access_token);
          localStorage.setItem('role', res.data.role[0]);
          localStorage.setItem('refresh_token', res.data.token.refresh_token);
          localStorage.setItem('first_name', res.data.user.firstname);
          localStorage.setItem('last_name', res.data.user.lastname);
          localStorage.setItem('idUser', res.data.user.id);

          localStorage.setItem('phone', res.data.user.phone);
          dispatch(setJWTToken(res.data.token.access_token));
          dispatch(loadJWTUser());
          if (res.data?.user?.firstConnection === '0') {
            dispatch({type: SET_IS_FIRST_CONNECTION, payload: true});
          }
        }
      });
    } catch (err) {
      if (err.response?.data.error?.code === 1) {
        dispatch({
          type: FETCH_ERROR,
          payload: "Ce compte n'existe pas!Veuillez réessayer avec un autre"
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Vous ne pouvez pas accèder pour le moment! Veuillez réessayer plus tard'
        });
      }
    }
  };
};

export const loadJWTUser = () => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const token = localStorage.getItem('token');
      const first_name = localStorage.getItem('first_name');
      const last_name = localStorage.getItem('last_name');
      const phone = localStorage.getItem('phone');
      const role = localStorage.getItem('role');

      dispatch(fetchSuccess());
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          authType: AuthType.JWT_AUTH,
          displayName: first_name + ' ' + last_name,
          phone: phone,
          role: role,
          token: token
          // photoURL: res.data.avatar,
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.error));
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token
    });
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch({type: FETCH_SUCCESS});
      window.localStorage.clear();
    }, 500);
  };
};
// export const getUserStats = () => {
//   const {messages} = appIntl();
//   return (dispatch) => {
//     dispatch({type: FETCH_START});
//     dataAxios
//       .get('/stats')
//       .then((res) => {
//         if (res.status === 200) {
//           dispatch({type: FETCH_SUCCESS});

//           dispatch({
//             type: GET_USER_STATS,
//             payload: res.data.Services,
//           });
//           dispatch({
//             type: GET_USER_STATS_BYMONTH,
//             payload: res.data.Stats_by_month,
//           });
//         } else {
//           dispatch({
//             type: FETCH_ERROR,
//             payload: messages['message.somethingWentWrong'],
//           });
//         }
//       })
//       .catch((error) => {
//         // dispatch({type: FETCH_ERROR, payload: error.message});
//       });
//   };
// };
// export const getAdminCommissions = () => {
//   const {messages} = appIntl();
//   return (dispatch) => {
//     dispatch({type: FETCH_START});
//     dataAxios
//       .get('/stats')
//       .then((res) => {
//         if (res.status === 200) {
//           //console.log(res.data);
//           dispatch({type: FETCH_SUCCESS});
//           dispatch({
//             type: GET_ADMIN_COMMISSIONS,
//             payload: res.data.Stats_Commission,
//           });
//           dispatch({
//             type: GET_USER_STATS,
//             payload: res.data.Services,
//           });
//           dispatch({
//             type: GET_USER_STOCK,
//             payload: res.data,
//           });
//           dispatch({
//             type: GET_USER_STATS_BYREG,
//             payload: res.data.number_users_by_region,
//           });
//         } else {
//           dispatch({
//             type: FETCH_ERROR,
//             payload: messages['message.somethingWentWrong'],
//           });
//         }
//       })
//       .catch((error) => {
//         //console.log(error);
//         dispatch({type: FETCH_ERROR});
//       });
//   };
// };
export const getAdminStatssByFilters = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/stats', {params: params})
      .then((res) => {
        if (res.status === 200) {
          //console.log(res.data);
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ADMIN_COMMISSIONS,
            payload: res.data.Stats_Commission
          });
          dispatch({
            type: GET_USER_STATS,
            payload: res.data.Services
          });
          dispatch({
            type: GET_USER_STOCK,
            payload: res.data
          });
          dispatch({
            type: GET_USER_STATS_BYREG,
            payload: res.data.number_users_by_region
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onSearchExistance = (setErrorText, prop, value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/search', {params: {[prop]: value}})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2003) {
            if (prop === 'email') {
              setErrorText('Email existe déjà!');
            } else if (prop === 'phone') {
              setErrorText('Numéro de téléphone existe déjà!');
            } else if (prop === 'username') {
              setErrorText("Nom d'utilisateur existe déjà!");
            }
          } else {
            setErrorText('');
          }
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onSearchExistCompany = (setErrorText, prop, value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/company/exist', {params: {[prop]: value}})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.data.message == false) {
            setErrorText('Registre de commerce existe déjà!');
          } else {
            setErrorText('');
          }
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllPdvOfCity = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/profile/gouvernorat', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: GET_ALL_PDV_CITY,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((err) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetEnabledServices = (prop, value) => {
  // const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/verif/service')
      .then((res) => {
        if (res.status === 200) {
          ////console.log(res);
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ENABLED_SERVICES,
            payload: res.data.data.service
          });
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
