import {
  FAIL,
  LOADING_SUCCESS,
  POST_RECLAMATION_SUCCESS,
  POST_RECLAMATION_FAIL,
  GET_PERIORITE,
  GET_SERVICES,
  GET_RECLAMATION_ID,
  GET_ALL_RECLAMATION_TRAITED
} from '../../shared/constants/ActionTypes';

const initState = {
  errorModal: false,
  successModal: false,
  isLoading: false,
  services: JSON.parse(localStorage.getItem('services')) || null,
  periorites: JSON.parse(localStorage.getItem('periorites')) || null,
  reclamationInfo: [],
  reclamtionTraited: [],

  errorss: null,
  successPostReclamation: false,
  failesPostReclamation: false,
  reclamationIdData: {}
};

const reclamation = (state = initState, {type, payload} = {}) => {
  switch (type) {
    case FAIL:
      return {
        ...state,
        errorModal: false,
        successModal: false,
        isLoading: false,
        successPostReclamation: false
      };

    case LOADING_SUCCESS:
      return {...state, isLoading: true};

    case POST_RECLAMATION_SUCCESS:
      return {
        ...state,
        reclamationInfo: [...state.reclamationInfo, payload.data.newReclamation],
        successModal: true,
        errorss: null,
        isLoading: false,
        successPostReclamation: true,
        failesPostReclamation: false
      };
    case POST_RECLAMATION_FAIL:
      return {
        ...state,
        errorss: payload,
        successModal: false,
        successPostReclamation: false,
        failesPostReclamation: true
      };

    case GET_SERVICES:
      localStorage.setItem('services', JSON.stringify(payload.data));
      return {
        ...state,
        services: payload.data,
        successModal: false,
        isLoading: false
      };
    case GET_ALL_RECLAMATION_TRAITED:
      return {
        ...state,
        reclamtionTraited: payload.items
      };

    case GET_PERIORITE:
      localStorage.setItem('periorites', JSON.stringify(payload.data));
      return {
        ...state,
        periorites: payload.data,
        successModal: false,
        isLoading: false
      };

    case GET_RECLAMATION_ID:
      return {...state, reclamationIdData: payload, isLoading: false};

    default:
      return state;
  }
};

export default reclamation;
