import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const AlimentationUserPagesConfig = [
  {
    auth: authRole.comptableNew,
    routes: [
      {
        exact: true,
        path: '/Alimentation-espèce',
        component: React.lazy(() => import('./Pages/CashDemands'))
      },
      {
        exact: true,
        path: '/Alimentation-PDV',
        component: React.lazy(() => import('./Pages/DemandesUser'))
      },
      {
        exact: true,
        path: '/DemandeRemboursement',
        component: React.lazy(() => import('./Pages/Remboursements'))
      },
      {
        exact: true,
        path: '/Alimentation-PDV',
        component: React.lazy(() => import('./Pages/DemandesUser'))
      },
      {
        exact: true,
        path: '/Alimanation-Commercial',
        component: React.lazy(() => import('./Pages/CommercialCashFeed'))
      },
      {
        exact: true,
        path: '/Alimanation-CashCollector',
        component: React.lazy(() => import('./Pages/CahCollectorCashFeed'))
      }
    ]
  }
];
