import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const FeedingPagesConfig = [
  {
    auth: authRole.comptable,
    routes: [
      {
        exact: true,
        path: '/Alimentations',
        component: React.lazy(() => import('./Pages/lists/ListFeed'))
      },
      {
        exact: true,
        path: '/Config-fournisseur',
        component: React.lazy(() => import('./Pages/lists/ListProviders'))
      },
      {
        exact: true,
        path: '/Alimentation/Config-service',
        component: React.lazy(() => import('./Pages/lists/ListServices'))
      },
      {
        exact: true,
        path: '/Config-typePaiement',
        component: React.lazy(() => import('./Pages/lists/ListPaymentType'))
      }
    ]
  },

  {
    auth: authRole.comptable,
    routes: [
      {
        exact: true,
        path: '/Feed/Stock',
        component: React.lazy(() => import('./Pages/forms/FeedForm'))
      }
    ]
  }
];
