import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const AbonnementConfig = [
  {
    auth: authRole.comptable,
    routes: [
      {
        exact: true,
        path: '/Liste-Abonnement',
        component: React.lazy(() => import('./listAbonnement/ListeAbonnement'))
      },
      {
        exact: true,
        path: '/Liste-Abonne',
        component: React.lazy(() => import('./listAbonne/ListeAbonne'))
      },
      {
        exact: true,
        path: '/Historique-Abonnement',
        component: React.lazy(() => import('./historiqueAbonnement/HistoriqueAbonnement'))
      },
      {
        exact: true,
        path: '/Type-Abonnement',
        component: React.lazy(() => import('./typeAbonnement/TypeAbonnement'))
      },
      {
        exact: true,
        path: '/TPE-Gestion',
        component: React.lazy(() => import('./GestionTpe/TpeGestion'))
      },
      {
        exact: true,
        path: '/Vente-TPE',
        component: React.lazy(() => import('./GestionTpe/TpeVente'))
      },
      {
        exact: true,
        path: '/vente-TPE/Ajout',
        component: React.lazy(() => import('./listAbonne/ajoutTpeListeVendu'))
      },
      {
        exact: true,
        path: '/vente-TPE/Affectation/:id',
        component: React.lazy(() => import('./listAbonne/affectaionTpe'))
      },
      {
        exact: true,
        path: '/Vente-TPE/:id',
        component: React.lazy(() => import('./GestionTpe/DetatilTpeVente/index'))
      },
      {
        exact: true,
        path: '/demande-Tpe',
        component: React.lazy(() => import('./GestionTpe/DemandeTpe'))
      },
      {
        exact: true,
        path: '/TPE-Maintenance',
        component: React.lazy(() => import('./maintenanceTpe/AllList'))
      },
      {
        exact: true,
        path: '/Historique-TPE',
        component: React.lazy(() => import('./historiqueTpe/AllList'))
      },
      {
        exact: true,
        path: '/Maintenance/Detail/:idMaintenance',
        component: React.lazy(() => import('./maintenanceTpe/Details'))
      },
      {
        exact: true,
        path: '/Ajouter-maintenance-tpe',
        component: React.lazy(() => import('./maintenanceTpe/Add'))
      },
      {
        exact: true,
        path: '/Maintenance/Modifier/:idMaintenance',
        component: React.lazy(() => import('./maintenanceTpe/Update'))
      },
      {
        exact: true,
        path: '/TPE-Gestion/Import',
        component: React.lazy(() => import('./GestionTpe/ImportTPE'))
      }
    ]
  }
];
