import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const ReclamationRouting = [
  {
    auth: authRole.adminAndSupport,
    routes: [
      {
        exact: true,
        path: '/reclamation-liste',
        component: React.lazy(() => import('./pages/liste'))
      },
      {
        exact: true,
        path: '/tags-liste/:id',
        component: React.lazy(() => import('./tags/ListTags'))
      },
      {
        exact: true,
        path: '/new-tags/:id',
        component: React.lazy(() => import('./tags/AddTag'))
      },
      {
        exact: true,
        path: '/nouvelle-reclamation',
        component: React.lazy(() => import('./pages/new'))
      },
      {
        exact: true,
        path: '/reclamation/detail/:id',
        component: React.lazy(() => import('./pages/DetailReclamation'))
      },

      {
        exact: true,
        path: '/reclamation-en-cours',
        component: React.lazy(() => import('./pages/listEnCours'))
      },
      {
        exact: true,
        path: '/reclamation_pdv/:id',
        component: React.lazy(() => import('./pages/ReclamationByid_user'))
      },
      {
        exact: true,
        path: '/reclamation_History/:id',
        component: React.lazy(() => import('./pages/HistoriqueReclamation'))
      },

      {
        exact: true,
        path: '/reclamation-traiter',
        component: React.lazy(() => import('./pages/traiter'))
      },
      {
        exact: true,
        path: '/reclamation-cloturer',
        component: React.lazy(() => import('./pages/listReclamationproceed'))
      },
      {
        exact: true,
        path: '/reclamation-Annuler',
        component: React.lazy(() => import('./pages/listAnuller'))
      }
    ]
  },
  {
    auth: authRole.onlYDaf,
    routes: [
      {
        exact: true,
        path: '/reclamation-affecter',
        component: React.lazy(() => import('./pages/DafReclamation/listAffecterDaf'))
      },
      {
        exact: true,
        path: '/reclamation-affecter/detail/:id',
        component: React.lazy(() => import('./pages/DetailReclamation'))
      },
      {
        exact: true,
        path: '/reclamation-affecter-traiter',
        component: React.lazy(() => import('./pages/DafReclamation/listTraiter'))
      },
      {
        exact: true,
        path: '/reclamation-affecter-cloturer',
        component: React.lazy(() => import('./pages/DafReclamation/listCloturer'))
      },
      {
        exact: true,
        path: '/reclamation-affecter-anuller',
        component: React.lazy(() => import('./pages/DafReclamation/listAnuler'))
      }
    ]
  }
];
