import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const CommercialConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Liste-prospections',
        component: React.lazy(() => import('./Rapports/rapports'))
      },
      {
        exact: true,
        path: '/Liste-leads',
        component: React.lazy(() => import('./Rapports/leads'))
      },
      {
        exact: true,
        path: '/Liste-leads-contacted',
        component: React.lazy(() => import('./Rapports/leadsContacted'))
      },
      {
        exact: true,
        path: '/Liste-leads-recontacter',
        component: React.lazy(() => import('./Rapports/leadsRecontacted'))
      },
      {
        exact: true,
        path: '/Liste-des-prospects-chauds',
        component: React.lazy(() => import('./Rapports/leadsHot'))
      },
      {
        exact: true,
        path: '/Statistique-leads',
        component: React.lazy(() => import('./Rapports/statLeads'))
      },
      {
        exact: true,
        path: '/Liste-appointements',
        component: React.lazy(() => import('./Rapports/appointements'))
      },
      {
        exact: true,
        path: '/Liste-reclamations',
        component: React.lazy(() => import('./Feedback/reclamations'))
      },

      {
        exact: true,
        path: '/Liste-reclamations/comments',
        component: React.lazy(() => import('./Feedback/comments'))
      },
      {
        exact: true,
        path: '/Liste-reclamations/services',
        component: React.lazy(() => import('./Feedback/services'))
      },
      {
        exact: true,
        path: '/Liste-comments',
        component: React.lazy(() => import('./Rapports/comments'))
      },
      {
        path: `/Ajouter-reports/:idReport`,
        component: React.lazy(() => import('./Rapports/AddReport'))
      },
      {
        path: `/Ajouter-prospections/:idProspection`,
        component: React.lazy(() => import('./Rapports/AddReport'))
      },
      {
        path: `/Ajouter-reports`,
        component: React.lazy(() => import('./Rapports/AddReport'))
      },
      {
        path: `/Ajouter-prospections/:idProspection`,
        component: React.lazy(() => import('./Rapports/AddReport'))
      },
      {
        exact: true,
        path: '/Alimentation-Commercial',
        component: React.lazy(() => import('./FeedCommercial/CommercialFeed'))
      },
      {
        exact: true,
        path: '/Historique-Alimentaion',
        component: React.lazy(() => import('./HistoryWallet/History'))
      },
      {
        exact: true,
        path: '/Historique-Commercial',
        component: React.lazy(() => import('./HistoryCommercial/historyCommercial'))
      },
      {
        exact: true,
        path: '/List-Visite-Commercial',
        component: React.lazy(() => import('./ListVisit/ListVisit'))
      },
      {
        exact: true,
        path: '/Historique-Commercial/Details',
        component: React.lazy(() => import('./HistoryCommercial/Details'))
      },
      {
        exact: true,
        path: '/Historique-Commercial/StatisticCommercial',
        component: React.lazy(() => import('./HistoryCommercial/StatisticCommercial'))
      },

      {
        exact: true,
        path: '/Importer_prospection',
        component: React.lazy(() => import('./importProspection/ImportProspection'))
      },
      {
        exact: true,
        path: '/Importer_prospection/Importer',
        component: React.lazy(() => import('./importProspection/AddProspection'))
      },
      {
        exact: true,
        path: '/ListContact',
        component: React.lazy(() => import('./ListContact/ListOfContact'))
      }
    ]
  }
];
