import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const MintrouteConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Liste-Marque/Liste-Produits',
        component: React.lazy(() => import('./Pages/ProductsList'))
      },
      {
        exact: true,
        path: '/Liste-Marque/Add-Guid',
        component: React.lazy(() => import('./Pages/AddGuid'))
      },
      {
        exact: true,
        path: '/Liste-Marque',
        component: React.lazy(() => import('./Pages/MarquesList'))
      },
      {
        exact: true,
        path: '/Historique-Coupons',
        component: React.lazy(() => import('./Pages/transactions'))
      }
    ]
  }
];
