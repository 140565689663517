import {
  GET_ALL_HOTEL_CITITES,
  GET_ALL_HOTEL_DESTINATION,
  GET_ALL_HOTEL_TRANS,
  GET_ALL_HOTEL_BOOKING_HOTEL
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  cities: [],
  destinations: [],
  reservationTransactions: [],
  bookingTransactions: [],
  totalPages: 0,
  totalItems: 0,
  totalAmount: 0
};

const hotelReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_HOTEL_DESTINATION:
      return {
        ...state,
        destinations: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems
      };
    case GET_ALL_HOTEL_CITITES:
      return {
        ...state,
        cities: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems
      };
    case GET_ALL_HOTEL_TRANS:
      return {
        ...state,
        reservationTransactions: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems
      };
    case GET_ALL_HOTEL_BOOKING_HOTEL:
      return {
        ...state,
        bookingTransactions: action.payload.data,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages
      };
    default:
      return state;
  }
};

export default hotelReducer;
