import {
  GET_ALL_AFFILIATION,
  GET_ALL_CARDS,
  GET_ALL_TRANSACTION
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  totalPages: 0,
  cardsPoste: [],
  affilationMerchant: [],
  transactions: []
};

const PosteReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_CARDS:
      return {
        ...state,
        cardsPoste: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_AFFILIATION:
      return {
        ...state,
        affilationMerchant: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_TRANSACTION:
      return {
        ...state,
        transactions: action.payload.items,
        totalPages: action.payload.totalPages
      };
    default:
      return state;
  }
};

export default PosteReducer;
