import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const CompanyConfigV2 = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/PDV-V2',
        component: React.lazy(() => import('./pages/pdv'))
      }
    ]
  }
];
