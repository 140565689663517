import {appIntl} from '@crema/utility/Utils';
import dataAxios from '@crema/services/data/axiosData';

import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SHOW_MESSAGE} from 'shared/constants/ActionTypes';

import {onGetAllFunction} from './CommonCrud';

export const onImportDataHotels = (callCityEndpoint, endpoint, actionType, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = {
      languageId: '1'
    };
    dispatch({type: FETCH_START});
    dataAxios
      .post('/hotel/staticData', body)
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
          dispatch({
            type: FETCH_SUCCESS
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Les données sont bien importés'
          });
          //   if (callCityEndpoint) onGetAllFunction(endpoint, actionType, params);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Vous ne pouvez pas ajouter des données pour le moment'
        });
      });
  };
};
