import {
  GET_ALL_FACIAL,
  GET_ALL_OPERATORS,
  GET_ALL_USERS_VOUCHER,
  GET_ALL_VOUCHER_DOCUMENTS,
  GET_ALL_VOUCHER_SETTINGS,
  GET_ALL_VOUCHER_STOCK,
  GET_ALL_VOUCHER_TRANSACTIONS,
  GET_EMPTY_DOCUMENTS,
  GET_STOCK_VOUCHER,
  GET_ALL_HISTORY_COMMAND,
  GET_ALL_TRANSACTION,
  GET_ALL_NOT_INSERD
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  facials: [],
  operators: [],
  idOrange: '',
  idFacial1: '',
  idFacial5: '',
  documents: [],
  settings: [],
  totalPages: 0,
  totalAmount: [],
  transactions: [],
  sucessMessage: '',
  notInsered: [],
  stock: [],
  stockVouch: [],
  facialVouch: [],
  historyCommand: {}
};

const stockVoucherReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_HISTORY_COMMAND:
      return {...state, historyCommand: action.payload};
    case GET_STOCK_VOUCHER:
      return {
        ...state,
        stockVouch: action.payload.data,
        totalAmount: action.payload.extraData.totalAmount,
        totalPages: 1
      };

    case GET_ALL_FACIAL:
      return {
        ...state,
        facials: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_OPERATORS:
      return {
        ...state,
        operators: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_VOUCHER_DOCUMENTS:
      return {
        ...state,
        documents: action.payload.items,
        totalPages: action.payload.totalPages
      };

    case GET_EMPTY_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
        // totalPages: action.payload.totalPages,
      };
    case GET_ALL_VOUCHER_SETTINGS:
      return {
        ...state,
        settings: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_VOUCHER_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload.data.data,
        sucessMessage: action.payload.data.message,
        totalPages: action.payload.data.totalPages,
        totalItems: action.payload.data.totalItems,
        totalAmount: action.payload.data.totalAmount ? action.payload.data.totalAmount : 0
      };
    case GET_ALL_VOUCHER_STOCK:
      return {
        ...state,
        stock: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_USERS_VOUCHER:
      return {
        ...state,
        usersVoucher: action.payload.data
        //totalPages: action.payload.totalPages,
      };
    case GET_ALL_TRANSACTION:
      return {
        ...state,
        Transaction: action.payload.data
      };

    default:
      return state;
  }
};

export default stockVoucherReducer;
