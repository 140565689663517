export const authRole = {
  admin: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
  daf: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DAF'],
  dafAndSupport: ['user', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DAF', 'ROLE_SUPPORT'],

  onlYSupperAdmin: ['user', 'ROLE_SUPER_ADMIN'],
  adminAndSupport: ['user', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_SUPPORT'],
  onlYComptable: ['ROLE_COMPTABLE'],
  comptableAndSupport: ['user', 'ROLE_COMPTABLE', 'ROLE_SUPPORT'],
  dafComptableAndSupport: ['user', 'ROLE_COMPTABLE', 'ROLE_SUPPORT', 'ROLE_DAF'],
  dafAndAdmin: ['user', 'ROLE_ADMIN', 'ROLE_DAF'],
  user: [
    'user',
    'ROLE_DAF',
    'ROLE_ADMIN',
    'ROLE_SUPPORT',
    'ROLE_COMPTABLE',
    'ROLE_COMMERCIAL',
    'ROLE_SUPER_ADMIN'
  ],
  onlYAdmin: ['user', 'ROLE_ADMIN'],
  visitor: [
    'ROLE_VISITOR',
    'user',
    'ROLE_DAF',
    'ROLE_SUPPORT',
    'ROLE_COMPTABLE',
    'ROLE_ADMIN',
    'ROLE_SUPER_ADMIN'
  ],
  onlYSupport: ['ROLE_SUPPORT'],
  onlYDaf: ['ROLE_DAF'],
  superAdmin: ['ROLE_SUPER_ADMIN'],
  comptable: ['user', 'ROLE_DAF', 'ROLE_COMPTABLE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
  comptableNew: [
    'user',
    'ROLE_DAF',
    'ROLE_COMPTABLE',
    'ROLE_ADMIN',
    'ROLE_SUPER_ADMIN',
    'ROLE_SUPPORT'
  ],
  support: ['user', 'ROLE_DAF', 'ROLE_SUPPORT', 'ROLE_COMPTABLE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
  commercial: [
    'ROLE_DAF',
    'user',
    'ROLE_SUPPORT',
    'ROLE_COMPTABLE',
    'ROLE_COMMERCIAL',
    'ROLE_ADMIN',
    'ROLE_SUPER_ADMIN'
  ]
};
const preProd = 'http://192.168.12.115';
const prod = 'http://192.168.10.116';
export const token = localStorage.getItem('token');

export const initialUrl = '/home';
// this url will open after login
export const baseUrl = 'https://api.khallasli.com'; // remplace this base url by this
// export const baseUrl = 'https://refonte-api.khallasli.com';
//export const baseUrl = 'https://localhost:3000';
export const imgBaseUrl = '5005';
export const documentsBaseurl = '5010';
export const feedDocumentsBaseurl = '5004';
export const transactionsBaseurl = '4000';
export const transactionsTopnet = '5000';
export const reportDocuments = '5012';
export const billPaymentDocs = '5013';
export const baseFilesUrl = '5002';

//export const SOCKET_URL = preProd + ':5015';
