import {
  GET_ALL_MINTROUTE_BRANDS,
  GET_ALL_MINTROUTE_PRODUCTS,
  GET_ALL_MINTROUTE_TRANS,
  GET_ALL_EXCHANGE,
  GET_ALL_TYPE,
  SET_ONE_BRAND
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  products: [],
  historyTransactions: [],
  extraData: {},
  types: [],
  exchange: [],
  totalPages: 0,
  onebrand: {}
};

const mintrouteReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_MINTROUTE_BRANDS:
      return {
        ...state,
        products: action.payload.data?.data,
        totalPages: action.payload.data?.totalPages
      };
    case GET_ALL_MINTROUTE_PRODUCTS:
      return {
        ...state,
        products: action.payload.data?.data,
        totalPages: action.payload.data?.totalPages
      };
    case GET_ALL_MINTROUTE_TRANS:
      return {
        ...state,
        historyTransactions: action.payload?.data?.data,
        totalPages: action.payload?.data?.totalPages,
        extraData: action.payload?.extraData
      };
    case GET_ALL_TYPE:
      return {
        ...state,
        types: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_EXCHANGE:
      return {
        ...state,
        exchange: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case SET_ONE_BRAND:
      return {...state, onebrand: action.payload};
    default:
      return state;
  }
};

export default mintrouteReducer;
