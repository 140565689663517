import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const teamPagesConfig = [
  {
    auth: authRole.daf,
    routes: [
      {
        exact: true,
        path: '/Equipe',
        component: React.lazy(() => import('./Pages/TeamList'))
      },
      {
        exact: true,
        path: '/Equipe/Details',
        component: React.lazy(() => import('./Pages/TeamDetails'))
      },
      {
        exact: true,
        path: '/Liste-Commercials',
        component: React.lazy(() => import('./Pages/CommercialsList'))
      }
    ]
  }
];
