import {GET_ALL_FOURNISSEUR} from '../../shared/constants/ActionTypes';

const initialSettings = {
  agents: [],
  fourniss: [],
  extraData: {},

  totalPages: 0
};

const FournisseurReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_FOURNISSEUR:
      return {
        ...state,
        agents: action.payload?.data.rows, //error
        totalPages: action.payload.data.rows?.totalPages
      };

    default:
      return state;
  }
};

export default FournisseurReducer;
