import {
  GET_ALL_HISTORY_TOPUP,
  GET_ALL_POSTE_PAYMENT,
  GET_ALL_POSTE_RECHARGE,
  GET_ALL_TOPNET_HISTORY,
  GET_ALL_BILL_HISTORY,
  GET_ALL_TOPUP_OPERATORS,
  GET_ALL_TOPUP_STOCK,
  GET_ALL_TOPUP_TRANS,
  GET_HOTEL_BALANCE,
  GET_ALL_TOPUP_TYPES,
  GET_ALL_USERS_TOPNET,
  GET_ALL_MINTROUTE_BALANCE,
  SET_STOCK_BY_OPERATOR,
  GET_ALL_INTERNET_SABBA_DATA,
  GET_ALL_JOURNEY_HISTORY,
  GET_ALL_NOT_INSERD,
  GET_ALL_DATA_RAPPROUCHEMNT
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  rechargePoste: [],
  paymentPoste: [],
  topupTrans: [],
  topupStock: [],
  totalPages: 0,
  operators: [],
  topupKhTypes: [],
  totalAmount: 0,
  totalItems: 0,
  topnetTrans: [],
  topnetBill: [],
  journeyHistory: [],
  users: [],
  topupModem: [],
  mintrouteBalance: {},
  hotelbalance: [],
  totalGros: 0,
  statistiqueTopup: [],
  totalDetail: 0,
  stockOperator: {},
  dataRapprochement: []
};

const historyReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_MINTROUTE_BALANCE:
      return {...state, mintrouteBalance: action.payload};
    case GET_ALL_POSTE_RECHARGE:
      return {
        ...state,
        rechargePoste: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_DATA_RAPPROUCHEMNT:
      return {
        ...state,
        dataRapprochement: action.payload.data
        //totalPages: action.payload.totalPages,
      };

    case GET_ALL_POSTE_PAYMENT:
      return {
        ...state,
        paymentPoste: action.payload?.items,
        totalPages: action.payload?.totalPages,
        totalItems: action.payload.totalItems ? action.payload.totalItems : 0
      };
    case GET_ALL_TOPUP_TRANS:
      return {
        ...state,
        topupTrans: action.payload.data,
        totalPages: action.payload.totalPages,
        totalGros: action.payload.extraData?.totalGros,
        totalDetail: action.payload.extraData?.totalDetails,
        totalItems: action.payload.totalItems ? action.payload.totalItems : 0,
        totalAmount: action.payload.totalAmount ? action.payload.totalAmount : 0,
        statistiqueTopup: action.payload.stat
      };
    case GET_HOTEL_BALANCE:
      return {
        ...state,
        hotelbalance: action.payload
      };

    case GET_ALL_TOPUP_OPERATORS:
      return {
        ...state,
        operators: action.payload.data.map((item) =>
          item.Type.type === 'api_externe'
            ? {...item, label: (item.label += ' API externe')}
            : item.Type.type === 'api_interne'
            ? {...item, label: (item.label += ' API interne')}
            : item
        )
      };
    case GET_ALL_TOPUP_TYPES:
      return {
        ...state,
        topupKhTypes: action.payload.data
      };
    case GET_ALL_TOPNET_HISTORY:
      return {
        ...state,
        topnetTrans: action.payload.data.items,
        totalPages: action.payload.data.totalPages,
        totalItems: action.payload.data.totalItems,
        totalAmount: action.payload.code
      };
    case GET_ALL_BILL_HISTORY:
      return {
        ...state,
        topnetBill: action.payload.data.items,
        totalPages: action.payload.data.totalPages,
        totalItems: action.payload.data.totalItems
      };
    case GET_ALL_TOPUP_STOCK:
      return {
        ...state,
        topupStock: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_JOURNEY_HISTORY:
      return {
        ...state,
        journeyHistory: action.payload.data,
        totalPages: action.payload.totalPages
      };

    case GET_ALL_USERS_TOPNET:
      return {
        ...state,
        users: action.payload.data
        //totalPages: action.payload.totalPages,
      };
    case GET_ALL_HISTORY_TOPUP:
      return {
        ...state,
        topupModem: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_INTERNET_SABBA_DATA:
      return {
        ...state,
        sabbaData: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_NOT_INSERD:
      return {
        ...state,
        notInsered: action.payload.items
      };
    case SET_STOCK_BY_OPERATOR:
      return {
        ...state,
        totalStock: action.payload.data.reduce((a, current) => a + parseFloat(current.stock), 0),
        stockOperator: action.payload.data.reduce(function (count, currentValue) {
          return (
            count[currentValue.code]
              ? (count[currentValue.code] =
                  count[currentValue.code] + parseFloat(currentValue.stock))
              : (count[currentValue.code] = parseFloat(currentValue.stock)),
            count
          );
        }, {})
      };
    default:
      return state;
  }
};

export default historyReducer;
