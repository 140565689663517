import saMessages from '../locales/fr_FR.json';
import {frFR} from '@material-ui/core/locale';

const saLang = {
  messages: {
    ...saMessages
  },
  muiLocale: frFR,
  locale: 'fr-FR'
};
export default saLang;
