import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_COMMERCIAL_REPORTS,
  GET_COMMERCIAL_DEMANDS,
  GET_COMMERCIAL_LIST,
  GET_DETAIL_BY_ID,
  GET_ALL_COMMERCIAL_COMMENTS,
  GET_ALL_COMMERCIAL_APPOINTEMENT,
  GET_HISTORY_COMMERCIAL_WALLET,
  GET_HISTORY_WALLET,
  GET_PROFILE_BY_ID,
  GET_SOURCE_LIST,
  GET_TOTAL_WALLET,
  GET_TPE_IMAGES,
  SET_COMPANY_FILTERS,
  SHOW_MESSAGE,
  GET_ALL_FEED_COMMERCIAL_CREDIT,
  GET_ALL_FEED_COMMERCIAL_ENCAISEMENT,
  SET_COMPANY_FILTERS_DELETED,
  GET_DOC_IMAGES,
  SET_EMPTY_DOCS,
  GET_REPORT_BY_ID,
  GET_ALL_STATISTICS_COMMERCIAL,
  GET_ALL_DEPASSED_CREDIT,
  GET_STATISTIC_CITY_PDV,
  GET_STATISTIC_CITY_PDV_PROBABILITY,
  GET_ALL_COMMERCIAL_LEADS,
  GET_ALL_STATISTIC_LEADS,
  GET_ALL_LEADS_EN_ATTENTE,
  GET_ALL_LEADS_A_CONTACTER,
  GET_ALL_LEADS_PLANIFIER,
  GET_ALL_LEADS_CHAUD,
  GET_ALL_STATISTIC_LEADS_CRM
} from 'shared/constants/ActionTypes';
import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {imgBaseUrl} from 'shared/constants/AppConst';
import {onUploadFiles, onUploadImages} from '.';
export const onValidateDemandeUser = (username, status, raison) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = {
      code: status,
      raison: raison
    };
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/accept/${username}`, body)
      .then((data) => {
        if (data.data.status === 'success') {
          dispatch({
            type: FETCH_SUCCESS,
            payload: data.data.message
          });
          if (data.data.message === 'The user has been refused') {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Le PDV a été rejeté avec succès'
            });
          } else if (data.data.message === 'The user has been accepted') {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Le PDV a été accepté avec succès'
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Desolé,vous ne pouvez pas activer ou déactiver ce PDV!'
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: FETCH_ERROR,
          payload: 'Vous ne pouvez pas acceptez cet utilisateur pour le moment'
        });
      });
  };
};
export const onImportCompany = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('user-management/company/import', body)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetHistoryWallet = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/history', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_HISTORY_WALLET,
              payload: {items: [], totalPages: 1}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_HISTORY_WALLET,
              payload: res.data.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const onGetHistoryCommercialWallet = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/historyWallet', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_HISTORY_COMMERCIAL_WALLET,
              payload: {data: [], totalPages: 1}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_HISTORY_COMMERCIAL_WALLET,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};

export const onGetAllFeedCredit = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/feed/pos', {params: param})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_FEED_COMMERCIAL_CREDIT,
              payload: {data: [], totalPages: 1}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_FEED_COMMERCIAL_CREDIT,
              payload: res.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};

export const onGetAllStatisticCommercial = (commercial_id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/report/wallet/stats/commercial/${commercial_id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_STATISTICS_COMMERCIAL,
            payload: res.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const onGetAllFeedEncaisement = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/feed/pos', {params: param})
      .then((res) => {
        console.log('resssssssssss', res);
        if (res.status === 200) {
          if (res.data.code === 2203) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_FEED_COMMERCIAL_ENCAISEMENT,
              payload: {data: [], totalPages: 1}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_FEED_COMMERCIAL_ENCAISEMENT,
              payload: res.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const onGetAllDepassedCredit = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/feed/pos', {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_DEPASSED_CREDIT,
            payload: res.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const onGetTotalWalletStock = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/stock_wallet', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_TOTAL_WALLET,
            payload: res.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        // //dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const onActivatePOS = (username, status) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = {
      code: status
    };
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/activate/${username}`, body)
      .then((data) => {
        if (data.status === 200) {
          if (data.data.message === 'The user does not exist') {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Vous ne pouvez pas gérer ce PDV'
            });
          } else if (data.data.message === 'The user has been activated') {
            dispatch({
              type: FETCH_SUCCESS,
              payload: 'PDV a été activé avec succès'
            });
          } else if (data.data.message === 'The user has been desactivated') {
            dispatch({
              type: FETCH_SUCCESS,
              payload: 'PDV a été déactivé avec succès'
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: data.data.message
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        if (error.response.data.code === '00') {
          dispatch({type: FETCH_ERROR, payload: 'Fichier non valide'});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: "Un erreur s'est produit! Réessayez plus tard"
          });
        }
      });
  };
};
export const onBlockPOS = (username, status) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = {
      isBlocked: status
    };
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/block/${username}`, body)
      .then((data) => {
        if (data.status === 200) {
          if (data.data.data === 2202) {
            dispatch({
              type: FETCH_SUCCESS,
              payload: 'PDV a été modifié avec succès'
            });
          } else
            dispatch({
              type: FETCH_ERROR,
              payload: 'Vous ne pouvez pas effectuer cette action pour le moment'
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong']
        });
      });
  };
};
export const onCreateWallet = (id_user) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = {
      commercial_id: id_user
    };
    dispatch({type: FETCH_START});
    dataAxios
      .post('/report/wallet', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({
            type: FETCH_SUCCESS
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Wallet crée avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Vous ne pouvez pas effectuer cette action pour le moment'
          });
        }
      })
      .catch((error) => {
        if (error.response.data.message === 'This commercial_id has already a wallet') {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Cet utilisateur possède déjà une wallet'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      });
  };
};
export const getAllCommercialDemands = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/feed/wallet', {params: param})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.code === 2202) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_COMMERCIAL_DEMANDS,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_COMMERCIAL_DEMANDS,
              payload: {totalPages: 0, data: {}}
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getReportById = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/report/${id}`)
      .then((res) => {
        console.log('***********Repoooooort', res);

        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_REPORT_BY_ID,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getStatisticCity = () => {
  console.log('7777');
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/company/stats/region/status')
      .then((res) => {
        console.log('***********Repoooooort', res);

        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_STATISTIC_CITY_PDV,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getStatisticProbabilityActivationPDV = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/stats')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_STATISTIC_CITY_PDV_PROBABILITY,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onDeleteImg = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/user-management/image/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteDocument = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete('/user-management/document/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const oncCreateDocument = (body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/user-management/document', body)
      .then((res) => {
        if (res.status === 201) {
          if (res.data.message === 'Document was successfully created') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Documents modifiés avec succès!'
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateImg = (body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/user-management/image', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous devez ajouter qu'une seule image"
        });
      });
  };
};

export const onUpdateServices = (body) => {
  const {messages} = appIntl();
  return (dispatch) => {
    // const body = {
    //   code: status,
    // };
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/service-user/update', body)
      .then((data) => {
        if (data.data.status === 'success') {
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Sorry this visitor can not be accepted'
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: 'this visitor can not be accepted'
        });
      });
  };
};

export const onGetCsvFile = (exportUrl, port, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(exportUrl, {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          console.log(res);
          if (res.data.code === 2202) {
            dispatch(
              onUploadFiles({
                url: res.data.data.path.slice(1),
                port: port
              })
            );
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const onGeneratePassword = (username) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/resend-mail/${username}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.etat === 'Success') {
            dispatch({
              type: FETCH_SUCCESS
            });
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Un email contenant le nouveau mot de passe a été envoyé au POS'
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR
        });
      });
  };
};
export const onGetAllReports = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'no report found') {
              dispatch({
                type: GET_ALL_COMMERCIAL_REPORTS,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_COMMERCIAL_REPORTS,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};

export const getLeadsEnAttente = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/allleads', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'no report found') {
              dispatch({
                type: GET_ALL_LEADS_EN_ATTENTE,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_LEADS_EN_ATTENTE,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const getLeadsAContacter = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/allleads', {params: params})
      .then((res) => {
        console.log('99999999999999999', res.data.data);
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'no report found') {
              dispatch({
                type: GET_ALL_LEADS_A_CONTACTER,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_LEADS_A_CONTACTER,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const getLeadsPlanifier = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/allleads', {params: params})
      .then((res) => {
        console.log('888888888888', res.data.data);
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'no report found') {
              dispatch({
                type: GET_ALL_LEADS_PLANIFIER,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_LEADS_PLANIFIER,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const getLeadsChauds = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/allleads', {params: params})
      .then((res) => {
        if (res.status === 200) {
          console.log('res?.data', res?.data);
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'no report found') {
              dispatch({
                type: GET_ALL_LEADS_CHAUD,
                payload: []
              });
            } else {
              dispatch({
                type: GET_ALL_LEADS_CHAUD,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const onGetAllReportParams = (endpoint, actionType, params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(endpoint, {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.data?.data) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: actionType,
              payload: res.data.data
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const onGetCommercialList = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/commercial', {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMERCIAL_LIST,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const setCompanyFilters = (filters) => {
  return (dispatch) => {
    //const body = filters;
    dispatch({
      type: SET_COMPANY_FILTERS,
      payload: filters
    });
  };
};
export const setCompanyFiltersDeleted = (filters) => {
  return (dispatch) => {
    //const body = filters;
    dispatch({
      type: SET_COMPANY_FILTERS_DELETED,
      payload: filters
    });
  };
};
export const onDemandCreateCompany = (company) => {
  const {messages} = appIntl();
  let timeout = 0;
  return (dispatch) => {
    const body = company;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/register', body)
      .then((data) => {
        //  //   //console.log(data, 'response');
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Votre demande a été envoyée! '
          });
        } else {
          if (data.data.code === '2003') {
            if (data.data.error === 'Phone already exist') {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Numéro de téléphone existe déjà'
              });
            } else if (data.data.error === 'email already exist') {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Email saisi existe déjà Veuillez réessayer!'
              });
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: "Nom d'utilisateur saisi existe déjà Veuillez réessayer!"
              });
            }
          } else if (data.data.code === '2007') {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Cet utilisateur est déactivé!'
            });
          } else if (data.data.code === '2008') {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Le format de mot de passe est invalide! Veuillez réessayer'
            });
          } else if (data.data.code === '2009') {
            dispatch({
              type: FETCH_ERROR,
              payload: "L'e-mail que vous avez utilisé n'est pas confirmé!"
            });
          } else {
            dispatch({type: FETCH_ERROR, payload: data.data.code});
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
        if (error?.response?.data?.error?.code === 2003) {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Veuillez vérifer vos données'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: error?.response?.data?.error?.message
          });
        }
      });
  };
};
export const withdrowMoney = (parmasRequest) => {
  const {messages} = appIntl();

  return (dispatch) => {
    const body = parmasRequest;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/retrait', body)
      .then((data) => {
        //  //   //console.log(data, 'response');
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Opération effectuée avec Succeés '
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: `Problème dans l'exécution de l'opération.`
        });
      });
  };
};

export const onGetSourceList = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/source', {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SOURCE_LIST,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetProfileById = (id, param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get(`/user-management/profile/${id}`, {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_PROFILE_BY_ID,
            payload: res.data.data
          });
          dispatch({
            type: GET_TPE_IMAGES,
            payload: null
          });
          dispatch({
            type: SET_EMPTY_DOCS,
            payload: []
          });
          // dispatch({
          //   type: GET_DOC_IMAGES,
          //   payload: [],
          // });
          if (res.data?.data?.data?.Tpe?.path) {
            dispatch(
              onUploadImages(
                {
                  url: res.data.data.data.Tpe.path?.slice(1),
                  port: imgBaseUrl
                },
                'tpe'
              )
            );
          }
          if (res.data?.data?.data?.Company?.Contracts?.length > 0) {
            console.log(
              res.data?.data?.data.Company?.Contracts,
              'res.data?.data?.data.company?.Contracts'
            );
            let b = res.data?.data?.data.Company?.Contracts;
            for (let k = 0; k < b.length; k++) {
              dispatch(onUploadImages({url: b[k].path?.slice(1), port: imgBaseUrl}, 'docs'));
            }
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetAllComments = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/comment', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'no comment found') {
              dispatch({
                type: GET_ALL_COMMERCIAL_COMMENTS,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_COMMERCIAL_COMMENTS,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};

export const onGetAllStatisticLeads = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/leads/stat', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'No Leads found') {
              dispatch({
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_STATISTIC_LEADS,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};

export const onGetStatisticCRM = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/leads/stat/all', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'No Leads found') {
              dispatch({
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_STATISTIC_LEADS_CRM,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};

export const onGetAllLeads = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/leads', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'No Leads found') {
              dispatch({
                type: GET_ALL_COMMERCIAL_LEADS,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_COMMERCIAL_LEADS,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
export const onDeleteMeeting = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete(`/report/meeting/` + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'RDV supprimé avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllAppointements = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/report/meeting', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data) {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message === 'no meeting found') {
              dispatch({
                type: GET_ALL_COMMERCIAL_APPOINTEMENT,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_COMMERCIAL_APPOINTEMENT,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};
