const {
  POST_SERVICE,
  POST_CATEGORY,
  GET_SUPPLIER,
  GET_INTERVENANT,
  GET_SERVICE,
  GET_SERVICE_BY_ID,
  GET_REIMBURSED,
  GET_CATEGORY,
  GET_WALLET_DETAILS,
  GET_WALLET_DETAILS_SERVICE,
  GET_ALL_SUPPLIER_SERVICE,
  GET_TOPUP_BY_ID,
  GET_WALLET_BY_ID,
  GET_POSTE_BY_ID,
  GET_FILTRED_SERV_SUPP,
  GET_TYPE,
  GET_ACTIVITY,
  GET_COMPANY,
  GET_ALL_PDV,
  GET_ALL_OPERATORS_TOPUP,
  GET_ALL_SIM_TOPUP,
  GET_CATEGORY_PROFILE,
  GET_ONE_COMPANY,
  GET_ALL__SERVICE_SUPPLIER,
  GET_SERVICE_BY_COMPANY_ID,
  GET_TOPUP_BY_COMPANY_ID,
  GET_WALLET_BY_COMPANY_ID,
  GET_POSTE_BY_COMPANY_ID,
  GET_ONE_SERVICE_SUPP,
  GET_SUPPLIER_BY_ID,
  GET_FEED_TYPE,
  GET_COMMISSION_BY_COMPANY_ID,
  GET_RECHARGE_CARD_BY_ID,
  GET_RECHARGE_CARD,
  GET_ALL_CANALS,
  GET_ROLES,
  GET_COMMERCIAL_TYPE_USER,
  GET_ONE_CONFIG_SERVICE,
  GET_ALL_CARD_TOPUP,
  GET_ALL_ORGANISME_TOPUP,
  GET_COMMERCIAL,
  SET_EMPTY_COMPANY,
  GET_ALL_SCOPES,
  GET_ALL_ROLES,
  GET_PROFILE_PIC,
  GET_DOCUMENTS_C,
  GET_DOCUMENTS_P,
  GET_DOCUMENTS_C_EMPTY,
  GET_DOCUMENTS_P_EMPTY,
  GET_PROFILE_PIC_EMPTY,
  GET_GALLERY_EMPTY,
  GET_GALLERY_PIC,
  GET_FEED_WALLET_BY_USER,
  SET_EMPTY_FEED_WALLET_BY_USER,
  GET_DETAIL_BY_ID,
  GET_ALL_REGIONS,
  GET_ALL_EXTERNAL_VOUCHER,
  GET_COMMISSION_STATS_BY_ID,
  UPDATE_BALANCE_SIM,
  GET_ALL_COMMENTS_USER,
  GET_ALL_LIST_VISIT_PDV,
  GET_ALL_COMMERCIAL_VISIT,
  GET_ALL_USERS_VISIT,
  GET_COMPANY_DELETED,
  GET_ALL_IMPORTED_PROSPECTION,
  GET_ONE_REPORT,
  GET_ALL_LIST_CONTACT,
  GET_PROSPECTION_BY_ID,
  GET_ALL_CA_SERVICE,
  GET_COMMISSION_TOPUP_BY_ID,
  GET_COMMISSION_BY_ID,
  GET_COMMISSION_BY_ID_VOUCHER,
  GET_COMMISSION_BY_ID_RW,
  GET_COMMISSION_BY_ID_RC,
  GET_COMMISSION_BY_ID_PF,
  GET_COMMISSION_BY_ID_BOO,
  GET_COMMISSION_BY_ID_GAMING,
  GET_COMMISSION_BY_ID_DIWEN,
  GET_COMMISSION_BY_ID_INTERNET_SABBA
} = require('shared/constants/ActionTypes');

const INIT_STATE = {
  canals_profiles: [],
  isSpecificTopup: false,
  isSpecificVoucher: false,
  isSpecificRW: false,
  isSpecificPFP: false,
  isSpecificBoking: false,
  isSpecificGaming: false,
  isSpecificDiwen: false,
  isSpecificInternetSabba: false,
  isSpecificRC: false,
  service: [],
  category: [],
  supplier: [],
  reimbursed: [],
  intervenant: [],
  suppService: [],
  service_id: {},
  serviceVoucher: [],
  commissions: [],
  serviceTopup: [],
  servicePoste: [],
  serviceWallet: [],
  filtredServSup: [],
  typePos: [],
  activity: [],
  company: [],
  operators_top: [],
  simcard: [],
  totalPages: 0,
  totalPage: 0,
  totalItems: 0,
  totalPageC: 0,
  category_profile: [],
  getAllPdv: [],
  user: {},
  profile: {},
  wallet: [],
  listWallet: [],
  walletdetails: [],
  historyWallet: [],
  Commission: [],
  typeUser: [],
  CompanyDetail: {},
  Category: [],
  serviceSupplier: [],
  activities_CompaniesC: [],
  voucherCompany: [],
  topupCompany: [],
  walletCompany: [],
  posteCompany: [],
  ServSupplierDetails: {},
  typeService: {},
  supplier_details: {},
  commission_details: null,

  typeVirement: [],
  companyList: {},
  commissionDetails: [],
  historyWalletDetails: [],
  canals: [],
  rolesPos: [],
  commercialId: '',
  detailsConfig: {},
  card: [],
  organisme: [],
  serviceRechargeCard: [],
  commercial: [],
  scopes: [],
  roles: [],
  documentsC: [],
  documentsP: [],
  profilePic: null,
  galleryPic: [],
  documentsFeed: [],
  detail: {},
  region: [],
  externalVoucher: [],
  extraData: [],
  updateSim: {},
  comments: [],
  listVisit: [],
  totalPagesVisit: 0,
  listCommercialVisit: [],
  listUsersVisit: [],
  companyDeleted: [],
  companyListDeleted: {},
  totalPageCDeleted: 0,
  totalItemsDeleted: 0,
  importedprospection: {},
  testtt: {},
  listOfContact: {},
  extraDatatotalca: {},
  commissionsPdv: {},
  prospectionData: {},
  CAService: {},
  commissionTopup: [],
  specificCommission: [],
  specificCommissionVoucher: [],
  specificCommissionRW: [],
  specificCommissionRC: [],
  specificCommissionPF: [],
  specificCommissionBOO: [],
  serviceBooking: [],
  specificCommissionGaming: [],
  serviceGaming: [],
  specificCommissionDiwen: [],
  serviceDiwen: [],
  specificCommissionInternetSabba: [],
  serviceInternetSabba: []
};
const configurationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_IMPORTED_PROSPECTION:
      return {...state, importedprospection: action.payload};
    case GET_ONE_REPORT:
      return {...state, testtt: action.payload};
    case GET_ALL_LIST_CONTACT:
      return {...state, listOfContact: action.payload};
    case GET_PROSPECTION_BY_ID:
      return {...state, prospectionData: action.payload};
    case GET_ALL_CA_SERVICE:
      return {...state, CAService: action.payload};
    case POST_SERVICE:
      return {
        ...state,
        service: [action.payload, ...state.category]
      };
    case GET_ALL_LIST_VISIT_PDV:
      return {
        ...state,
        listVisit: action.payload.data.items,
        totalPagesVisit: action.payload.data.totalPages
      };
    case GET_ALL_COMMERCIAL_VISIT:
      return {...state, listCommercialVisit: action.payload};
    case GET_ALL_USERS_VISIT:
      return {...state, listUsersVisit: action.payload};
    case UPDATE_BALANCE_SIM:
      return {...state, updateSim: action.payload};
    case SET_EMPTY_COMPANY:
      return {
        ...state,
        profile: [],
        documentsC: [],
        documentsP: [],
        CompanyDetail: [],
        walletId: null,
        wallet: null,
        user: {}
      };
    case GET_ONE_SERVICE_SUPP:
      return {
        ...state,
        ServSupplierDetails: action.payload,
        Commission: action.payload.commissions,
        typeService: action.payload.TypeService
        // code:action.payload.code,
        // name:action.payload.name,
        // sill_number:action.payload.sill_number,
        // hasSill:action.payload.hasSill,
        // enable:action.payload.enable,
        // limited_amount:action.payload.limited_amount,
        // reimbursedId:action.payload.reimbursedId,
        // supplierId:action.payload.supplierId,
        // typeServiceId:action.payload.typeServiceId,
      };
    case POST_CATEGORY: {
      return {
        ...state,
        category: [action.payload, ...state.service]
      };
    }
    case GET_ALL_CANALS: {
      return {
        ...state,
        canals: action.payload.data
      };
    }
    case GET_ALL_REGIONS: {
      return {
        ...state,
        region: action.payload
      };
    }
    case GET_ALL_EXTERNAL_VOUCHER: {
      return {
        ...state,
        externalVoucher: action.payload.items,
        totalPages: action.payload.totalPages,
        extraData: action.payload.extraData
      };
    }
    case GET_COMMISSION_BY_COMPANY_ID: {
      return {
        ...state,
        commissionDetails: action.payload.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_COMMISSION_TOPUP_BY_ID:
      return {
        ...state,
        commissionTopup: action.payload
      };

    case GET_COMMISSION_BY_ID:
      return {
        ...state,
        specificCommission: action.payload,
        serviceTopup: action.payload.Services
      };
    case GET_COMMISSION_BY_ID_VOUCHER:
      return {
        ...state,
        specificCommissionVoucher: action.payload,
        serviceVoucher: action.payload.Services
      };
    case GET_COMMISSION_BY_ID_RW:
      return {
        ...state,
        specificCommissionRW: action.payload,
        serviceToChange: action.payload.Services
      };
    case GET_COMMISSION_BY_ID_RC:
      return {
        ...state,
        specificCommissionRC: action.payload,
        serviceRechargeCard: action.payload.Services
      };
    case GET_COMMISSION_BY_ID_PF:
      return {
        ...state,
        specificCommissionPF: action.payload,
        servicePoste: action.payload.Services
      };
    case GET_COMMISSION_BY_ID_BOO: {
      return {
        ...state,
        specificCommissionBOO: action.payload,
        serviceBooking: action.payload.Services
      };
    }
    case GET_COMMISSION_BY_ID_GAMING: {
      return {
        ...state,
        specificCommissionGaming: action.payload,
        serviceGaming: action.payload.Services
      };
    }
    case GET_COMMISSION_BY_ID_DIWEN: {
      return {
        ...state,
        specificCommissionDiwen: action.payload,
        serviceDiwen: action.payload.Services
      };
    }
    case GET_COMMISSION_BY_ID_INTERNET_SABBA: {
      return {
        ...state,
        specificCommissionInternetSabba: action.payload,
        serviceInternetSabba: action.payload.Services
      };
    }
    case GET_ALL_OPERATORS_TOPUP: {
      return {
        ...state,
        operators_top: action.payload.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_SIM_TOPUP: {
      return {
        ...state,
        simcard: action.payload.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_CARD_TOPUP: {
      return {
        ...state,
        card: action.payload.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_ORGANISME_TOPUP: {
      return {
        ...state,
        organisme: action.payload.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_SUPPLIER: {
      return {
        ...state,
        supplier: action.payload?.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_SUPPLIER_BY_ID: {
      return {
        ...state,
        supplier_details: action.payload
      };
    }
    case GET_COMMISSION_STATS_BY_ID: {
      return {
        ...state,
        commission_details: action.payload
      };
    }
    case GET_ACTIVITY: {
      return {
        ...state,
        activity: action.payload.data
      };
    }
    case GET_PROFILE_PIC: {
      return {
        ...state,
        profilePic: action.payload
      };
    }
    case GET_PROFILE_PIC_EMPTY: {
      return {
        ...state,
        profilePic: null
      };
    }
    case GET_DOCUMENTS_C_EMPTY: {
      return {
        ...state,
        documentsC: []
      };
    }
    case GET_DOCUMENTS_P_EMPTY: {
      return {
        ...state,
        documentsP: []
      };
    }
    case GET_GALLERY_EMPTY: {
      return {
        ...state,
        galleryPic: []
      };
    }
    case GET_DOCUMENTS_C: {
      return {
        ...state,
        documentsC: [...state.documentsC, action.payload]
      };
    }
    case GET_DOCUMENTS_P: {
      return {
        ...state,
        documentsP: [...state.documentsP, action.payload]
      };
    }
    case GET_GALLERY_PIC: {
      return {
        ...state,
        galleryPic: [...state.galleryPic, action.payload]
      };
    }
    case GET_FEED_WALLET_BY_USER: {
      return {
        ...state,
        documentsFeed: [...state.documentsFeed, action.payload]
      };
    }

    case SET_EMPTY_FEED_WALLET_BY_USER: {
      return {
        ...state,
        documentsFeed: []
      };
    }
    case GET_ONE_COMPANY: {
      if (action.payload) {
        if (action.payload.company !== undefined) {
          return {
            ...state,
            isSpecific: action.payload.isSpecific,
            user: action.payload.user,
            profile: action.payload.profile,
            typeUser: action.payload.profile?.Type_user,
            CompanyDetail: action.payload.profile?.Company,
            Category: action.payload.profile?.Company.Category,
            //documentsP: action.payload.profile?.documentsP,
            //documentsC: action.payload.profile?.Company.documentsC,
            wallet: action.payload.wallet,
            walletId: action.payload.wallet?.id,
            historyWallet: action.payload.historyWallet,
            Commission: action.payload.commission,
            activities_CompaniesC:
              action.payload.profile?.Company.activities_CompaniesC[0].Activity,
            servDisable: action.payload.services,
            canals_profiles: action.payload.profile?.canals_profiles.map((item) => item.TypeCanalId)
          };
        } else {
          return {
            ...state,
            isSpecificTopup: action.payload.commission?.find(
              (comm) =>
                comm.Service?.code === 'TopupOR' ||
                comm.Service?.code === 'TopupTT' ||
                comm.Service?.code === 'TopupOO'
            )
              ? true
              : false,
            isSpecificVoucher: action.payload.commission?.find(
              (comm) =>
                comm.Service?.code === 'VoucherOR' ||
                comm.Service?.code === 'VoucherTT' ||
                comm.Service?.code === 'VoucherOO'
            )
              ? true
              : false,
            isSpecificRW: action.payload.commission?.find(
              (comm) => comm.Service?.code === 'Sobflouss'
            )
              ? true
              : false,
            isSpecificPFP: action.payload.commission?.find((comm) => comm.Service?.code === 'PFC')
              ? true
              : false,
            isSpecificBoking: action.payload.commission?.find(
              (comm) => comm.Service?.code === 'HOTELS_BEDBANK'
            )
              ? true
              : false,
            isSpecificGaming: action.payload.commission?.find(
              (comm) => comm.Service?.code === 'MINTROUTE'
            )
              ? true
              : false,
            isSpecificDiwen: action.payload.commission?.find(
              (comm) => comm.Service?.code === 'VC-DIWAN'
            )
              ? true
              : false,

            isSpecificInternetSabba: action.payload.commission?.find(
              (comm) => comm.Service?.code === 'INTERNET_SABBA_TT'
            )
              ? true
              : false,
            isSpecificRC: action.payload.commission?.find((comm) => comm.Service?.code === 'RC')
              ? true
              : false,
            isSpecific: action.payload.isSpecific,
            user: action.payload.user,
            profile: action.payload.profile,
            canals_profiles: action.payload.profile?.canals_profiles.map(
              (item) => item.TypeCanalId
            ),
            CompanyDetail: action.payload.profile?.Company,
            typeUser: action.payload.profile?.Type_user,
            //documentsP: action.payload.profile?.documentsP,
            //documentsC: action.payload.profile?.Company?.documentsC,
            wallet: action.payload.wallet,
            walletId: action.payload.wallet?.id,
            historyWallet: action.payload.historyWallet,
            Commission: action.payload.commission,
            servDisable: action.payload.services
          };
        }
      } else {
        return {
          ...state,
          isSpecificTopup: null,
          isSpecificVoucher: null,
          isSpecificRW: null,
          isSpecificPFP: null,
          isSpecific: null,
          profile: null,
          isSpecificBoking: null,
          isSpecificGaming: null,
          isSpecificDiwen: null,
          isSpecificInternetSabba: null,
          isSpecificRC: null
        };
      }
    }
    case GET_COMPANY_DELETED: {
      return {
        ...state,
        companyDeleted: action.payload.data,
        companyListDeleted: action.payload.data.Company,
        totalPageCDeleted: action.payload.totalPages,
        totalItemsDeleted: action.payload.totalItems ? action.payload.totalItems : 0
      };
    }
    case GET_COMPANY: {
      return {
        ...state,
        company: action.payload.data,
        companyList: action.payload.data.Company,
        totalPageC: action.payload.totalPages,
        totalItems: action.payload.totalItems ? action.payload.totalItems : 0
      };
    }
    case GET_INTERVENANT: {
      return {
        ...state,
        intervenant: action.payload?.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_TYPE: {
      return {
        ...state,
        typePos: action.payload.data
      };
    }
    case GET_ROLES: {
      return {
        ...state,
        rolesPos: action.payload
      };
    }
    case GET_SERVICE: {
      return {
        ...state,
        service: action.payload?.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_SERVICE_BY_COMPANY_ID: {
      return {
        ...state,
        voucherCompany: action.payload.Services
      };
    }
    case GET_TOPUP_BY_COMPANY_ID: {
      return {
        ...state,
        topupCompany: action.payload.Services
      };
    }
    case GET_WALLET_BY_COMPANY_ID: {
      return {
        ...state,
        walletCompany: action.payload.Services
      };
    }
    case GET_POSTE_BY_COMPANY_ID: {
      return {
        ...state,
        posteCompany: action.payload.Services
      };
    }
    case GET_SERVICE_BY_ID: {
      return {
        ...state,
        serviceVoucher: action.payload.Services
      };
    }
    case GET_TOPUP_BY_ID: {
      return {
        ...state,
        serviceTopup: action.payload.Services
      };
    }
    case GET_WALLET_BY_ID: {
      return {
        ...state,
        serviceWallet: action.payload.Services
      };
    }
    case GET_POSTE_BY_ID: {
      return {
        ...state,
        servicePoste: action.payload.Services
      };
    }
    case GET_RECHARGE_CARD_BY_ID: {
      return {
        ...state,
        serviceRechargeCard: action.payload.Services
      };
    }
    case GET_RECHARGE_CARD: {
      return {
        ...state,
        serviceRechargeCard: action.payload.Services
      };
    }
    case GET_REIMBURSED: {
      return {
        ...state,
        reimbursed: action.payload.items
      };
    }
    case GET_CATEGORY_PROFILE: {
      return {
        ...state,
        category_profile: action.payload.data
      };
    }
    case GET_CATEGORY: {
      return {
        ...state,
        category: action.payload?.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_SUPPLIER_SERVICE: {
      return {
        ...state,
        suppService: action.payload?.items
      };
    }
    case GET_FILTRED_SERV_SUPP: {
      return {
        ...state,
        filtredServSup: action.payload
      };
    }
    case GET_FEED_TYPE: {
      return {
        ...state,
        typeVirement: action.payload?.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL__SERVICE_SUPPLIER: {
      return {
        ...state,
        serviceSupplier: action.payload?.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_COMMERCIAL_TYPE_USER: {
      return {
        ...state,
        commercialId: action.payload?.data[0].id
      };
    }
    case GET_COMMERCIAL: {
      return {
        ...state,
        commercial: action.payload
      };
    }
    case GET_ONE_CONFIG_SERVICE: {
      return {
        ...state,
        detailsConfig: action.payload
      };
    }
    case GET_ALL_SCOPES: {
      return {
        ...state,
        scopes: action.payload
      };
    }
    case GET_ALL_ROLES: {
      return {
        ...state,
        roles: action.payload
      };
    }
    case GET_DETAIL_BY_ID: {
      return {
        ...state,
        detail: action.payload
      };
    }
    case GET_WALLET_DETAILS: {
      return {
        ...state,
        listWallet: action.payload?.data?.data,
        totalPages: action.payload?.data?.totalPages,
        extraDatatotalca: action.payload?.extraData
      };
    }
    case GET_ALL_PDV: {
      return {...state, getAllPdv: action.payload?.data};
    }
    case GET_WALLET_DETAILS_SERVICE: {
      return {
        ...state,
        commissionsPdv: {
          commOReDetails: action.payload.commission_details.filter((item) =>
            item.code.includes('OR')
          ),
          commTTDetails: action.payload.commission_details.filter((item) =>
            item.code.includes('TT')
          ),
          commOODetails: action.payload.commission_details.filter((item) =>
            item.code.includes('OO')
          ),
          commLycaDetails: action.payload.commission_details.filter((item) =>
            item.code.includes('LYCA')
          ),
          commAselDetails: action.payload.commission_details.filter((item) =>
            item.code.includes('ASEL')
          ),
          otherComm: action.payload.commission_details.filter(
            (item) =>
              !item.code.includes('ASEL') &&
              !item.code.includes('LYCA') &&
              !item.code.includes('OO') &&
              !item.code.includes('OR') &&
              !item.code.includes('TT')
          ),
          idwalletselected: action.payload.idwalletselected
        }
      };
    }
    case GET_ALL_COMMENTS_USER:
      return {...state, comments: action.payload};
    default:
      return state;
  }
};

export default configurationReducer;
