//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';
export const GET_ALL_RECLAMATION = 'get_all_reclamation';
export const GET_ALL_COMMENTS_RECLAMATION = 'get_all_comments_reclamation';
export const ID_RECLAMATION = 'id_reclamation';
export const GET_ALL_SERVICE = 'get_all_service';
export const GET_ALL_DATA_RAPPROUCHEMNT = 'GET_ALL_DATA_RAPPROUCHEMNT';
export const GET_ALL_TAGS = 'GET_ALL_TAGS';
//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';
//App Livreur
export const GET_ALL_LIVREUR = '  GET_ALL_LIVREUR';
export const GET_ALL_DEMANDE = 'GET_ALL_DEMANDE';
export const GET_DEMANDE_BY_ID = 'GET_DEMANDE_BY_ID ';
export const GET_DEMANDE_RETARD = 'GET_DEMANDE_RETARD';
export const GET_DEMANDE_AFFECTER = 'GET_DEMANDE_AFFECTER';
export const AFFECT_COLLECT = 'AFFECT_COLLECT';
export const COLLECT_REFUSSER = 'COLLECT_REFUSSER';
export const COLLECT_COLLECTER = 'COLLECT_COLLECTER ';
export const GET_ALL_COMMERCIAL = 'GET_ALL_COMMERCIAL';
export const GET_ALL_COMMERCIAL_LIVREUER = 'GET_ALL_COMMERCIAL_LIVREUER';
export const GET_LIST_CASH_COLLECTER = 'GET_LIST_CASH_COLLECTER';
export const GET_ALL_JOURNEY_HISTORY = 'GET_ALL_JOURNEY_HISTORY';
//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//CRYPTO-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//MAIL-APP
export const GET_MAIL_LIST = 'get_mail_list';
export const GET_FOLDER_LIST = 'get_folder_list';
export const GET_LABEL_LIST = 'get_label_list';
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer';
export const COMPOSE_MAIL = 'compose_mail';
export const GET_MAIL_DETAIL = 'get_mail_detail';
export const UPDATE_MAIL_FOLDER = 'update_mail_folders';
export const UPDATE_MAIL_LABEL = 'update_mail_label';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail';
export const CHANGE_READ_STATUS = 'change_read_status';
export const GET_CONNECTION_LIST = 'get_connection_list';
export const NULLIFY_MAIL = 'nullify_mail';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTACT_APP
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST';
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';
export const GET_ALL_COMMERCIAL_COMMENTS = 'GET_ALL_COMMERCIAL_COMMENTS';
export const GET_ALL_COMMERCIAL_APPOINTEMENT = 'GET_ALL_COMMERCIAL_APPOINTEMENT';
//SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD';
export const DELETE_LIST = 'DELETE_LIST';
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL';
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST';

//CHAT_APP
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_USER_MESSAGES = 'get_user_messages';
export const ADD_NEW_MESSAGE = 'add_new_message';
export const EDIT_MESSAGE = 'edit_message';
export const DELETE_MESSAGE = 'delete_message';
export const DELETE_USER_MESSAGES = 'delete_user_messages';
export const TOGGLE_CHAT_DRAWER = 'toggle_chat_drawer';
export const SELECT_USER = 'select_user';

//WALL_APP
export const GET_WALL_DATA = 'get_wall_data';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_NEW_POST = 'create_new_post';
export const UPDATE_POST = 'update_post';

//USER_LIST
export const GET_USER_LIST = 'GET_USER_LIST';

//ECOMMERCE_LIST
export const GET_ECOMMERCE_LIST = 'get_ecommerce_list';
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type';
export const SET_FILTER_DATA = 'set_filter_data';
export const SET_PRODUCT_DATA = 'set_product_data';
export const GET_RECENT_ORDER = 'get_recent_order';
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = 'get_balloon_block_data';
export const UPDATE_BALLOON_BLOCK_DATA = 'update_balloon_block_data';
export const GET_BALLOON_DATA = 'get_balloon_data';
export const UPDATE_BALLOON_DATA = 'update_balloon_data';
export const GET_CLASSIC_DATA = 'get_classic_data';
export const UPDATE_CLASSIC_DATA = 'update_classic_data';
export const GET_INLINE_DATA = 'get_inline_data';
export const UPDATE_INLINE_DATA = 'update_inline_data';
export const GET_DOCUMENT_DATA = 'get_document_data';
export const UPDATE_DOCUMENT_DATA = 'update_document_data';
export const GET_CUSTOM_DATA = 'get_custom_data';
export const UPDATE_CUSTOM_DATA = 'update_custom_data';

//GALLERY
export const GET_GALLERY_PHOTO = 'get_gallery_photo';

//POST_DATA
export const POST_DATA = 'post_data';

//configuration
export const POST_SERVICE = 'post_service';
export const POST_CATEGORY = 'post_category';
export const GET_SUPPLIER = 'get_supplier';
export const GET_SERVICE = 'get_service';
export const GET_REIMBURSED = 'get_reimbursed';
export const GET_INTERVENANT = 'get_intervenant';
export const GET_CATEGORY = 'get_category';
export const GET_ALL_SUPPLIER_SERVICE = 'get_supplier_service';
export const GET_SERVICE_BY_ID = 'get_service_id';
export const GET_TOPUP_BY_ID = 'get_topup_id';
export const GET_WALLET_BY_ID = 'get_wallet_id';
export const GET_RECHARGE_CARD_BY_ID = 'get_recharge_card_by_id';
export const GET_RECHARGE_CARD = 'get_recharge_card';
export const GET_POSTE_BY_ID = 'get_poste_id';
export const GET_SERVICE_COM = 'get _service_com';
export const GET_FILTRED_SERV_SUPP = 'get_filtred_serv_supp';
export const GET_TYPE = 'get_type';
export const GET_ACTIVITY = 'get_activity';
export const GET_COMPANY = 'get_company';
export const GET_ALL_PDV = 'GET_ALL_PDV';
export const GET_ALL_FACIAL = 'get_all_facials';
export const GET_ALL_OPERATORS = 'get_all_operators';
export const GET_ALL_OPERATORS_TOPUP = 'get_all_operators_TOPUP';
export const GET_ALL_SIM_TOPUP = 'get_all_sim_TOPUP';
export const GET_ALL_VOUCHER = ' GET_ALL_VOUCHER';
export const LIST_VIDE = 'LIST_VIDE';
export const GET_ALL_VOUCHER_DOCUMENTS = 'get_all_voucher_document';
export const GET_EMPTY_DOCUMENTS = 'get_empty_document';
export const GET_CATEGORY_PROFILE = 'get_category_profile';
export const GET_ALL_VOUCHER_SETTINGS = 'get_all_voucher_settings';
export const GET_ONE_COMPANY = 'get_one_company';
export const GET_ALL_VOUCHER_TRANSACTIONS = 'get_all_voucher_transactions';
export const GET_ALL__SERVICE_SUPPLIER = 'upplier';
export const GET_ALL_VOUCHER_STOCK = 'get_all_voucher_stock';
export const GET_SERVICE_COM_COMPANY = 'GET_SERVICE_COM_COMPANY';
export const GET_SERVICE_BY_COMPANY_ID = 'GET_SERVICE_BY_COMPANY_ID';
export const GET_TOPUP_BY_COMPANY_ID = 'get_topup_COMPANY_id';
export const GET_WALLET_BY_COMPANY_ID = 'get_wallet_COMPANY_id';
export const GET_WALLET_DETAILS = 'get_wallet_DETAILS';
export const GET_WALLET_DETAILS_SERVICE = 'GET_WALLET_DETAILS_SERVICE';
export const GET_POSTE_BY_COMPANY_ID = 'get_poste_COMPANY_id';
export const GET_COMMISSION_BY_COMPANY_ID = 'GET_COMMISSION_BY_COMPANY_ID';
export const SEY_EMPTY = 'set_empty';
export const GET_USER_STATS = 'get_user_stats';
export const GET_USER_STATS_BYMONTH = 'get_user_stats_by_month';
export const GET_ALL_DEMANDES_USER = 'get_all_demandes_user';
export const GET_EMPTY_LIST = 'get_empty_list';
export const GET_ADMIN_COMMISSIONS = 'get_admin_commissions';
export const GET_ALL_POSTE_RECHARGE = 'get_all_poste_recharge';
export const GET_ALL_POSTE_PAYMENT = 'get_all_poste_payment';
export const GET_ALL_TOPUP_TRANS = 'get_all_topup_trans';
export const GET_HOTEL_BALANCE = 'get_hotel_balance';
export const GET_ONE_SERVICE_SUPP = 'get_one_service_supp';
export const GET_SUPPLIER_BY_ID = 'GET_SUPPLIER_BY_ID';
export const GET_COMMISSION_STATS_BY_ID = 'GET_COMMISSION_STATS_BY_ID';

export const GET_STOCK_VOUCHER = 'GET_STOCK_VOUCHER';
export const GET_ALL_CARDS = 'GET_ALL_CARDS';
export const GET_FEED_TYPE = 'get_feed_type';
export const GET_ALL_AFFILIATION = 'GET_ALL_AFFILIATION';
export const GET_ALL_TRANSACTION = 'GET_ALL_TRANSACTION';
export const GET_ALL_NOT_INSERD = 'GET_ALL_NOT_INSERD';
export const GET_ALL_FEED_DEMANDES_USER = 'get_all_feed_demands_user';
export const GET_HISTORY_WALLET = 'GET_HISTORY_WALLET';
export const GET_ALL_IMPORT_API_OOREDOO = 'get_all_import_api_ooredoo';
export const POST_IMPORT_API_OOREDOO = 'post_import_api_ooredoo';
export const GET_TOTAL_WALLET = 'GET_TOTAL_WALLET';
export const GET_ALL_TOPUP_STOCK = 'GET_ALL_TOPUP_STOCK';
export const GET_ALL_FEEDING = 'get_all_feeding';
export const GET_ALL_FEEDING_PAYMENT_TYPE = 'get_all_feeding_payment_type';
export const GET_ALL_FEED_SERVICES = 'get_all_feed_services';
export const GET_ALL_FEED_PROVIDER = 'get_all_feed_provider';
export const GET_ALL_FEED_DOCUMENTS = 'get_all_feed_documents';
export const GET_ALL_CANALS = 'get_all_canals';
export const GET_ROLES = 'get_roles';
export const GET_COMMERCIAL_TYPE_USER = 'get_all_type_commercial';
export const GET_ONE_CONFIG_SERVICE = 'get_one_confih_service';
export const GET_ALL_ORGANISME_TOPUP = 'GET_ALL_ORGANISME_TOPUP';
export const GET_ALL_CARD_TOPUP = 'GET_ALL_CARD_TOPUP';
export const GET_COMMERCIAL = 'get_commercial';
export const SET_EMPTY_COMPANY = 'set_empty_company';
export const SET_CSV_PATH = 'set_csv_path';
export const GET_ALL_SCOPES = 'get_all_scopes';
export const GET_ALL_ROLES = 'get_all_roles';
export const GET_DOCUMENTS_C = 'get_documents_c';
export const GET_DOCUMENTS_P = 'get_documents_P';
export const GET_PROFILE_PIC = 'get_profile_pic';
export const GET_DOCUMENTS_C_EMPTY = 'get_documents_c_empty';
export const GET_DOCUMENTS_P_EMPTY = 'get_documents_P_empty';
export const GET_PROFILE_PIC_EMPTY = 'get_empty_profile_pic';
export const GET_GALLERY_PIC = 'get_gallery_pic';
export const GET_GALLERY_EMPTY = 'get_gallery_empty';
export const GET_FEED_WALLET_BY_USER = 'get_feed_wallet_by_user';
export const GET_ALL_TOPUP_OPERATORS = 'ge_all_topup_operators';
export const GET_ALL_TOPUP_TYPES = 'ge_all_topup_types';
export const SET_EMPTY_FEED_WALLET_BY_USER = 'set_empty_feed_wallet_by_user';
export const GET_ALL_COMMERCIAL_REPORTS = 'get_all_comercial_reports';
export const GET_DETAIL_BY_ID = 'get_details_by_id';
export const GET_ALL_POTENTAL_REPORTS = 'get_all_potental_reports';
export const GET_ALL_VISIT_TYPES_REPORTS = 'get_all_visit_types_reports';
export const GET_ALL_COMMERCE_TYPE_REPORTS = 'get_all_commerce_type_reports';
export const GET_ALL_MINTROUTE_PRODUCTS = 'get_all_mintroute_products';
export const GET_ALL_MINTROUTE_BRANDS = 'get_all_mintroute_brands';
export const GET_ALL_MINTROUTE_HISTORY = 'get_all_mintroute_history';
export const GET_ALL_EXCHANGE = 'GET_ALL_EXCHANGE';
export const GET_ALL_TYPE = 'GET_ALL_TYPE';
export const GET_USER_STATS_BYREG = 'get_user_stats_by_region';
export const GET_USER_STOCK = 'get_user_stock';
export const GET_ALL_MINTROUTE_TRANS = 'get_all_mintroute_transactions';
export const GET_ALL_POSTE_PAYMENT_ORGANISMS = 'get_all_payment_organisms';
export const GET_ALL_POSTE_PAYMENT_INVOICES = 'get_all_payment_invoices';
export const SET_EMPTY_HISTORY_WALLET = 'set_empty_history_wallet';
export const SET_RECIEVED_CODE_FROM_EMAIL = 'set_recieved_code_from_email';
export const SET_OPEN_CHANGE_PASSWORD_DIALOG = 'set_change_password_dialog';
export const SET_IS_FIRST_CONNECTION = 'set_is_first_connection';
export const GET_ALL_REGIONS = 'get_all_regions';
export const GET_ALL_EXTERNAL_VOUCHER = 'get_all_externall_voucher';
export const GET_COMMERCIAL_DEMANDS = 'get_commercial_demands';
export const GET_HISTORY_COMMERCIAL_WALLET = 'get_history_commercial_wallet';
export const GET_ALL_FEED_DEMANDES_USER_COMMERCIAL = 'get_all_feed_demands_user_commercial';

export const GET_COMMERCIAL_LIST = 'ge_commercial_list';

//HOTEL
export const GET_ALL_HOTEL_CITITES = 'GET_ALL_HOTEL_CITITES';
export const GET_ALL_HOTEL_TRANS = 'GET_ALL_HOTEL_TRANS';
export const GET_ALL_HOTEL_DESTINATION = 'GET_ALL_HOTEL_DESTINATION';
export const GET_ALL_HOTEL_BOOKING_HOTEL = 'GET_ALL_HOTEL_BOOKING_HOTeL';
export const GET_ALL_USERS_VOUCHER = 'get_all_users_voucher';
export const GET_ALL_TOPNET_HISTORY = 'get_all_topnet_history';
export const GET_ALL_USERS_TOPNET = 'get_all_users_topnet';

export const SET_COMPANY_FILTERS = 'set_company_filters';
export const GET_INVOICE_PIC = 'get_invoice_pic';
export const GET_SOURCE_LIST = 'get_source_list';

export const GET_COMMERCIAL_PIC = 'get_commercial_pic';

//Comment Reports
export const GET_ALL_COMMENT_REPORT = 'GET_ALL_COMMENT_REPORT';
export const POST_NEW_COMMENT_REPORT_ID = 'POST_NEW_COMMENT_REPORT_ID';
export const GET_ALL_COMMENTS_REPORT_ID = 'GET_ALL_COMMENTS_REPORT_ID';
export const GET_ALL_TYPE_CONTACT = 'GET_ALL_TYPE_CONTACT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const DENY_MODAL = 'DENY_MODAL';
export const ADD_TYPE_CONTACT_REPORT = 'ADD_TYPE_CONTACT_REPORT';
export const GET_ALL_SUBSCRIPTION_TYPE = 'GET_ALL_SUBSCRIPTION_TYPE';
export const POST_TYPE_SUBSCRIPTION = 'POST_TYPE_SUBSCRIPTION';
export const GET_TYPE_SUBSCRIPTION_BY_ID = 'GET_TYPE_SUBSCRIPTION_BY_ID';
export const GET_ALL_SUBSCRIPTION = 'GET_ALL_SUBSCRIPTION';
export const GET_ALL_SUBSCRIBERS = 'GET_ALL_SUBSCRIBERS';
export const GET_ALL_SUBSCRIPTION_HISTORY = 'GET_ALL_SUBSCRIPTION_HISTORY';
export const POST_SUBSCRIPTION = 'POST_SUBSCRIPTION';
export const POST_SUBSCRIBERS = 'POST_SUBSCRIBERS';
export const POST_ALL_SUBSCRIBERS = 'POST_ALL_SUBSCRIBERS';
export const GET_ALL_USER_SUBSCRIBERS = 'GET_ALL_USER_SUBSCRIBERS';
export const GET_COMMERCIAL_WALLET = 'get_commercial_wallet';
export const UPDATE_BALANCE_SIM = 'UPDATE_BALANCE_SIM';
export const GET_ALL_HISTORY_TOPUP = 'get_all_history_topup';
export const GET_TPE_IMAGES = 'get_tpe_images';
export const GET_PROFILE_BY_ID = 'get_profile_by_id';
export const FAIL = 'FAIL';

export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const POST_RECLAMATION_SUCCESS = 'POST_RECLAMATION_SUCCESS';
export const POST_RECLAMATION_FAIL = 'POST_RECLAMATION_FAIL';
export const GET_PERIORITE = 'GET_PERIORITE';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_RECLAMATION_ID = 'GET_RECLAMATION_ID';
export const GET_ALL_FEED_COMMERCIAL_CREDIT = 'GET_ALL_FEED_COMMERCIAL_CREDIT';
export const GET_ALL_FEED_COMMERCIAL_ENCAISEMENT = 'GET_ALL_FEED_COMMERCIAL_ENCAISEMENT';
export const POST_NEW_COMMENT_USER = ' POST_NEW_COMMENT_USER';
export const GET_ALL_COMMENTS_USER = ' GET_ALL_COMMENTS_USER';
export const GET_ALL_LIST_VISIT_PDV = 'GET_ALL_LIST_VISIT_PDV';
export const GET_ALL_COMMERCIAL_VISIT = 'GET_ALL_COMMERCIAL_VISIT';
export const GET_ALL_USERS_VISIT = 'GET_ALL_USERS_VISIT';
export const GET_COMPANY_DELETED = 'GET_COMPANY_DELETED';
export const SET_COMPANY_FILTERS_DELETED = 'SET_COMPANY_FILTERS_DELETED';

export const GET_DOC_IMAGES = 'set_doc_images';
export const SET_EMPTY_DOCS = 'SET_EMPTY_DOCS';
export const GET_ALL_IMPORTED_PROSPECTION = 'GET_ALL_IMPORTED_PROSPECTION';

export const GET_ALL_MINTROUTE_BALANCE = 'GET_ALL_MINTROUTE_BALANCE';

export const POST_NEW_REPORT = 'POST_NEW_REPORT';
export const PATCH_REPORT = 'PATCH_REPORT';
export const GET_ONE_REPORT = 'GET_ONE_REPORT';
export const GET_ONE_REPORT_NOT_FOUND = 'GET_ONE_REPORT_NOT_FOUND';
export const GET_ONE_REPORT_ERROR = 'GET_ONE_REPORT_ERROR';
export const SET_ONE_BRAND = 'SET_ONE_BRAND';
export const GET_ALL_LIST_CONTACT = 'GET_ALL_LIST_CONTACT';

export const GET_ALL_PDV_CITY = 'GET_ALL_PDV_CITY';
export const GET_REPORT_BY_ID = 'GET_REPORT_BY_ID';
export const GET_PROSPECTION_BY_ID = 'GET_PROSPECTION_BY_ID';
export const GET_ALL_INTERNET_SABBA_DATA = 'get_all_internet_sabba_data';
export const GET_ALL_CA_SERVICE = 'GET_ALL_CA_SERVICE';
export const GET_ALL_STATISTICS_COMMERCIAL = 'GET_ALL_STATISTICS_COMMERCIAL';
export const GET_ALL_DEPASSED_CREDIT = 'GET_ALL_DEPASSED_CREDIT';
export const GET_STATISTIC_CITY_PDV = 'GET_STATISTIC_CITY_PDV';
export const GET_STATISTIC_CITY_PDV_PROBABILITY = 'GET_STATISTIC_CITY_PDV_PROBABILITY';
export const GET_ALL_TPE = 'GET_ALL_TPE';
export const SET_STOCK_BY_OPERATOR = 'set_stock_by_operator';
export const POST_TPE = 'POST_TPE';
export const GET_ALL_NewRECLAMATION = ' GET_ALL_NewRECLAMATION';
export const GET_ALL_INTIAL_RECLAMATION = 'GET_ALL_INTIAL_RECLAMATION';
export const GET_ALL_INPROGRESS_RECLAMATION = 'GET_ALL_INPROGRESS_RECLAMATION';
export const GET_ALL_TRAITED_RECLAMATION = 'GET_ALL_TRAITED_RECLAMATION';
export const GET_ALL_CLOTURED_RECLAMATION = 'GET_ALL_CLOTURED_RECLAMATION';
export const GET_ALL_REFUSED_RECLAMATION = 'GET_ALL_REFUSED_RECLAMATION';
export const GET_ALL_MODALITY = 'GET_ALL_MODALITY';
export const GET_ALL_HISTORY_COMMAND = 'GET_ALL_HISTORY_COMMAND';
export const GET_ALL_BILL_HISTORY = 'GET_ALL_BILL_HISTORY';
export const POST_NEW_LEADS_REPORT_ID = 'POST_NEW_LEADS_REPORT_ID';
export const GET_ALL_COMMERCIAL_LEADS = 'GET_ALL_COMMERCIAL_LEADS';
export const GET_ALL_STATISTIC_LEADS = 'GET_ALL_STATISTIC_LEADS';
export const GET_ALL_LEADS_EN_ATTENTE = 'GET_ALL_LEADS_EN_ATTENTE';
export const GET_ALL_LEADS_A_CONTACTER = 'GET_ALL_LEADS_A_CONTACTER';
export const GET_ALL_LEADS_PLANIFIER = 'GET_ALL_LEADS_PLANIFIER';
export const GET_ALL_LEADS_CHAUD = 'GET_ALL_LEADS_CHAUD';
export const GET_ALL_LEADS_EN_ATTENTE_REQUEST = 'GET_ALL_LEADS_EN_ATTENTE_REQUEST';
export const GET_TPE_IMAGES_BANK = 'GET_TPE_IMAGES_BANK';
export const GET_ALL_STATISTIC_LEADS_CRM = 'GET_ALL_STATISTIC_LEADS_CRM';
export const GET_ALL_TPE_MAINTENANCE = 'GET_ALL_TPE_MAINTENANCE';
export const GET_MAINTENANCE_TPE_BY_ID = 'GET_MAINTENANCE_TPE_BY_ID';
export const GET_TPE_BON_TRANSFERT = 'GET_TPE_BON_TRANSFERT';
export const GET_TPE_MAINTENANCE_IMAGES = 'GET_TPE_MAINTENANCE_IMAGES';
export const GET_MAINTENANCE_D_EMPTY = 'GET_MAINTENANCE_D_EMPTY';
export const GET_ALL_HISTORY_TPE = 'GET_ALL_HISTORY_TPE';
export const GET_ALL_TPE_SALES = 'GET_ALL_TPE_SALES';
export const GET_TPE_VENTE_IMAGES = 'GET_TPE_VENTE_IMAGES';
export const GET_FACTURE_TPE_VENTE_IMAGES = 'GET_FACTURE_TPE_VENTE_IMAGES';
export const GET_TPE_VENDU_ID = 'GET_TPE_VENDU_ID';
export const GET_VENTE_D_EMPTY = ' GET_VENTE_D_EMPTY';
export const GET_RECLAMATION_BY_ID = 'GET_RECLAMATION_BY_ID';
export const GET_RECLAMATION_BY_PDV = '  GET_RECLAMATION_BY_PDV';
export const GET_ALL_FOURNISSEUR = 'GET_ALL_FOURNISSEUR';
export const GET_FOURNISSEUR_ID = 'GET_FOURNISSEUR_ID';
export const GET_ALL_RESERVATION = ' GET_ALL_RESERVATION';
export const SET_VOUCHER_STATUS_FALSE = 'set_voucher_status_false';
export const GET_NB_PRINTED = 'GET_NB_PRINTED';
export const GET_ALL_HISTORY_VOUCHER = 'get_all_history_voucher';
export const GET_ALL_TYPE_OPERATOR = 'get_all_type_operator';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_VOUCHER_PDF = 'get_voucher_pdf';
export const GET_VOUCHER_STATUS = 'get_voucher_status';
export const GET_ENABLED_SERVICES = 'get_enabled_services';
export const GET_ALL_FACIAL_VOUCHER = 'get_all_facials_voucher';
export const GET_ALL_OPERATORS_VOUCHER = 'get_all_operators_voucher';
export const CREATE_OBJECT_FROM_SERV_SUPP = 'create_object_from_serv_supp';
export const GET_DIALOG_STATUS = 'get_dialog_status';
export const SET_DIALOG_RESULT_FALSE = 'set_dialog_result_false';
export const GET_ALL_DUPLICATED_TRABSATIONS = 'GET_ALL_DUPLICATED_TRABSATIONS';
export const GET_COMMISSION_TOPUP_BY_ID = 'GET_COMMISSION_TOPUP_BY_ID';
export const GET_COMMISSION_BY_ID = 'GET_COMMISSION_BY_ID';
export const GET_COMMISSION_BY_ID_VOUCHER = 'GET_COMMISSION_BY_ID_VOUCHER';
export const GET_COMMISSION_BY_ID_RW = 'GET_COMMISSION_BY_ID_RW';
export const GET_COMMISSION_BY_ID_RC = 'GET_COMMISSION_BY_ID_RC';
export const GET_COMMISSION_BY_ID_PF = 'GET_COMMISSION_BY_ID_PF';
export const GET_COMMISSION_BY_ID_BOO = 'GET_COMMISSION_BY_ID_BOO';
export const GET_COMMISSION_BY_ID_GAMING = 'GET_COMMISSION_BY_ID_GAMING';
export const GET_COMMISSION_BY_ID_DIWEN = 'GET_COMMISSION_BY_ID_DIWEN';
export const GET_COMMISSION_BY_ID_INTERNET_SABBA = 'GET_COMMISSION_BY_ID_INTERNET_SABBA';
export const POS_CREATED = 'POS_CREATED';
export const GET_ALL_RECLAMATION_TRAITED = 'GET_ALL_RECLAMATION_TRAITED ';
