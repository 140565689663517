import {
  GET_ALL_FEEDING,
  GET_ALL_FEEDING_PAYMENT_TYPE,
  GET_ALL_FEED_DEMANDES_USER,
  GET_ALL_FEED_DEMANDES_USER_COMMERCIAL,
  GET_ALL_FEED_DOCUMENTS,
  GET_ALL_FEED_PROVIDER,
  GET_ALL_FEED_SERVICES,
  GET_ALL_LIVREUR,
  GET_ALL_DEMANDE,
  GET_DEMANDE_BY_ID,
  GET_DEMANDE_RETARD,
  GET_DEMANDE_AFFECTER,
  COLLECT_REFUSSER,
  COLLECT_COLLECTER,
  GET_ALL_COMMERCIAL,
  GET_LIST_CASH_COLLECTER
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  totalPages: 0,
  demands: [],
  services: [],
  paymentType: [],
  feed: [],
  provider: [],
  documents: [],
  livreurs: [],
  commercial: [],
  cashCollocter: [],
  listDemandeAlimanataion: [],
  demandeAlimantationById: [],
  demandeCollectReatd: [],
  demandeCollecter: [],
  listCollectRefuser: [],
  listCollectAffecter: [],
  feedUserCommercialDemands: [],
  totalAmount: 0,
  totalItems: 0
};

const alimentationReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_FEED_DEMANDES_USER:
      return {
        ...state,
        demands: action.payload.data ? action.payload.data : [],
        totalPages: action.payload.totalPages,
        totalAmount: action.payload.totalAmount,
        totalItems: action.payload.totalItems
      };
    case GET_ALL_FEEDING:
      return {
        ...state,
        feed: action.payload ? action.payload.data : [],
        totalPages: action.payload ? action.payload.totalPages : 0
      };

    case GET_ALL_FEEDING_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload.items,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case GET_ALL_FEED_SERVICES:
      return {
        ...state,
        services: action.payload.items,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case GET_ALL_FEED_PROVIDER:
      return {
        ...state,
        provider: action.payload.items,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case GET_ALL_LIVREUR:
      return {
        ...state,
        livreurs: action.payload.data
      };
    case GET_ALL_COMMERCIAL:
      return {
        ...state,
        commercial: action.payload.data
      };
    case GET_LIST_CASH_COLLECTER:
      return {
        ...state,
        cashCollocter: action.payload.data
      };

    case GET_ALL_DEMANDE:
      return {
        ...state,
        listDemandeAlimanataion: action.payload.data,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case GET_DEMANDE_BY_ID:
      return {
        ...state,
        demandeAlimantationById: action.payload.data,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case GET_DEMANDE_RETARD:
      return {
        ...state,
        demandeCollectReatd: action.payload.data,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case COLLECT_REFUSSER:
      return {
        ...state,
        listCollectRefuser: action.payload.data,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case GET_DEMANDE_AFFECTER:
      return {
        ...state,
        listCollectAffecter: action.payload.data,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case COLLECT_COLLECTER:
      return {
        ...state,
        demandeCollecter: action.payload.data,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case GET_ALL_FEED_DOCUMENTS:
      return {
        ...state,
        documents: action.payload.items,
        totalPages: action.payload ? action.payload.totalPages : 0
      };
    case GET_ALL_FEED_DEMANDES_USER_COMMERCIAL: {
      return {
        ...state,
        feedUserCommercialDemands: action.payload.data,
        totalPages: action.payload.totalPages,
        totalAmount: action.payload.totalAmount,
        totalItems: action.payload.totalItems
      };
    }
    default:
      return state;
  }
};

export default alimentationReducer;
