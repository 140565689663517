import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import {Fonts} from 'shared/constants/AppEnums';
import AppAnimate from '../AppAnimate';
import {Breadcrumbs, TextField, Typography} from '@material-ui/core';
import {Link, Route} from 'react-router-dom';
import ChangePasswordDialog from '../ConfirmationDialog/ChangePasswordDialog';
import ConfirmWithFormDialog from '../ConfirmationDialog/ConfirmWithForm';
import {useDispatch, useSelector} from 'react-redux';
import {onSendCodeByEmail} from 'redux/actions/Configuration';
import {useField} from 'formik';

const MyTextField = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  // const classes = useStyles(props);
  return (
    <TextField
      {...props}
      {...field}
      // className={classes.root}
      fullWidth='100%'
      helperText={errorText}
      error={!!errorText}
    />
  );
};
const ComponentHeader = () => {
  const useStyles = makeStyles((theme) => ({
    containerHeader: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      paddingBottom: 16,
      [theme.breakpoints.up('xl')]: {
        paddingTop: 16
      }
    },
    linkIcon: {
      paddingLeft: 4
    },
    textbase: {
      fontSize: 15,
      fontWeight: Fonts.REGULAR
    }
  }));

  const classes = useStyles();
  const recivedCodeValidationInfo = useSelector(({auth}) => auth.recivedCodeValidationInfo);
  const dispatch = useDispatch();
  function onSendCode(data) {
    //console.log(data);
    dispatch(
      onSendCodeByEmail(
        {
          code: data.code,
          old_password: data.old_password,
          new_password: data.new_password
        },
        true
      )
    );
  }
  return (
    <AppAnimate animation='transition.slideDownIn' delay={300}>
      <Box className={classes.containerHeader}>
        <Route>
          {({location}) => {
            const pathnames = location.pathname.split('/').filter((x) => x);

            return (
              <Breadcrumbs aria-label='breadcrumb'>
                <Link exact style={{textDecoration: 'none', color: 'black'}} to='/'>
                  Accueil
                </Link>
                {/* {routesConfig.map((item) => {
                  
                  return (
                    <Typography color='textPrimary' key={item.id}>
                      {item.title}
                    </Typography>
                  )
                })} */}
                {pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                  const regexExp =
                    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
                  value = value.replaceAll('-', ' ');
                  // String with valid UUID separated by dash

                  return last ? (
                    <Typography
                      style={{
                        textDecoration: 'none',
                        fontWeight: 600,
                        color: '#003250'
                      }}>
                      {regexExp.test(value) ? 'Details' : value.replace('-', ' ')}
                    </Typography>
                  ) : (
                    <Link exact style={{textDecoration: 'none', color: 'black'}} to={to}>
                      {value.replace('-', ' ')}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            );
          }}
        </Route>
        <ChangePasswordDialog />
        {recivedCodeValidationInfo?.openDialog ? (
          <ConfirmWithFormDialog
            // open={recivedCodeValidationInfo?.openDialog}
            // onDeny={() =>
            //   dispatch({
            //     type: SET_RECIEVED_CODE_FROM_EMAIL,
            //     payload: {openDialog: false},
            //   }).
            // }
            onConfirm={onSendCode}
            title={
              'Veuillez saisir le code reçu par email pour confirmer le changement de votre mot de passe'
            }
            dialogTitle={'Vous avez reçu un code par email pour confirmer!'}>
            <MyTextField
              name='code'
              label={'Code'}
              className={classes.textField}
              variant='outlined'
              type='text'
            />
          </ConfirmWithFormDialog>
        ) : null}
      </Box>
    </AppAnimate>
  );
};

export default ComponentHeader;

// ComponentHeader.propTypes = {
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string,
//   refUrl: PropTypes.string,
// };
// ComponentHeader.defaultProps = {};
