import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_RECLAMATION,
  GET_ALL_COMMENTS_RECLAMATION,
  GET_ALL_NewRECLAMATION,
  ID_RECLAMATION,
  SHOW_MESSAGE,
  GET_ALL_SERVICE,
  GET_RECLAMATION_BY_PDV,
  FIALED_PROCESS,
  LIST_VIDE,
  GET_ALL_RECLAMATION_TRAITED,
  GET_ALL_INTIAL_RECLAMATION,
  GET_ALL_INPROGRESS_RECLAMATION,
  GET_ALL_REFUSED_RECLAMATION,
  GET_ALL_CLOTURED_RECLAMATION,
  GET_ALL_TRAITED_RECLAMATION
} from 'shared/constants/ActionTypes';
import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {imgBaseUrl} from 'shared/constants/AppConst';
import {onUploadFiles, onUploadImages} from '.';

const filterItemsByEtat = (data, etat) => {
  return data.filter((item) => item.etat === etat);
};

export const onGetAllReclamations = (params, etat) => {
  console.log('params', params);
  console.log('etatetat', etat);
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/feedback/reclamation', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data.status === 'success') {
            dispatch({type: FETCH_SUCCESS});
            const dataResponse = res.data.data.items;
            const result = filterItemsByEtat(dataResponse, etat);
            switch (etat) {
              case 0:
                {
                  dispatch({
                    type: GET_ALL_INTIAL_RECLAMATION,
                    payload: result
                  });
                }
                break;
              case 1:
                {
                  dispatch({
                    type: GET_ALL_INPROGRESS_RECLAMATION,
                    payload: result
                  });
                }
                break;
              case 2:
                {
                  dispatch({
                    type: GET_ALL_TRAITED_RECLAMATION,
                    payload: result
                  });
                }
                break;
              case 3:
                {
                  dispatch({
                    type: GET_ALL_REFUSED_RECLAMATION,
                    payload: result
                  });
                }
                break;
              case 4: {
                dispatch({
                  type: GET_ALL_CLOTURED_RECLAMATION,
                  payload: result
                });
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages['message.somethingWentWrong']
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong']
        });
      });
  };
};

export const onGetAllCommentsforReclamation = (id, params) => {
  const {messages} = appIntl();

  return (dispatch) => {
    dispatch({
      type: ID_RECLAMATION,
      payload: id
    });
    dispatch({type: FETCH_START});
    dataAxios
      .get('/feedback/comment?reclamationid=' + id, {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data.status === 'success') {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message !== 'Comments retrieved') {
              dispatch({
                type: GET_ALL_COMMENTS_RECLAMATION,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_COMMENTS_RECLAMATION,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};

export const postNewComment = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/feedback/comment', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: res.data.message
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const postNewService = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .post('/feedback/service', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: res.data.message
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllServices = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/feedback/service', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res?.data.status === 'success') {
            dispatch({type: FETCH_SUCCESS});
            if (res.data.message !== 'Services retrieved') {
              dispatch({
                type: GET_ALL_SERVICE,
                payload: {data: [], totalPages: 0}
              });
            } else {
              dispatch({
                type: GET_ALL_SERVICE,
                payload: res.data.data
              });
            }
          } else {
            dispatch({type: FETCH_SUCCESS});
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: "Vous ne pouvez pas accéder à l'historique pour le moment"
        });
      });
  };
};

export const patchreclamation = (id, body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .patch('/feedback/reclamation/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: res.data.message
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllNewReclamation = (id, params) => {
  const {messages} = appIntl();

  return async (dispatch) => {
    dispatch({type: FETCH_START});

    try {
      const res = await dataAxios.get(`/feedback/reclamation/idPdv?id_user=${id}`, {
        params: params
      });

      dispatch({
        type: GET_RECLAMATION_BY_PDV,
        payload: res.data
      });
    } catch (error) {
      console.log('error when GET_RECLAMATION_BY_PDV ', error);
    }
  };
};

/*const getAllNewReclamation = () => {
  try {pu
    setIsLoading(true);
    dataAxios
      .get(`/feedback/reclamation/idPdv?id_user=${idPdv}`)
      .then((res) => {
        if (res.status === 200) {
          const response = res.data.data.items;
          setReclamation(response);
        } else {
          console.log('Something went wrong');
        }
      })
      .catch((error) => {
        console.log(`Network error${error}`);
      });
  } catch (error) {
    console.log('Error:', error.message);
  }
};*/

//
export const getAllNewReclamationTraited = (params) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch({type: FETCH_START});

    try {
      const res = await dataAxios.get(`/feedback/reclamation/getAll`, {
        params: params
      });

      dispatch({
        type: GET_ALL_RECLAMATION_TRAITED,
        payload: res.data.data
      });
    } catch (error) {
      console.log('error when GET_RECLAMATION_BY_PDV ', error);
    }
  };
};
