import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL__SERVICE_SUPPLIER,
  GET_CATEGORY,
  GET_EMPTY_DOCUMENTS,
  GET_FEED_TYPE,
  GET_INTERVENANT,
  GET_ONE_SERVICE_SUPP,
  GET_REIMBURSED,
  GET_SERVICE,
  GET_SUPPLIER,
  GET_SUPPLIER_BY_ID,
  GET_WALLET_DETAILS,
  GET_WALLET_DETAILS_SERVICE,
  POST_CATEGORY,
  SHOW_MESSAGE,
  GET_COMMISSION_STATS_BY_ID,
  GET_ALL_CA_SERVICE
} from 'shared/constants/ActionTypes';
import {fetchStart} from '.';

export const onCreateService = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/service', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateCategory = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/category', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: POST_CATEGORY,
            payload: res.data
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateIntervenant = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/intervenant', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});

          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateTypeFeed = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/feed/type', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Type alimentation ajouté avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onCreateSupplier = (supplier) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = supplier;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/wallet/supplier', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllServiceSupplier = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('wallet/supplier-service', {params: params})
      .then((res) => {
        console.log('GET_ALL__SERVICE_SUPPLIER', res.data.data.totalPages);
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'ServiceSupplier retrieved') {
            dispatch({
              type: GET_ALL__SERVICE_SUPPLIER,
              payload: res.data.data
            });
            dispatch({
              type: FETCH_SUCCESS,
              payload: 'Service supplier retrieved successfully'
            });
          } else {
            dispatch({
              type: GET_EMPTY_DOCUMENTS,
              payload: []
            });
            // dispatch({
            //   type: FETCH_ERROR,
            //   payload: 'Pas de données trouvées',
            // });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onCreateServiceSupplier = (supplier) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = supplier;
    dispatch({type: FETCH_START});
    dataAxios
      .post('wallet/supplier-service', body)
      .then((data) => {
        if (data.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetService = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/service', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2203) {
            dispatch({
              type: GET_SERVICE,
              payload: {
                items: [],
                totalPages: 0
              }
            });
          } else if (res.data.code === 2202) {
            dispatch({
              type: GET_SERVICE,
              payload: res.data?.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetAllIntervenant = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/intervenant', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_INTERVENANT,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllFeedType = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/feed/type')
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === 'no feed found') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_FEED_TYPE,
              payload: {items: [], totalPages: 0}
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_FEED_TYPE,
              payload: res.data.data
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllSuppliers = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/supplier', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SUPPLIER,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetAllCAService = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/invoice/turnover/organization', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_CA_SERVICE,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const onGetAllReimbursed = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/reimbursed')
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_REIMBURSED,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onDeleteService = (property, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .delete(`/wallet/${property}/` + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Type alimentation supprimé avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetSupplierDetails = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/supplier/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SUPPLIER_BY_ID,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditOIntervenant = (property, service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put(`/wallet/${property}/` + id, body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: res.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditServicePatch = (property, service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch(fetchStart());
    const body = service;
    dataAxios
      .patch(`/wallet/${property}/` + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Votre service fournisseur a été modifié avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditService = (property, service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = service;
    dataAxios
      .put(`/wallet/${property}/` + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'La modification a été éffectuée avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onAddTeam = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = service;
    dataAxios
      .post('/team-register', body)
      .then((res) => {
        if (res.status === 201) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === '2003') {
            if (res.data.error === 'Phone already exist') {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Numéro de téléphone existe déjà'
              });
            } else if (res.data.error === 'email already exist') {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Email saisi existe déjà Veuillez réessayer!'
              });
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: 'Nom utilisateur saisi existe déjà Veuillez réessayer!'
              });
            }
          }
          // dispatch({
          //   type: SHOW_MESSAGE,
          //   payload: res.data.message,
          // });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditTypeVirement = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .put('/wallet/feed/type/' + id, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'Type alimentaion modifié avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetAllCategories = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/category', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_CATEGORY,
            payload: res.data?.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onGetOneServiceSupp = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('wallet/supplier-service/' + id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ONE_SERVICE_SUPP,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllWalletDetails = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/company', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_WALLET_DETAILS,
            payload: res.data?.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getCommissionStatsByPdv = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/stats/wallet/' + id)
      .then((res) => {
        if (res.status === 200) {
          console.log('**-after dispatch res.dat', res.data);
          console.log('**-after dispatch res.data.data', res.data.data.data);
          console.log('**-typeof', typeof res.data.data.data);

          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_COMMISSION_STATS_BY_ID,
            payload: res.data.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onEditServiceSupplier = (service, id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = service;
    dataAxios
      .patch(`/wallet/supplier-service/update/` + id, body)
      .then((res) => {
        console.log('voucher selected');

        console.log('voucher eeevouchereee', body);
        console.log('voucher res', res);

        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: 'La modification a été éffectuée avec succès'
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};

export const getWalletDetails = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/wallet/ca/company/' + id)
      .then((res) => {
        if (res.status === 200) {
          console.log('########3############', res.data?.data);
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_WALLET_DETAILS_SERVICE,
            payload: {
              commission_details: res.data?.data,
              idwalletselected: id
            }
          });
          console.log('##########2##########', res.data?.data?.data);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        console.log(error, 'erroooooooorrrrr');
        dispatch({type: FETCH_ERROR});
      });
  };
};
