import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const posteRouteConfig = [
  {
    auth: authRole.onlYSupperAdmin,
    routes: [
      {
        path: '/Liste-Cartes-Poste',
        component: React.lazy(() => import('./card/ListCards'))
      }
    ]
  },
  {
    auth: authRole.onlYSupperAdmin,
    routes: [
      {
        path: '/Poste-Transfert',
        component: React.lazy(() => import('./transferBalance/index'))
      }
    ]
  },
  {
    auth: authRole.onlYSupperAdmin,
    routes: [
      {
        path: '/Liste-Affiliation',
        component: React.lazy(() => import('./listAffiliation'))
      }
    ]
  }
];
