import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const FournisseurConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Fournisseurs',
        component: React.lazy(() => import('./Pages/Fournisseurs'))
      },
      {
        exact: true,
        path: '/Fournisseurs/Details/:id',
        component: React.lazy(() => import('./Pages/Details'))
      }
    ]
  }
];
