import {
  GET_ALL_COMMERCE_TYPE_REPORTS,
  GET_ALL_COMMERCIAL_REPORTS,
  GET_ALL_COMMERCIAL_COMMENTS,
  GET_ALL_COMMERCIAL_APPOINTEMENT,
  GET_ALL_DEMANDES_USER,
  GET_ALL_POTENTAL_REPORTS,
  GET_ALL_VISIT_TYPES_REPORTS,
  GET_COMMERCIAL_DEMANDS,
  GET_COMMERCIAL_LIST,
  GET_EMPTY_LIST,
  GET_HISTORY_COMMERCIAL_WALLET,
  GET_HISTORY_WALLET,
  GET_SOURCE_LIST,
  GET_TOTAL_WALLET,
  SET_COMPANY_FILTERS,
  SET_CSV_PATH,
  SET_EMPTY_HISTORY_WALLET,
  GET_ALL_COMMENTS_REPORT_ID,
  GET_ALL_TYPE_CONTACT,
  SHOW_MODAL,
  DENY_MODAL,
  GET_COMMERCIAL_WALLET,
  GET_TPE_IMAGES,
  GET_TPE_IMAGES_BANK,
  GET_PROFILE_BY_ID,
  GET_ALL_FEED_COMMERCIAL_CREDIT,
  GET_ALL_FEED_COMMERCIAL_ENCAISEMENT,
  SET_COMPANY_FILTERS_DELETED,
  GET_DOC_IMAGES,
  SET_EMPTY_DOCS,
  GET_REPORT_BY_ID,
  GET_ALL_STATISTICS_COMMERCIAL,
  GET_ALL_DEPASSED_CREDIT,
  GET_STATISTIC_CITY_PDV,
  GET_STATISTIC_CITY_PDV_PROBABILITY,
  GET_ALL_COMMERCIAL_LEADS,
  GET_ALL_STATISTIC_LEADS,
  GET_ALL_LEADS,
  GET_ALL_LEADS_A_CONTACTER,
  GET_ALL_LEADS_EN_ATTENTE,
  GET_ALL_LEADS_PLANIFIER,
  GET_ALL_LEADS_CHAUD,
  GET_ALL_STATISTIC_LEADS_CRM
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  demands: [],
  historyWallet: [],
  totalPages: 0,
  totalAmount: 0,
  totalItems: 0,
  stock: undefined,
  totale_wallet: undefined,
  potential: [],
  visitTypes: [],
  commerceType: [],
  reports: [],
  allcomments: [],
  allLeads: [],
  allStatisticLeads: [],
  allReasonCRM: [],
  allappointements: [],
  reportData: {},
  commercialExtract: [],
  feedCommercial: {},
  feedCommercialEncaisement: {},
  statisticCommercial: {},
  depassedCredit: {},
  statisticCityPdv: [],
  statisticCityPdvProbability: [],
  filters: {
    page: 1,
    size: 10,
    first_name: undefined,
    last_name: undefined,
    username: undefined,
    phone: undefined,
    city: undefined,
    pos: undefined,
    order_updatedAt: 'desc',
    typeUser_id: undefined,
    start_date: undefined,
    end_date: undefined,
    end_date_last_comment: undefined,
    start_date_last_comment: undefined,
    team: false,
    code: undefined,
    demandNot: 1
  },
  filtersDeleted: {
    page: 1,
    size: 10,
    first_name: undefined,
    last_name: undefined,
    username: undefined,
    phone: undefined,
    city: undefined,
    pos: undefined,
    order_updatedAt: 'desc',
    typeUser_id: undefined,
    start_date: undefined,
    end_date: undefined,
    team: false,
    code: undefined,
    demandNot: 1,
    deleted: 1,
    withDeleted: true,
    isActive: true
  },
  tpeImages: null,
  bankTpe: null,
  docsImg: [],
  profileById: {},
  comments: {},
  leads: {},
  contactType: [],
  showModal: false,
  leadEnAttente: [],
  leadAConacter: [],
  LeadPlanifier: [],
  leadChaud: []
};

const companyReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_FEED_COMMERCIAL_CREDIT:
      return {...state, feedCommercial: action.payload.data};
    case GET_ALL_FEED_COMMERCIAL_ENCAISEMENT:
      return {...state, feedCommercialEncaisement: action.payload.data};
    case GET_ALL_STATISTICS_COMMERCIAL:
      return {...state, statisticCommercial: action.payload.data};
    case GET_ALL_DEPASSED_CREDIT:
      return {...state, depassedCredit: action.payload.data};
    case GET_STATISTIC_CITY_PDV:
      return {
        ...state,
        statisticCityPdv: action.payload?.reduce((accumulator, {city, count, status}) => {
          if (!accumulator[city]) {
            accumulator[city] = {actif: '0', nonActif: '0'};
          }
          if (status === '1') {
            accumulator[city].actif = count;
          } else if (status === '0') {
            accumulator[city].nonActif = count;
          }

          return accumulator;
        }, {})
      };
    case GET_STATISTIC_CITY_PDV_PROBABILITY:
      return {
        ...state,
        statisticCityPdvProbability: action.payload.reduce(
          (accumulator, {city, count, probabilityActivation}) => {
            if (!accumulator[city]) {
              accumulator[city] = {
                hesitated: '0',
                notInterested: '0',
                interested: '0'
              };
            }
            if (probabilityActivation === 'interested') {
              accumulator[city].interested = count;
            } else if (probabilityActivation === 'not interested') {
              accumulator[city].notInterested = count;
            } else if (probabilityActivation === 'hesitated') {
              accumulator[city].hesitated = count;
            }

            return accumulator;
          },
          {}
        )
      };
    case SET_CSV_PATH:
      return {
        ...state,
        csvPath: action.payload.data
      };
    case GET_REPORT_BY_ID:
      return {...state, reportData: action.payload.data};
    case GET_HISTORY_WALLET:
      return {
        ...state,
        historyWallet: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case SET_EMPTY_HISTORY_WALLET:
      return {
        ...state,
        historyWallet: [],
        totalPages: 0
      };
    case GET_TOTAL_WALLET:
      return {
        ...state,
        stock: Math.round(action.payload.stock * 1000) / 1000,
        totale_wallet: Math.round(action.payload.totale_wallet * 1000) / 1000
      };
    case GET_ALL_DEMANDES_USER:
      return {
        ...state,
        demands: action.payload
      };
    case GET_EMPTY_LIST:
      return {
        ...state,
        demands: []
      };
    case GET_ALL_COMMERCIAL_REPORTS: {
      return {
        ...state,
        reports: action.payload.data,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_LEADS_EN_ATTENTE: {
      return {
        ...state,
        leadEnAttente: action.payload
      };
    }

    case GET_ALL_LEADS_A_CONTACTER: {
      return {
        ...state,
        leadAConacter: action.payload
      };
    }
    case GET_ALL_LEADS_PLANIFIER: {
      return {
        ...state,
        LeadPlanifier: action.payload
      };
    }

    case GET_ALL_LEADS_CHAUD: {
      return {
        ...state,
        leadChaud: action.payload
      };
    }

    case GET_ALL_COMMERCIAL_COMMENTS: {
      return {
        ...state,
        allcomments: action.payload.data,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_COMMERCIAL_LEADS: {
      return {
        ...state,
        allLeads: action.payload.data,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_STATISTIC_LEADS: {
      return {
        ...state,
        allStatisticLeads: action.payload.reduce(
          (accumulator, {id_user, count, reason, commercial}) => {
            if (!accumulator[id_user]) {
              accumulator[id_user] = {
                commercial: null,
                Injoignable: '0',
                'Ne répond pas': '0',
                Occupé: '0',
                'Faux numéro': '0',
                'Non intéressé': '0',
                "Il n'a pas de patente": '0',
                'En attente de doc': '0',
                'RDV fixé avec un commercial': '0',
                'A recontacter': '0'
              };
            }
            if (reason === 'Injoignable') {
              accumulator[id_user].Injoignable = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            } else if (reason === 'Ne répond pas') {
              accumulator[id_user]['Ne répond pas'] = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            } else if (reason === 'Occupé') {
              accumulator[id_user].Occupé = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            } else if (reason === 'Faux numéro') {
              accumulator[id_user]['Faux numéro'] = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            } else if (reason === 'Non intéressé') {
              accumulator[id_user]['Non intéressé'] = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            } else if (reason === "Il n'a pas de patente") {
              accumulator[id_user]["Il n'a pas de patente"] = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            } else if (reason === 'En attente de doc') {
              accumulator[id_user]['En attente de doc'] = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            } else if (reason === 'RDV fixé avec un commercial') {
              accumulator[id_user]['RDV fixé avec un commercial'] = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            } else if (reason === 'A recontacter') {
              accumulator[id_user]['A recontacter'] = count;
              accumulator[id_user].commercial = `${commercial?.first_name}${' '}${
                commercial?.last_name
              }`;
            }

            return accumulator;
          },
          []
        )
        // allReasonCRM: action.paypoald.reduce(
        //   (accumulator, { reason, count }) => {
        //     if (!accumulator[reason]) {
        //       accumulator[reason] = {
        //         Injoignable: '0',
        //         'Ne répond pas': '0',
        //         Occupé: '0',
        //         'Faux numéro': '0',
        //         'Non intéressé': '0',
        //         "Il n'a pas de patente": '0',
        //         'En attente de doc': '0',
        //         'RDV fixé avec un commercial': '0',
        //         'A recontacter': '0',
        //       };
        //     }
        //     if (reason === 'Injoignable') {
        //       accumulator[count].Injoignable = count;
        //     }
        //     return accumulator;
        //   }, [],
        // )
      };
    }
    case GET_ALL_STATISTIC_LEADS_CRM: {
      return {
        ...state,
        allReasonCRM: action.payload
      };
    }
    case GET_ALL_COMMERCIAL_APPOINTEMENT: {
      return {
        ...state,
        allappointements: action.payload.data,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_COMMERCE_TYPE_REPORTS: {
      return {
        ...state,
        commerceType: action.payload.data
      };
    }
    case GET_ALL_VISIT_TYPES_REPORTS: {
      return {
        ...state,
        visitTypes: action.payload.data
      };
    }
    case GET_ALL_POTENTAL_REPORTS: {
      return {
        ...state,

        potential: action.payload.data
      };
    }
    case GET_COMMERCIAL_DEMANDS: {
      return {
        ...state,

        commercialDemands: action.payload.data,
        totalPages: action.payload.totalPages
      };
    }
    case GET_HISTORY_COMMERCIAL_WALLET: {
      return {
        ...state,
        commercialExtract: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        totalAmount: action.payload.totalAmount
      };
    }
    case SET_COMPANY_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case SET_COMPANY_FILTERS_DELETED: {
      return {...state, filtersDeleted: action.payload};
    }
    case GET_COMMERCIAL_LIST: {
      return {
        ...state,
        commercial: action.payload.data?.map((item) => ({
          ...item,
          label: item.first_name + ' ' + item.last_name
        })),
        totalPages: action.payload.totalPages
      };
    }
    case GET_COMMERCIAL_WALLET: {
      return {
        ...state,
        wallet: action.payload.data,
        totalPages: action.payload.totalPages
      };
    }
    case GET_SOURCE_LIST: {
      return {
        ...state,
        source: action.payload.data
      };
    }

    case GET_ALL_COMMENTS_REPORT_ID:
      return {
        ...state,
        comments: action.payload.data,
        leads: action.payload.data
      };

    case GET_ALL_TYPE_CONTACT:
      return {...state, contactType: action.payload.data};

    case SHOW_MODAL:
      return {...state, showModal: true};
    case GET_TPE_IMAGES:
      return {...state, tpeImages: action.payload};
    case GET_TPE_IMAGES_BANK:
      return {...state, bankTpe: action.payload};
    case GET_DOC_IMAGES:
      return {...state, docsImg: [...state.docsImg, action.payload]};
    case SET_EMPTY_DOCS:
      return {...state, docsImg: []};
    case GET_PROFILE_BY_ID:
      return {...state, profileById: action.payload.data};
    case DENY_MODAL:
      return {...state, showModal: false};
    default:
      return state;
  }
};

export default companyReducer;
