import initialState from '../../../shared/constants/initialState';
import {LIST_RECLAMATION, LOADING_SUCCESS} from './tickets';

// ACTIONS
export const ADD_TICKET = '[list] Add ticket';
export const REORDER_TICKET_IN_LIST = '[list] Reorder Ticket in List';

// ACTION CREATORS
export function addTicketAction(payload, listId) {
  return {type: ADD_TICKET, payload, listId};
}

export function reorderListAction(payload) {
  return {type: REORDER_TICKET_IN_LIST, payload};
}

// REDUCER
export function listsReducer(state = initialState.lists, action = {}) {
  switch (action.type) {
    case ADD_TICKET:
      return addTicketToList(state, action);
    case REORDER_TICKET_IN_LIST:
      return reorderList(state, action);
    case LIST_RECLAMATION:
      return addTicketToListReclamation(state, action);
    case LOADING_SUCCESS:
      return {...state, isLoading: true};
    default:
      return state;
  }
}

function addTicketToList(state, action) {
  const list = state[action.listId];
  const ticketIds = [...list.ticketIds, action.payload.id];
  const newListState = {...list, ticketIds};
  return {...state, [action.listId]: newListState};
}

function addTicketToListReclamation(state, action) {
  const list1 = state['list-1'];
  const list2 = state['list-2'];
  const list3 = state['list-3'];

  if (action?.payload?.state?.status?.[0] === 'TO DO') {
    const ticketIds = [...list1.ticketIds, action?.payload?.id];
    const newListState = {...list1, ticketIds};

    return {...state, 'list-1': newListState, isLoading: false};
  }

  if (action?.payload?.state?.status?.[0] === 'IN PROGRESS') {
    const ticketIds = [...list2.ticketIds, action?.payload?.id];
    const newListState = {...list2, ticketIds};

    return {...state, 'list-2': newListState, isLoading: false};
  }

  if (action?.payload?.state?.status?.[0] === 'COMPLETED') {
    const ticketIds = [...list3.ticketIds, action?.payload?.id];
    const newListState = {...list3, ticketIds};
    return {...state, 'list-3': newListState, isLoading: false};
  }
}

function reorderList(state, action) {
  const {source, destination, draggableId} = action.payload;

  const start = state[source.droppableId];
  const finish = state[destination.droppableId];

  if (start === finish) {
    const ticketIdsArray = [...start.ticketIds];
    ticketIdsArray.splice(source.index, 1);
    ticketIdsArray.splice(destination.index, 0, draggableId);

    const newList = {...start, ticketIds: ticketIdsArray};
    return {...state, [newList.id]: newList};
  }

  return moveTicketToNewList(state, action, {start, finish});
}

function moveTicketToNewList(state, action, {start, finish}) {
  const {source, destination, draggableId} = action.payload;
  const startTicketIds = [...start.ticketIds];
  startTicketIds.splice(source.index, 1);
  const newStart = {...start, ticketIds: startTicketIds};

  const finishedTicketIds = [...finish.ticketIds];
  finishedTicketIds.splice(destination.index, 0, draggableId);
  const newFinish = {...finish, ticketIds: finishedTicketIds};

  return {
    ...state,
    [newStart.id]: newStart,
    [newFinish.id]: newFinish,
    payload: action.payload
  };
}
