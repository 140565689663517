import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import {amber, green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import {Alert, AlertTitle} from '@material-ui/lab';
import {Collapse} from '@material-ui/core';
import IntlMessages from '@crema/utility/IntlMessages';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};
const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  // root: {
  //   '& .MuiSnackbarContent-root': {
  //     flexGrow: 'initial',
  //     minWidth: '150px',
  //     height: '100px',
  //     [theme.breakpoints.down('sm')]: {
  //       minWidth: '250px',
  //       height: '60px',
  //     },
  //   },
  // },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 25
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20
  }
}));
const AppSnackbar = (props) => {
  const classes = useStyles1();

  const [open, setOpen] = React.useState(true);
  const {className, message, variant, ...other} = props;
  const Icon = variantIcon[variant];

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div id='messageHandler' className={classes.root}>
      <Collapse in={open}>
        {variant.includes('error') ? (
          <Alert
            variant='outlined'
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            autoHideDuration={600000}>
            <AlertTitle>Error</AlertTitle>
            {message}
          </Alert>
        ) : (
          <Alert
            variant='outlined'
            severity='success'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            autoHideDuration={600000}>
            <AlertTitle>
              <IntlMessages id='common.success' />
            </AlertTitle>
            {message}
          </Alert>
        )}
      </Collapse>
    </div>
  );
};
AppSnackbar.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

export default AppSnackbar;
