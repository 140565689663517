import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const DigidisConfig = [
  {
    auth: authRole.user,
    routes: [
      // {
      //   exact: true,
      //   path: '/Digidis/Reservation',
      //   component: React.lazy(() => import('./Pages/Reservation')),
      // },
      {
        exact: true,
        path: '/Digidis/Historique',
        component: React.lazy(() => import('./Pages/Historique'))
      }
    ]
  }
];
