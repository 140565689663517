import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const RapprochementFparcelRouting = [
  {
    auth: authRole.dafAndSupport,
    routes: [
      {
        exact: true,
        path: '/payouts-importer',
        component: React.lazy(() => import('./page/AddPayoute'))
      },
      {
        exact: true,
        path: '/payouts',
        component: React.lazy(() => import('./page/Rapproch'))
      },
      {
        exact: true,
        path: '/payouts-non-rapprochement',
        component: React.lazy(() => import('./page/alimentationNonRapprocher'))
      }
    ]
  }
];
