const localStorageKey = 'appTheme';
const persistedTheme = localStorage.getItem(localStorageKey);
const condition = persistedTheme || 'light';

export default {
  appTheme: condition,
  projects: [],
  users: [],
  tickets: {},
  lists: {
    'list-1': {
      id: 'list-1',
      title: 'À faire',
      ticketIds: []
    },
    'list-2': {
      id: 'list-2',
      title: 'En cours',
      ticketIds: []
    },
    'list-3': {
      id: 'list-3',
      title: 'Terminé',
      ticketIds: []
    },
    payload: {}
  },
  listOrder: ['list-1', 'list-2', 'list-3'],

  isLoading: false
};
