import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const historyPagesConfig = [
  {
    auth: authRole.visitor,
    routes: [
      {
        exact: true,
        path: '/Historique-TOPNET',
        component: React.lazy(() => import('./Pages/topnet'))
      },
      {
        exact: true,
        path: '/Historique-BILL',
        component: React.lazy(() => import('./Pages/bill'))
      },
      {
        exact: true,
        path: '/Historique-TOPUP',
        component: React.lazy(() => import('./Pages/Topup'))
      },

      {
        exact: true,
        path: '/Historique-TOPUP-POSTE',
        component: React.lazy(() => import('./Pages/TopupPoste'))
      }
    ]
  },
  {
    auth: authRole.visitor,
    routes: [
      {
        exact: true,
        path: '/Historique-Voucher',
        component: React.lazy(() => import('./Pages/Voucher'))
      }
    ]
  },
  {
    auth: authRole.visitor,
    routes: [
      {
        exact: true,
        path: '/Historique-Command',
        component: React.lazy(() => import('./Pages/Command'))
      }
    ]
  },

  {
    auth: authRole.visitor,
    routes: [
      {
        exact: true,
        path: '/Historique-Recharge',
        component: React.lazy(() => import('./Pages/Recharge'))
      }
    ]
  },
  {
    auth: authRole.visitor,
    routes: [
      {
        exact: true,
        path: '/Historique-Factures',
        component: React.lazy(() => import('./Pages/Factures'))
      }
    ]
  },
  {
    auth: authRole.visitor,
    routes: [
      {
        exact: true,
        path: '/Historique-TOPUP/:pageNumber',
        component: React.lazy(() => import('../../@crema/core/TableComponent/table'))
      }
    ]
  }
];
