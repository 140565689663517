import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_POSTE_PAYMENT_INVOICES,
  GET_ALL_POSTE_PAYMENT_ORGANISMS,
  SHOW_MESSAGE
} from 'shared/constants/ActionTypes';

export const getAllPaymentOrganisms = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topup/organization', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_POSTE_PAYMENT_ORGANISMS,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const getAllPaymentInvoices = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dispatch({
      type: GET_ALL_POSTE_PAYMENT_INVOICES,
      payload: {totalPages: 0, totalAmount: 0, items: []}
    });
    dataAxios
      .get('/topup/invoice', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_ALL_POSTE_PAYMENT_INVOICES,
            payload: res.data.data
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onAddOrganism = (service) => {
  const {messages} = appIntl();
  return (dispatch) => {
    const body = service;
    dispatch({type: FETCH_START});
    dataAxios
      .post('/topup/organization', body)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.data.message
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});
      });
  };
};
export const onPayInvoice = (idInvoice, status) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .patch(`/topup/invoice/accept/${idInvoice}`, status)
      .then((data) => {
        if (data.status === 200) {
          if (data.data.message === 'Invoice does not exist') {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Vous ne pouvez pas gérer ce PDV'
            });
          } else if (data.data.message === 'The invoice has been updated') {
            dispatch({
              type: FETCH_SUCCESS,
              payload: 'La facture a été payé'
            });
          } else {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: SHOW_MESSAGE,
              payload: data.data.message
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        if (error.response?.data?.code === '00') {
          dispatch({type: FETCH_ERROR, payload: 'Fichier non valide'});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: "Un erreur s'est produit! Réessayez plus tard"
          });
        }
      });
  };
};
