import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const stockPagesConfig = [
  {
    auth: authRole.visitor,
    routes: [
      {
        exact: true,
        path: '/Stock-TOPUP',
        component: React.lazy(() => import('./Pages/StockTopup'))
      },
      {
        exact: true,
        path: '/Stock-Voucher',
        component: React.lazy(() => import('./Pages/StockVoucher'))
      },
      {
        exact: true,
        path: '/Stock-Recharge',
        component: React.lazy(() => import('./Pages/StockEdinar'))
      },
      {
        exact: true,
        path: '/Stock-Limit-Voucher',
        component: React.lazy(() => import('./Pages/StockLimitVoucher.js'))
      },
      {
        exact: true,
        path: '/Mintroute',
        component: React.lazy(() => import('./Pages/Mintroute.js'))
      },
      {
        exact: true,
        path: '/Stock-Hotel',
        component: React.lazy(() => import('./Pages/StockHotel'))
      }
    ]
  }
];
