import * as React from 'react';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';

export const CancelButton = styled(Button)(({theme}) => ({
  color: '#ffffff',
  backgroundColor: '#BA0000',
  padding: '10px 50px',

  '&:hover': {
    backgroundColor: '#717171'
  }
}));
export const ConfirmButton = styled(Button)(({theme}) => ({
  color: '#ffffff',
  padding: '10px 50px',
  backgroundColor: '#3F520D',
  '&:hover': {
    backgroundColor: '#93C021'
  }
}));
