import React, {useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#FBFBFB',
    background: 'linear-gradient(to left, #EE0000, #BA0000)',
    fontWeight: 'normal'
  }
}))(Button);
const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: '100%'
    }
  },
  text: {
    fontSize: '20px',
    fontWeight: 'bolder'
  }
}));

export default function TotalStock() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stock = useSelector(({company}) => company.stock);
  return (
    <div>
      <ColorButton
        variant='contained'
        color='primary'
        className={classes.margin}
        messageId='navbar.total.stock'>
        TOTAL STOCK: <p className={classes.text}>{stock}</p> TND
      </ColorButton>
    </div>
  );
}
