import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../utility/IntlMessages';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import {DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Form, Formik} from 'formik';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {SET_RECIEVED_CODE_FROM_EMAIL} from 'shared/constants/ActionTypes';
import InfoView from '../InfoView';

const validationSchema = yup.object({
  code: yup.string().required('Veuillez saisir le Code reçu par email!')
});
const ConfirmWithFormDialog = ({
  // onDeny,
  onConfirm,
  title,
  dialogTitle,
  // setValue,
  // values,
  children
}) => {
  const useStyle = makeStyles({
    btn: {
      marginLeft: 12,
      fontWeight: Fonts.MEDIUM
    },
    contentText: {
      color: grey[600]
    }
  });
  const classes = useStyle();
  const recivedCodeValidationInfo = useSelector(({auth}) => auth.recivedCodeValidationInfo);
  const dispatch = useDispatch();
  return (
    <Dialog
      maxWidth='sm'
      fullWidth='true'
      open={recivedCodeValidationInfo?.openDialog ? recivedCodeValidationInfo?.openDialog : false}
      // onClose={() =>
      //   dispatch({
      //     type: SET_RECIEVED_CODE_FROM_EMAIL,
      //     payload: {openDialog: false},
      //   })
      // }
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
      <Formik
        validateOnChange={true}
        initialValues={{
          code: ''
        }}
        validationSchema={validationSchema}
        onSubmit={(data, {setErrors, resetForm, setSubmitting}) => {
          setSubmitting(true);
          onConfirm({...recivedCodeValidationInfo, code: data.code});
          //setValue(data.code);
          setSubmitting(false);
          resetForm();
        }}>
        {({isSubmitting, setFieldValue, values}) => (
          <Form className={classes.form} noValidate autoComplete='off'>
            <DialogContent>
              <DialogContentText className={classes.contentText} id='alert-dialog-description'>
                <Box> {title}</Box>
                <InfoView />
              </DialogContentText>
              {children}
            </DialogContent>
            <DialogActions>
              {/* <Button
                variant='contained'
                className={classes.btn}
                onClick={() => onDeny(false)}
                color='secondary'>
                <IntlMessages id='common.no' />
              </Button> */}
              <Button
                variant='contained'
                className={classes.btn}
                type='submit'
                color='primary'
                autoFocus>
                <IntlMessages id='common.yes' />
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ConfirmWithFormDialog;

ConfirmWithFormDialog.propTypes = {
  dialogTitle: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onDeny: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired
};
