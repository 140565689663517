import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const AddReclamation = [
  {
    auth: authRole.admin,
    routes: [
      {
        exact: true,
        path: '/Add-Reclamation',
        component: React.lazy(() => import('./AddReclamation'))
      }
    ]
  }
];
