import {
  GET_ALL_SUBSCRIPTION_TYPE,
  GET_ALL_SUBSCRIPTION,
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIPTION_HISTORY,
  GET_ALL_USER_SUBSCRIBERS,
  GET_ALL_TPE,
  GET_ALL_MODALITY,
  GET_ALL_TPE_MAINTENANCE,
  GET_MAINTENANCE_TPE_BY_ID,
  GET_TPE_BON_TRANSFERT,
  GET_TPE_MAINTENANCE_IMAGES,
  GET_MAINTENANCE_D_EMPTY,
  GET_TPE_VENTE_IMAGES,
  GET_FACTURE_TPE_VENTE_IMAGE,
  GET_ALL_HISTORY_TPE,
  GET_ALL_TPE_SALES,
  GET_FACTURE_TPE_VENTE_IMAGES,
  GET_VENTE_D_EMPTY,
  GET_ALL_DUPLICATED_TRABSATIONS
} from '../../shared/constants/ActionTypes';

const initialState = {
  totalPages: 0,
  totalPagesAbonnement: 0,
  totalPagesAbonne: 0,
  totalPagesHistory: 0,
  totalPagesHistoryTPE: 0,
  totalPagesUserSubscribers: 0,
  totalPagesMaintenance: 0,
  totalItems: 0,
  listeAbonnement: [],
  listeAbonne: [],
  tpeSalesImage: [],
  tpeFactureImages: [],
  bonTransfertP: null,
  tpeMaintenanceP: [],
  historiqueAbonnement: [],
  typeAbonnement: [],
  userSubscriber: [],
  tpeInformation: {},
  tpeMaintenance: [],
  historyTPE: [],
  oneMaintenance: {},
  arrayIdTpe: [],
  totalModality: [],
  duplicatedTransations: []
};

const abonnementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HISTORY_TPE:
      return {
        ...state,
        totalPagesHistoryTPE: action.payload.totalPages,
        historyTPE: action.payload.items
      };
    case GET_TPE_VENTE_IMAGES:
      return {
        ...state,
        tpeSalesImage: [action.payload],
        tpeFactureImages: [action.payload]
      };
    case GET_FACTURE_TPE_VENTE_IMAGES:
      return {
        ...state,
        tpeFactureImages: [action.payload]
      };
    case GET_TPE_BON_TRANSFERT:
      return {
        ...state,
        bonTransfertP: action.payload
      };
    case GET_VENTE_D_EMPTY: {
      return {
        ...state,
        tpeSalesImage: [],
        tpeFactureImages: []
      };
    }
    case GET_TPE_MAINTENANCE_IMAGES:
      return {
        ...state,
        tpeMaintenanceP: [action.payload]
      };
    case GET_MAINTENANCE_D_EMPTY: {
      return {
        ...state,
        tpeMaintenanceP: []
      };
    }
    case GET_ALL_MODALITY:
      return {
        ...state,
        totalModality: action.payload.items
      };
    case GET_MAINTENANCE_TPE_BY_ID:
      return {
        ...state,
        oneMaintenance: action.payload
      };
    case GET_ALL_SUBSCRIPTION_TYPE:
      return {
        ...state,
        typeAbonnement: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_TPE_MAINTENANCE:
      return {
        ...state,
        tpeMaintenance: action.payload.items,
        totalPagesMaintenance: action.payload.totalPages
      };
    case GET_ALL_TPE:
      return {
        ...state,
        tpeInformation: action.payload,
        arrayIdTpe: [action.payload.items.map((el) => el.id)]
      };
    case GET_ALL_SUBSCRIPTION:
      return {
        ...state,
        listeAbonnement: action.payload.items,
        totalPagesAbonnement: action.payload.totalPages
      };
    case GET_ALL_TPE_SALES:
      return {
        ...state,
        totalPagesTpeSales: action.payload.totalPages,
        tpeSales: action.payload.items
      };
    case GET_ALL_SUBSCRIBERS:
      return {
        ...state,
        listeAbonne: action.payload.items,
        totalPagesAbonne: action.payload.totalPages
      };
    case GET_ALL_SUBSCRIPTION_HISTORY:
      return {
        ...state,
        historiqueAbonnement: action.payload.items,
        totalPagesHistory: action.payload.totalPages
      };
    case GET_ALL_USER_SUBSCRIBERS:
      return {
        ...state,
        totalPagesUserSubscribers: action.payload.totalPages,
        userSubscriber: action.payload.data
      };
    case GET_ALL_USER_SUBSCRIBERS:
      return {
        ...state,
        totalPagesUserSubscribers: action.payload.totalPages,
        userSubscriber: action.payload.data
      };
    case GET_ALL_DUPLICATED_TRABSATIONS:
      return {
        ...state,
        duplicatedTransations: action.payload
      };
    default:
      return state;
  }
};

export default abonnementReducer;
