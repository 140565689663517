import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const ReclamationConfig = [
  {
    auth: authRole.admin,
    routes: [
      {
        exact: true,
        path: '/List-Reclamation',
        component: React.lazy(() => import('./WorkspaceContainer'))
      }
    ]
  }
];
