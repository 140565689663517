import {
  GET_ALL_RECLAMATION,
  GET_ALL_COMMENTS_RECLAMATION,
  ID_RECLAMATION,
  GET_ALL_SERVICE,
  GET_ALL_NewRECLAMATION,
  GET_RECLAMATION_BY_ID,
  GET_RECLAMATION_BY_PDV,
  GET_ALL_TAGS,
  GET_ALL_INTIAL_RECLAMATION,
  GET_ALL_INPROGRESS_RECLAMATION,
  GET_ALL_REFUSED_RECLAMATION,
  GET_ALL_CLOTURED_RECLAMATION,
  GET_ALL_TRAITED_RECLAMATION
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  allreclamations: [],
  allcomments: [],
  allservices: [],
  getNewReclamation: [],
  intialReclamation: [],
  inProgessReclamation: [],
  refusedReclamation: [],
  cloturedReclamation: [],
  traitedReclamation: [],
  pathReclamation: [],
  ReclamationByPdv: [],
  tags: [],
  totalPages: 0,
  idreclamation: null,
  filters: {
    page: 1,
    size: 10,
    first_name: undefined,
    last_name: undefined,
    username: undefined,
    phone: undefined,
    city: undefined,
    pos: undefined,
    order_updatedAt: 'desc',
    typeUser_id: undefined,
    start_date: undefined,
    end_date: undefined,
    end_date_last_comment: undefined,
    start_date_last_comment: undefined,
    team: false,
    code: undefined,
    demandNot: 1
  },
  filtersDeleted: {
    page: 1,
    size: 10,
    first_name: undefined,
    last_name: undefined,
    username: undefined,
    phone: undefined,
    city: undefined,
    pos: undefined,
    order_updatedAt: 'desc',
    typeUser_id: undefined,
    start_date: undefined,
    end_date: undefined,
    team: false,
    code: undefined,
    demandNot: 1,
    deleted: 1,
    withDeleted: true,
    isActive: true
  },
  tpeImages: null,
  docsImg: [],
  profileById: {},
  comments: {},
  contactType: [],
  showModal: false
};

const reclamationReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_RECLAMATION:
      return {
        ...state,
        allreclamations: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_COMMENTS_RECLAMATION:
      return {
        ...state,
        allcomments: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case ID_RECLAMATION:
      return {
        ...state,
        idreclamation: action.payload
      };
    case GET_ALL_SERVICE:
      return {
        ...state,
        allservices: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_NewRECLAMATION:
      return {
        ...state,
        getNewReclamation: action.payload.items,
        isLoading: false
      };
    case GET_ALL_INTIAL_RECLAMATION:
      return {
        ...state,
        intialReclamation: action.payload,
        isLoading: false
      };
    case GET_ALL_INPROGRESS_RECLAMATION:
      return {
        ...state,
        inProgessReclamation: action.payload,
        isLoading: false
      };
    case GET_ALL_REFUSED_RECLAMATION:
      return {
        ...state,
        refusedReclamation: action.payload,
        isLoading: false
      };
    case GET_ALL_CLOTURED_RECLAMATION:
      return {
        ...state,
        cloturedReclamation: action.payload,
        isLoading: false
      };
    case GET_ALL_TRAITED_RECLAMATION:
      return {
        ...state,
        traitedReclamation: action.payload,
        isLoading: false
      };
    case GET_ALL_TAGS:
      return {
        ...state,
        tags: action.payload.data,
        isLoading: false
      };
    case GET_RECLAMATION_BY_ID:
      return {
        ...state,
        pathReclamation: [action.payload]
      };
    case GET_RECLAMATION_BY_PDV:
      return {
        ...state,
        ReclamationByPdv: action.payload.data.items
      };
    default:
      return state;
  }
};

export default reclamationReducer;
