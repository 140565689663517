import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const InvoicePaymentConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Liste-Factures',
        component: React.lazy(() => import('./Pages/InvoicesList'))
      }
    ]
  }
];
