import React, {useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useIntl} from 'react-intl';
import {onGetTotalWalletStock} from 'redux/actions';
import {useDispatch, useSelector} from 'react-redux';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#FBFBFB',
    background: 'linear-gradient(to left, #7EA41A, #3F520D)',
    fontWeight: 'normal'
  }
}))(Button);
const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: '100%'
    }
  },
  text: {
    fontSize: '20px',
    fontWeight: 'bolder'
  }
}));

export default function TotalWallet() {
  const {messages} = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const totale_wallet = useSelector(({company}) => company.totale_wallet);
  // useEffect(() => {
  //   let params = {
  //     start_date: '',
  //     end_date: '',
  //   };
  //   if (!totale_wallet) {
  //     dispatch(onGetTotalWalletStock(params));
  //   }
  // }, [dispatch]);
  return (
    <div>
      <ColorButton variant='contained' color='primary' className={classes.margin}>
        {messages['navbar.total.wallet']}: <p className={classes.text}>{totale_wallet}</p> TND
      </ColorButton>
    </div>
  );
}
