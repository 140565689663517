import {ProductColors} from 'modules/home/Pages/ServicesBoxes/data';
import {
  GET_ADMIN_COMMISSIONS,
  GET_USER_STATS,
  GET_USER_STATS_BYMONTH,
  GET_USER_STATS_BYREG,
  GET_USER_STOCK,
  SET_AUTH_TOKEN,
  SET_RECIEVED_CODE_FROM_EMAIL,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  SET_IS_FIRST_CONNECTION,
  GET_ALL_PDV_CITY,
  GET_ENABLED_SERVICES
} from '../../shared/constants/ActionTypes';
const colors = [
  '#4299e1',
  '#e54046',
  '#4d56bf',
  '#38b2ac',
  '#fa9a4b',
  '#43a047',
  '#7b5d80',
  '#f56565',
  '#f7694c',
  '#a7c830',
  '#42869f',
  '#38b2ac',
  '#4caf50',
  '#8badd7',
  '#607d8b',
  '#9e9e9e',
  '#4caf50'
];
const VouchersColors = [
  '#d93942',
  '#f56565',
  '#f7694c',
  '#fca94b',
  '#42869f',
  '#38b2ac',
  '#4caf50',
  '#8badd7',
  '#607d8b',
  '#9e9e9e',
  '#4caf50'
];
const INIT_STATE = {
  user: null,
  token: null,
  setChangePasswordDialogOpen: false,
  recivedCodeValidationInfo: {},
  listPDVCity: [],
  stock: {},
  totlaTrans: [],
  turnover: [
    {
      code: 'ca',
      color: '#00bcd4',
      name: "Chiffre d'affaire",
      amount: 0
    },
    {
      code: 'feed',
      color: '#3f51b5',
      name: 'Total alimentations',
      amount: 0
    },
    {
      code: 'cash',
      color: '#ffc107',
      name: 'Alimentations espèce',
      amount: 0
    },
    {
      code: 'vir',
      color: '#607d8b',
      name: 'Total virement',
      amount: 0
    }
  ],
  stats: [
    {
      name: 'Paymee',
      amount: 0,
      img: '/assets/images/icons/image1.png',
      color: ProductColors.LIGHT_PINK
    },
    {
      name: 'Voucher',
      amount: 0,
      img: '/assets/images/icons/image3.png',
      color: ProductColors.PARROT
    },
    {
      name: 'Invoices',
      amount: 0,
      img: '/assets/images/icons/image4.png',
      color: ProductColors.BLUE
    },
    {
      name: 'Topnet',
      amount: 0,
      img: '/assets/images/icons/image6.png',
      color: ProductColors.PINK
    },

    {
      name: 'Recharge',
      amount: 0,
      img: '/assets/images/icons/image1.png',
      color: ProductColors.GREY
    }
  ],
  monthStats: [
    {
      month: 'Jan',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Feb',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Mar',

      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Apr',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'May',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Jun',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Jul',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Aug',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Sep',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Oct',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Nov',
      voucher: 0,
      topnet: 0,
      paymee: 0
    },
    {
      month: 'Dec',
      voucher: 0,
      topnet: 0,
      paymee: 0
    }
  ],
  statsTr: [],
  transactions: [
    {
      name: 'Jan',
      value: 0,
      color: 'green',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Jan',
      value: 0,
      color: 'green',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },

    {
      name: 'Feb',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Mar',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Apr',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'May',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Jun',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Jul',
      value: 0,
      color: 'blue',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Aug',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Sep',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Oct',
      value: 0,
      color: 'red',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Nov',
      value: 0,
      color: 'gray',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    },
    {
      name: 'Dec',
      value: 0,
      color: 'orange',
      Topnet: 0,
      Paymee: 0,
      Voucher: 0
    }
  ],
  commissions: []
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload
      };
    }
    case GET_ALL_PDV_CITY: {
      return {
        ...state,
        listPDVCity: action.payload
      };
    }
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload
      };
    }
    case SET_IS_FIRST_CONNECTION: {
      return {
        ...state,
        isUserFirstConnection: action.payload
      };
    }
    case SET_RECIEVED_CODE_FROM_EMAIL: {
      return {
        ...state,
        recivedCodeValidationInfo: action.payload
      };
    }
    case GET_USER_STATS_BYMONTH: {
      return {
        ...state,
        monthStats: [
          {
            month: 'Jan',
            voucher: action.payload.Voucher[0].amount,
            topnet: action.payload.Topnet[0].amount,
            paymee: action.payload.Paymee[0].amount
          },
          {
            month: 'Feb',
            voucher: action.payload.Voucher[1].amount,
            topnet: action.payload.Topnet[1].amount,
            paymee: action.payload.Paymee[1].amount
          },
          {
            month: 'Mar',

            voucher: action.payload.Voucher[2].amount,
            topnet: action.payload.Topnet[2].amount,
            paymee: action.payload.Paymee[2].amount
          },
          {
            month: 'Apr',
            voucher: action.payload.Voucher[3].amount,
            topnet: action.payload.Topnet[3].amount,
            paymee: action.payload.Paymee[3].amount
          },
          {
            month: 'May',
            voucher: action.payload.Voucher[4].amount,
            topnet: action.payload.Topnet[4].amount,
            paymee: action.payload.Paymee[4].amount
          },
          {
            month: 'Jun',
            voucher: action.payload.Voucher[5].amount,
            topnet: action.payload.Topnet[5].amount,
            paymee: action.payload.Paymee[5].amount
          },
          {
            month: 'Jul',
            voucher: action.payload.Voucher[6].amount,
            topnet: action.payload.Topnet[6].amount,
            paymee: action.payload.Paymee[6].amount
          },
          {
            month: 'Aug',
            voucher: action.payload.Voucher[7].amount,
            topnet: action.payload.Topnet[7].amount,
            paymee: action.payload.Paymee[7].amount
          },
          {
            month: 'Sep',
            voucher: action.payload.Voucher[8].amount,
            topnet: action.payload.Topnet[8].amount,
            paymee: action.payload.Paymee[8].amount
          },
          {
            month: 'Oct',
            voucher: action.payload.Voucher[9].amount,
            topnet: action.payload.Topnet[9].amount,
            paymee: action.payload.Paymee[9].amount
          },
          {
            month: 'Nov',
            voucher: action.payload.Voucher[10].amount,
            topnet: action.payload.Topnet[10].amount,
            paymee: action.payload.Paymee[10].amount
          },
          {
            month: 'Dec',
            voucher: action.payload.Voucher[11].amount,
            topnet: action.payload.Topnet[11].amount,
            paymee: action.payload.Paymee[11].amount
          }
        ],
        transactions: [
          {
            name: 'Jan',
            value: action.payload.Topnet[0].transaction,
            color: 'green',
            Topnet: action.payload.Topnet[0].transaction,
            Paymee: action.payload.Paymee[0].transaction,
            Voucher: action.payload.Voucher[0].transaction
          },
          {
            name: 'Jan',
            value: action.payload.Topnet[0].transaction,
            color: 'green',
            Topnet: action.payload.Topnet[0].transaction,
            Paymee: action.payload.Paymee[0].transaction,
            Voucher: action.payload.Voucher[0].transaction
          },

          {
            name: 'Feb',
            value: action.payload.Paymee[1].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[1].transaction,
            Paymee: action.payload.Paymee[1].transaction,
            Voucher: action.payload.Voucher[1].transaction
          },
          {
            name: 'Mar',
            value: action.payload.Paymee[2].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[2].transaction,
            Paymee: action.payload.Paymee[2].transaction,
            Voucher: action.payload.Voucher[2].transaction
          },
          {
            name: 'Apr',
            value: action.payload.Paymee[3].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[3].transaction,
            Paymee: action.payload.Paymee[3].transaction,
            Voucher: action.payload.Voucher[3].transaction
          },
          {
            name: 'May',
            value: action.payload.Paymee[4].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[4].transaction,
            Paymee: action.payload.Paymee[4].transaction,
            Voucher: action.payload.Voucher[4].transaction
          },
          {
            name: 'Jun',
            value: action.payload.Paymee[5].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[5].transaction,
            Paymee: action.payload.Paymee[5].transaction,
            Voucher: action.payload.Voucher[5].transaction
          },
          {
            name: 'Jul',
            value: action.payload.Voucher[6].transaction,
            color: 'blue',
            Topnet: action.payload.Topnet[6].transaction,
            Paymee: action.payload.Paymee[6].transaction,
            Voucher: action.payload.Voucher[6].transaction
          },
          {
            name: 'Aug',
            value: action.payload.Paymee[7].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[7].transaction,
            Paymee: action.payload.Paymee[7].transaction,
            Voucher: action.payload.Voucher[7].transaction
          },
          {
            name: 'Sep',
            value: action.payload.Paymee[8].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[8].transaction,
            Paymee: action.payload.Paymee[8].transaction,
            Voucher: action.payload.Voucher[8].transaction
          },
          {
            name: 'Oct',
            value: action.payload.Paymee[9].transaction,
            color: 'red',
            Topnet: action.payload.Topnet[9].transaction,
            Paymee: action.payload.Paymee[9].transaction,
            Voucher: action.payload.Voucher[9].transaction
          },
          {
            name: 'Nov',
            value: action.payload.Paymee[10].transaction,
            color: 'gray',
            Topnet: action.payload.Topnet[10].transaction,
            Paymee: action.payload.Paymee[10].transaction,
            Voucher: action.payload.Voucher[10].transaction
          },
          {
            name: 'Dec',
            value: action.payload.Topnet[11].transaction,
            color: 'orange',
            Topnet: action.payload.Topnet[11].transaction,
            Paymee: action.payload.Paymee[11].transaction,
            Voucher: action.payload.Voucher[11].transaction
          }
        ]
      };
    }
    case GET_ADMIN_COMMISSIONS: {
      return {
        ...state,
        commissions: action.payload
      };
    }
    case GET_USER_STATS_BYREG: {
      return {
        ...state,
        numberByRegions: action.payload.map((item, index) => ({
          name: item?.name,
          value: item?.count,
          color: colors[index]
        }))
      };
    }
    case GET_USER_STATS: {
      return {
        ...state,
        stats: action.payload
      };
    }
    case GET_USER_STOCK: {
      return {
        ...state,
        stock: action.payload?.stock,
        turnover: [
          {
            code: 'ca',
            color: '#00bcd4',
            name: "Chiffre d'affaire",
            amount: action.payload?.CA
          },
          {
            code: 'feed',
            color: '#3f51b5',
            name: 'Total alimentations',
            amount: action.payload?.feed
          },
          {
            code: 'cash',
            color: '#ffc107',
            name: 'Alimentations espèce',
            amount: action.payload?.feed_with_type.filter(
              (item) => item?.type?.toUpperCase() === 'CASH'
            )[0]?.total[0]?.turnover
          },
          {
            code: 'vir',
            color: '#607d8b',
            name: 'Total virement',
            amount: action.payload?.feed_with_type.filter(
              (item) => item?.type?.toUpperCase() === 'VIREMENT'
            )[0]?.total[0]?.turnover
          },
          {
            code: 'ver',
            color: '#cddc39',
            name: 'Total versement',
            amount: action.payload?.feed_with_type.filter(
              (item) => item?.type?.toUpperCase() === 'VERSEMENT'
            )[0]?.total[0]?.turnover
          },
          {
            code: 'trans',
            color: '#cddc39',
            name: 'Total transactions',
            amount: action.payload?.Nombre_transaction_from_wallet?.reduce(
              (sum, item) => sum + item?.nbre_transaction,
              0
            )
          }
        ],
        totlaTrans: action.payload?.Nombre_transaction_from_wallet.filter(
          (item) =>
            !item.name.toUpperCase().includes('ASEL') && !item.name.toUpperCase().includes('LYCA')
        ).map((item, index) => ({
          ...item,
          name: item.name,

          value: item.nbre_transaction,
          color: colors[index]
          //totalAmountWithPuv: item?.nbre_transaction,
        })),
        sales: [
          ...action.payload?.sales.map((item, index) => ({
            ...item,
            color: colors[index],
            value: Number(item.value)
          })),
          {
            value: action.payload?.sales_voucher_puv?.total_amount,
            color: '#8bc34a',
            code: 1001
          }
        ],
        sales_voucher_puv: action.payload?.sales_voucher_puv?.sales
          .filter(
            (item) =>
              item?.value !== 10 &&
              item?.label.toUpperCase() !== 'LYCA MOBILE' &&
              item?.label.toUpperCase() !== 'ASEL MOBILE'
          )
          .map((item, index) => ({
            ...item,
            value: item.total_amount,
            name: item.label + ' ' + item.value + 'TND',
            color: VouchersColors[index]
          }))
      };
    }
    case GET_ENABLED_SERVICES: {
      //here we are changing the returned object structure to get directely serviceName
      return {
        ...state,
        topupServices: action.payload
          .find((serv) => serv.codeService === 'TU')
          ?.services.reduce((acc, cur) => ({...acc, [cur.code]: cur.status}), {}),
        voucherServices: action.payload
          .find((serv) => serv.codeService === 'Voucher')
          ?.services.reduce((acc, cur) => ({...acc, [cur.code]: cur.status}), {}),
        invoiceServices: action.payload
          .find((serv) => serv.codeService === 'PF')
          ?.services.reduce((acc, cur) => ({...acc, [cur.code]: cur.status}), {}),
        bookingServices: action.payload
          .find((serv) => serv.codeService === 'BOOKING')
          ?.services.reduce((acc, cur) => ({...acc, [cur.code]: cur.status}), {}),
        gamingServices: action.payload
          .find((serv) => serv.codeService === 'GAMING')
          ?.services.reduce((acc, cur) => ({...acc, [cur.code]: cur.status}), {}),
        internetSabbaServices: action.payload
          .find((serv) => serv.codeService === 'INTERNET_SABBA')
          ?.services.reduce((acc, cur) => ({...acc, [cur.code]: cur.status}), {})
      };
    }

    default:
      return state;
  }
};
export default authReducer;
