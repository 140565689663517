import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const NotificationRouting = [
  {
    auth: authRole.admin,
    routes: [
      {
        exact: true,
        path: '/notifiation-khallasli',
        component: React.lazy(() => import('../notification/TopicNotifcation/index'))
      },
      {
        exact: true,
        path: '/notifiation-user',
        component: React.lazy(() => import('../notification/userNotification/index'))
      },
      {
        exact: true,
        path: '/Historique-notifiation',
        component: React.lazy(() => import('../notification/Historique'))
      }
    ]
  }
];
