import {authRole} from 'shared/constants/AppConst';
import React from 'react';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
const routesConfig = [
  {
    role: authRole.visitor,
    id: 'home',
    title: 'Home',
    messageId: 'sidebar.home',
    type: 'item',
    icon: 'home',
    url: '/home'
  },
  {
    role: authRole.daf,
    id: 'pdv',
    title: 'POINT DE VENTE',
    messageId: 'sidebar.pdv.pdv',
    type: 'collapse',
    icon: 'storefront',
    children: [
      {
        role: authRole.daf,
        id: 'list-pdv',
        title: 'POINT DE VENTE',
        messageId: 'sidebar.pdv.list-pdv',
        type: 'item',
        url: '/Liste-PDV'
      },
      /*{
        role: authRole.daf,
        id: 'list-pdv-v2',
        title: 'POINT DE VENTE V2 ',
        messageId: 'sidebar.pdv.list-pdv-v2',
        type: 'item',
        url: '/Liste-PDV-V2',
      },*/
      {
        role: authRole.daf,
        id: 'list-pdv-wallet-negative',
        title: 'POINT DE VENTE WALLET NEGATIVE',
        messageId: 'sidebar.wallet.negative',
        type: 'item',
        url: '/Liste-PDV-wallet-negative'
      },
      {
        role: authRole.daf,
        id: 'list-pdv-wallet-superieur-2000DT',
        title: 'POINT DE VENTE WALLET SUPERIEUR 2000DT',
        messageId: 'sidebar.wallet.superieur.2000DT',
        type: 'item',
        url: '/Liste-PDV-wallet-superieur-2000Dt'
      },
      {
        role: authRole.daf,
        id: 'list-pdv-deleted',
        title: 'POINT DE VENTE ARCHIVE',
        messageId: 'sidebar.pdv.list-pdv-deleted',
        type: 'item',
        url: '/Liste-PDV-Archivé'
      },

      {
        role: authRole.daf,
        id: 'add-PDV',
        title: 'Add company',
        messageId: 'sidebar.pdv.add-pdv',
        type: 'item',
        url: '/Ajout-PDV'
      },
      {
        role: authRole.daf,
        id: 'add-PDV-VIP',
        title: 'Add company vip',
        messageId: 'sidebar.pdv.add-pdv-vip',
        type: 'item',
        url: '/Ajout-PDV-VIP'
      },
      {
        role: authRole.daf,
        id: 'demands',
        title: 'Demands',
        messageId: 'sidebar.pdv.demands',
        type: 'item',
        url: '/Liste-Demandes'
      },
      {
        role: authRole.daf,
        id: 'listWallets',
        title: 'listWallets',
        messageId: 'sidebar.pdv.wallets',
        type: 'item',
        url: '/Liste-Wallets'
      },

      {
        role: authRole.daf,
        id: 'listPdvCity',
        title: 'PDVCity',
        messageId: 'sidebar.pdv.City',
        type: 'item',
        url: '/PDV-Ville'
      },

      {
        role: authRole.daf,
        id: 'statistique-Ville',
        title: 'StatisticCity',
        messageId: 'dashboard.statistics',
        type: 'item',
        url: '/statistique-Ville'
      }
      /* {
        role: authRole.superAdmin,
        id: 'voucherNotIsered',
        title: 'voucherNotInsered',
        messageId: 'vouher Non insérer',
        type: 'item',
        url: '/voucher_notInserted',
      },*/
    ]
  },
  {
    role: authRole.adminAndSupport,
    id: 'rec',
    title: 'Reclamations',
    messageId: 'sidebar.reclamation',
    type: 'collapse',
    icon: 'warning',
    children: [
      {
        role: authRole.adminAndSupport,
        id: 'new-rec',
        title: 'Nouveaux reclamation',
        messageId: 'sidebar.reclamation.nouveaux',
        type: 'item',
        url: '/nouvelle-reclamation'
      },
      {
        role: authRole.adminAndSupport,
        id: 'list-rec',
        title: 'Liste reclamations',
        messageId: 'sidebar.reclamation.list',
        type: 'item',
        url: '/reclamation-liste'
      },
      {
        role: authRole.adminAndSupport,
        id: 'list-rec-Encours',
        title: 'Liste reclamations en cours',
        messageId: 'sidebar.reclamation.list.encour',
        type: 'item',
        url: '/reclamation-en-cours'
      },

      {
        role: authRole.adminAndSupport,
        id: 'list-rec-Traiter',
        title: 'Liste reclamations Traiter',
        messageId: 'sidebar.reclamation.list.Traiter',
        type: 'item',
        url: '/reclamation-traiter'
      },
      {
        role: authRole.adminAndSupport,
        id: 'list-rec-cloturer',
        title: 'Liste reclamations Traiter',
        messageId: 'sidebar.reclamation.list.cloturer',
        type: 'item',
        url: '/reclamation-cloturer'
      },
      {
        role: authRole.adminAndSupport,
        id: 'list-rec-Encours',
        title: 'Liste reclamations en cours',
        messageId: 'sidebar.reclamation.list.Annuler',
        type: 'item',
        url: '/reclamation-Annuler'
      }
    ]
  },
  {
    role: authRole.admin,
    id: 'notif',
    title: 'Notification',
    messageId: 'Notification',
    type: 'collapse',
    icon: <CampaignOutlinedIcon />,
    children: [
      {
        role: authRole.admin,
        id: 'new-notif',
        title: 'notifier khallasli',
        messageId: 'sidebar.notification.khallasli',
        type: 'item',
        url: '/notifiation-khallasli'
      },
      {
        role: authRole.admin,
        id: 'list-notif',
        title: 'notifier user ',
        messageId: 'sidebar.notification.user',
        type: 'item',
        url: '/notifiation-user'
      },
      {
        role: authRole.admin,
        id: 'Historique-notif',
        title: 'Historique Notification ',
        messageId: 'sidebar.notification.Historique',
        type: 'item',
        url: '/Historique-notifiation'
      }
    ]
  },
  {
    role: authRole.dafAndSupport,
    id: 'rec',
    title: 'statistique',
    messageId: 'Suivie Statistique',
    type: 'collapse',
    icon: <BarChartOutlinedIcon />,
    children: [
      {
        role: authRole.dafAndSupport,
        id: 'new-transaction',
        title: 'Trnasaction',
        messageId: 'sidebar.suivis',
        type: 'item',
        url: '/duplicated-transaction'
      },
      {
        role: authRole.dafAndSupport,
        id: 'voucherNotIsered',
        title: 'voucherNotInsered',
        messageId: 'Voucher non insérés',
        type: 'item',
        url: '/voucher_notInserted'
      },
      {
        role: authRole.dafAndSupport,
        id: 'voucherReserved',
        title: 'voucherReserved',
        messageId: 'Voucher Rerservée ',
        type: 'item',
        url: '/voucher_Reserved'
      },
      {
        role: authRole.dafAndSupport,
        id: 'currptedWallet',
        title: 'currptedWallet',
        messageId: 'Currpted Wallet',
        type: 'item',
        url: '/corrupted_wallet'
      }
    ]
  },
  {
    role: authRole.dafAndSupport,
    id: 'payouts',
    title: 'Rapprochement Fparcel',
    messageId: 'Rapprochement Fparcel',
    type: 'collapse',
    icon: <ContentPasteSearchOutlinedIcon />,
    children: [
      {
        role: authRole.dafAndSupport,
        id: 'payouts',
        title: 'Liste des payouts attaché',
        messageId: 'Liste des payouts attaché',
        type: 'item',
        url: '/payouts'
      },
      {
        role: authRole.dafAndSupport,
        id: 'payoutsImporter',
        title: 'Importer',
        messageId: 'Importer',
        type: 'item',
        url: '/payouts-importer'
      },
      {
        role: authRole.dafAndSupport,
        id: 'nonRapprochement',
        title: 'Liste payée et non rapprochée',
        messageId: 'Liste payée et non rapprochée',
        type: 'item',
        url: '/payouts-non-rapprochement'
      }
    ]
  },

  {
    role: authRole.onlYComptable,
    id: 'pdv',
    title: 'POINT DE VENTE',
    messageId: 'sidebar.pdv.pdv',
    type: 'collapse',
    icon: 'storefront',
    children: [
      {
        role: authRole.onlYComptable,
        id: 'list-pdv',
        title: 'POINT DE VENTE',
        messageId: 'sidebar.pdv.list-pdv',
        type: 'item',
        url: '/Liste-PDV'
      },
      {
        role: authRole.onlYComptable,
        id: 'list-pdv-deleted',
        title: 'POINT DE VENTE ARCHIVE',
        messageId: 'sidebar.pdv.list-pdv-deleted',
        type: 'item',
        url: '/Liste-PDV-Archivé'
      },
      {
        role: authRole.onlYComptable,
        id: 'listWallets',
        title: 'listWallets',
        messageId: 'sidebar.pdv.wallets',
        type: 'item',
        url: '/Liste-Wallets'
      }
    ]
  },
  {
    role: authRole.onlYSupport,
    id: 'pdv',
    title: 'POINT DE VENTE',
    messageId: 'sidebar.pdv.pdv',
    type: 'collapse',
    icon: 'storefront',
    children: [
      {
        role: authRole.onlYSupport,
        id: 'list-pdv',
        title: 'POINT DE VENTE',
        messageId: 'sidebar.pdv.list-pdv',
        type: 'item',
        url: '/Liste-PDV'
      },
      {
        role: authRole.onlYSupport,
        id: 'list-pdv-deleted',
        title: 'POINT DE VENTE ARCHIVE',
        messageId: 'sidebar.pdv.list-pdv-deleted',
        type: 'item',
        url: '/Liste-PDV-Archivé'
      },

      {
        role: authRole.onlYSupport,
        id: 'add-PDV',
        title: 'Add company',
        messageId: 'sidebar.pdv.add-pdv',
        type: 'item',
        url: '/Ajout-PDV'
      },
      {
        role: authRole.onlYSupport,
        id: 'demands',
        title: 'Demands',
        messageId: 'sidebar.pdv.demands',
        type: 'item',
        url: '/Liste-Demandes'
      }
    ]
  },
  {
    role: authRole.onlYComptable,
    id: 'abonnement',
    title: 'ABONNEMENT',
    messageId: 'sidebar.abonnement',
    type: 'collapse',
    icon: 'storage',
    children: [
      {
        role: authRole.onlYComptable,
        id: 'liste_abonne',
        title: 'liste_abonne',
        messageId: 'sidebar.liste_abonne',
        type: 'item',
        url: '/Liste-Abonne'
      },
      {
        role: authRole.onlYComptable,
        id: 'historique_abonnement',
        title: 'historique_abonnement',
        messageId: 'sidebar.historique_abonnement',
        type: 'item',
        url: '/Historique-Abonnement'
      },
      {
        role: authRole.onlYComptable,
        id: 'tpe-gestion',
        title: 'Gestion-TPE',
        messageId: 'sidebar.type_TPE',
        type: 'item',
        url: '/TPE-Gestion'
      }
    ]
  },
  {
    role: authRole.onlYSupport,
    id: 'abonnement',
    title: 'ABONNEMENT',
    messageId: 'sidebar.abonnement',
    type: 'collapse',
    icon: 'storage',
    children: [
      {
        role: authRole.onlYSupport,
        id: 'liste_abonne',
        title: 'liste_abonne',
        messageId: 'sidebar.liste_abonne',
        type: 'item',
        url: '/Liste-Abonne'
      },
      {
        role: authRole.onlYSupport,
        id: 'liste_maintenance',
        title: 'liste_maintenance',
        messageId: 'sidebar.liste_maintenance',
        type: 'item',
        url: '/TPE-Maintenance'
      },
      {
        role: authRole.daf,
        id: 'tpe-vendu',
        title: 'Vente-TPE',
        messageId: 'sidebar.vente_TPE',
        type: 'item',
        url: '/Vente-TPE'
      },
      {
        role: authRole.daf,
        id: 'tpe-Affecter',
        title: 'Affecter-Tpe',
        messageId: 'sidebar.demande-tpe',
        type: 'item',
        url: '/demande-Tpe'
      },
      {
        role: authRole.onlYSupport,
        id: 'tpe-gestion',
        title: 'Gestion-TPE',
        messageId: 'sidebar.type_TPE',
        type: 'item',
        url: '/TPE-Gestion'
      }
    ]
  },
  {
    role: authRole.daf,
    id: 'abonnement',
    title: 'ABONNEMENT',
    messageId: 'sidebar.abonnement',
    type: 'collapse',
    icon: 'storage',
    children: [
      {
        role: authRole.daf,
        id: 'liste_abonnement',
        title: 'liste_abonnement',
        messageId: 'sidebar.liste_abonnement',
        type: 'item',
        url: '/Liste-Abonnement'
      },
      {
        role: authRole.daf,
        id: 'liste_abonne',
        title: 'liste_abonne',
        messageId: 'sidebar.liste_abonne',
        type: 'item',
        url: '/Liste-Abonne'
      },
      {
        role: authRole.daf,
        id: 'liste_maintenance',
        title: 'liste_maintenance',
        messageId: 'sidebar.liste_maintenance',
        type: 'item',
        url: '/TPE-Maintenance'
      },
      {
        role: authRole.daf,
        id: 'historique_abonnement',
        title: 'historique_abonnement',
        messageId: 'sidebar.historique_abonnement',
        type: 'item',
        url: '/Historique-Abonnement'
      },
      {
        role: authRole.daf,
        id: 'tpe-vendu',
        title: 'Vente-TPE',
        messageId: 'sidebar.vente_TPE',
        type: 'item',
        url: '/Vente-TPE'
      },
      {
        role: authRole.daf,
        id: 'tpe-Affecter',
        title: 'Affecter-Tpe',
        messageId: 'sidebar.demande-tpe',
        type: 'item',
        url: '/demande-Tpe'
      },
      {
        role: authRole.daf,
        id: 'type_abonnement',
        title: 'type_abonnement',
        messageId: 'sidebar.type_abonnement',
        type: 'item',
        url: '/Type-Abonnement'
      },
      {
        role: authRole.daf,
        id: 'tpe-gestion',
        title: 'Gestion-TPE',
        messageId: 'sidebar.type_TPE',
        type: 'item',
        url: '/TPE-Gestion'
      },
      {
        role: authRole.daf,
        id: 'tpe-gestion',
        title: 'Historique-TPE',
        messageId: 'sidebar.history_TPE',
        type: 'item',
        url: '/Historique-TPE'
      }
    ]
  },

  // {
  //   role: authRole.admin,
  //   id: 'reclamation',
  //   title: 'RECLAMATION',
  //   messageId: 'sidebar.reclamation',
  //   type: 'collapse',
  //   icon: 'history',
  //   children: [
  //     {
  //       role: authRole.admin,
  //       id: 'liste_reclamation',
  //       title: 'liste_reclamation',
  //       messageId: 'sidebar.historique_reclamation',
  //       type: 'item',
  //       icon: 'list',
  //       url: '/List-Reclamation',
  //     },
  //     {
  //       role: authRole.admin,
  //       id: 'add_reclamation',
  //       title: 'add_reclamation',
  //       messageId: 'sidebar.add_reclamation',
  //       type: 'item',
  //       icon: 'add',
  //       url: '/Add-Reclamation',
  //     },
  //   ],
  // },

  {
    role: authRole.dafAndSupport,
    id: 'contactList',
    title: 'Historique Contact',
    messageId: 'sidebar.historique.contact',
    type: 'collapse',
    icon: 'feed',
    children: [
      {
        role: authRole.dafAndSupport,
        id: 'Liste contact',
        title: 'Liste des contact',
        messageId: 'List.ofContact',
        type: 'item',
        url: '/ListContact'
      },
      {
        role: authRole.dafAndSupport,
        id: 'reclamations',
        title: 'reclamations',
        messageId: 'sidebar.comm.reclamations',
        type: 'item',
        url: '/Liste-reclamations'
      }
    ]
  },
  {
    role: authRole.admin,
    id: 'commercial',
    title: 'Commercial',
    messageId: 'sidebar.commercial.activities',
    type: 'collapse',
    icon: 'supportAgent',
    children: [
      {
        role: authRole.admin || authRole.onlYSupport,
        id: 'rapports',
        title: 'rapports',
        messageId: 'sidebar.comm.reports',
        type: 'item',
        url: '/Liste-prospections'
      },
      {
        role: authRole.admin,
        id: 'appointements',
        title: 'appointements',
        messageId: 'sidebar.comm.appointement',
        type: 'item',
        url: '/Liste-appointements'
      },
      {
        role: authRole.admin,
        id: 'comments',
        title: 'comments',
        messageId: 'sidebar.comm.comments',
        type: 'item',
        url: '/Liste-comments'
      },
      // {
      //   role: authRole.admin,
      //   id: 'rapports',
      //   title: 'rapports',
      //   messageId: 'sidebar.comm.Addreports',
      //   type: 'item',
      //   url: `/Ajouter-reports`,
      // },
      {
        role: authRole.admin,
        id: 'rapports',
        title: 'rapports',
        messageId: 'sidebar.comm.feed',
        type: 'item',
        url: '/Alimentation-Commercial'
      },

      {
        role: authRole.admin,
        id: 'historyCommercial',
        title: 'history',
        messageId: 'sidebar.commercial.history.wallet',
        type: 'item',
        url: '/Historique-Alimentaion'
      },
      {
        role: authRole.admin,
        id: 'historyCommercialWallet',
        title: 'history',
        messageId: 'sidebar.commercial.wallet',
        type: 'item',
        url: '/Historique-Commercial'
      },
      {
        role: authRole.admin,
        id: 'Liste Visite',
        title: 'Liste Visite',
        messageId: 'sidebar.visit',
        type: 'item',
        url: '/List-Visite-Commercial'
      },
      {
        role: authRole.admin,
        id: 'Importer prospection',
        title: 'Importer prospection',
        messageId: 'sidebar.importer.prospection',
        type: 'item',
        url: '/Importer_prospection'
      },
      {
        role: authRole.admin,
        id: 'verfierVoucher',
        title: 'verfierVoucher',
        messageId: 'sidebar.config.voucher.verifier',
        type: 'item',
        url: '/VerifierVoucher'
      }
    ]
  },
  {
    role: authRole.onlYDaf,
    id: 'commercial',
    title: 'Commercial',
    messageId: 'sidebar.commercial.activities',
    type: 'collapse',
    icon: 'supportAgent',
    children: [
      {
        role: authRole.onlYDaf,
        id: 'rapports',
        title: 'rapports',
        messageId: 'sidebar.comm.feed',
        type: 'item',
        url: '/Alimentation-Commercial'
      },

      {
        role: authRole.onlYDaf,
        id: 'historyCommercial',
        title: 'history',
        messageId: 'sidebar.commercial.history.wallet',
        type: 'item',
        url: '/Historique-Alimentaion'
      },
      {
        role: authRole.onlYDaf,
        id: 'historyCommercialWallet',
        title: 'history',
        messageId: 'sidebar.commercial.wallet',
        type: 'item',
        url: '/Historique-Commercial'
      }
    ]
  },
  {
    role: authRole.onlYDaf,
    id: 'reclamation-For-Daf',
    title: 'Daf-Reclamations',
    messageId: 'sidebar.reclamation',
    type: 'collapse',
    icon: 'warning',
    children: [
      {
        role: authRole.onlYDaf,
        id: 'list-rec-Encours',
        title: 'Reclamations à Traiter',
        messageId: 'Reclamations à faire',
        type: 'item',
        url: '/reclamation-affecter'
      },
      {
        role: authRole.onlYDaf,
        id: 'list-rec-Encours',
        title: 'Reclamations Traitée',
        messageId: 'Reclamations Traitée',
        type: 'item',
        url: '/reclamation-affecter-traiter'
      },
      {
        role: authRole.onlYDaf,
        id: 'list-reclamationColturer',
        title: 'Reclamations Traitée',
        messageId: 'Reclamations Cloturée',
        type: 'item',
        url: '/reclamation-affecter-cloturer'
      },
      {
        role: authRole.onlYDaf,
        id: 'list-rec-Encours',
        title: 'Reclamations anullée',
        messageId: 'Reclamations anullée',
        type: 'item',
        url: '/reclamation-affecter-anuller'
      }
    ]
  },

  {
    role: authRole.onlYComptable,
    id: 'commercial',
    title: 'Commercial',
    messageId: 'sidebar.commercial.activities',
    type: 'collapse',
    icon: 'supportAgent',
    children: [
      {
        role: authRole.onlYComptable,
        id: 'rapports',
        title: 'rapports',
        messageId: 'sidebar.comm.feed',
        type: 'item',
        url: '/Alimentation-Commercial'
      },

      {
        role: authRole.onlYComptable,
        id: 'historyCommercial',
        title: 'history',
        messageId: 'sidebar.commercial.history.wallet',
        type: 'item',
        url: '/Historique-Alimentaion'
      },
      {
        role: authRole.onlYComptable,
        id: 'historyCommercialWallet',
        title: 'history',
        messageId: 'sidebar.commercial.wallet',
        type: 'item',
        url: '/Historique-Commercial'
      },
      {
        role: authRole.onlYComptable,
        id: 'verfierVoucher',
        title: 'verfierVoucher',
        messageId: 'sidebar.config.voucher.verifier',
        type: 'item',
        url: '/VerifierVoucher'
      }
    ]
  },
  {
    role: authRole.onlYSupport,
    id: 'commercial',
    title: 'Commercial',
    messageId: 'sidebar.commercial.activities',
    type: 'collapse',
    icon: 'supportAgent',
    children: [
      {
        role: authRole.onlYSupport,
        id: 'rapports',
        title: 'rapports',
        messageId: 'sidebar.comm.reports',
        type: 'item',
        url: '/Liste-prospections'
      },
      {
        role: authRole.onlYSupport,
        id: 'appointements',
        title: 'appointements',
        messageId: 'sidebar.comm.appointement',
        type: 'item',
        url: '/Liste-appointements'
      },
      {
        role: authRole.onlYSupport,
        id: 'comments',
        title: 'comments',
        messageId: 'sidebar.comm.comments',
        type: 'item',
        url: '/Liste-comments'
      },
      {
        role: authRole.onlYSupport,
        id: 'rapports',
        title: 'rapports',
        messageId: 'sidebar.comm.feed',
        type: 'item',
        url: '/Alimentation-Commercial'
      },
      {
        role: authRole.onlYSupport,
        id: 'verfierVoucher',
        title: 'verfierVoucher',
        messageId: 'sidebar.config.voucher.verifier',
        type: 'item',
        url: '/VerifierVoucher'
      }
    ]
  },
  {
    role: authRole.adminAndSupport,
    id: 'crm',
    title: 'CRM',
    messageId: 'sidebar.CRM',
    type: 'collapse',
    icon: 'storage',
    children: [
      {
        role: authRole.adminAndSupport,
        id: '/Liste-leads',
        title: '/Liste-leads',
        messageId: 'sidebar.comm.leads',
        type: 'item',
        url: '/Liste-leads'
      },
      {
        role: authRole.adminAndSupport,
        id: '/Liste-leads-contacted',
        title: '/Liste-leads-contacted',
        messageId: 'sidebar.comm.contactedleads',
        type: 'item',
        url: '/Liste-leads-contacted'
      },
      {
        role: authRole.adminAndSupport,
        id: '/Liste-leads-recontacter',
        title: '/Liste-leads-recontacter',
        messageId: 'leads.recontacted',
        type: 'item',
        url: '/Liste-leads-recontacter'
      },
      {
        role: authRole.adminAndSupport,
        id: '/Liste-des-prospects-chauds',
        title: '/Liste-des-prospects-chauds',
        messageId: 'leads.hot',
        type: 'item',
        url: '/Liste-des-prospects-chauds'
      },
      {
        role: authRole.adminAndSupport,
        id: '/Statistique-leads',
        title: '/Statistique-leads',
        messageId: 'leads.statistic',
        type: 'item',
        url: '/Statistique-leads'
      }
    ]
  },
  // {
  //   role: authRole.support,
  //   id: 'invoicePayment',
  //   title: 'invoicePayment',
  //   messageId: 'sidebar.invoice.payment',
  //   type: 'collapse',
  //   icon: 'Payment',
  //   children: [
  //     {
  //       role: authRole.support,
  //       id: 'invoices',
  //       title: 'invoices',
  //       messageId: 'sidebar.invoices.list',
  //       type: 'item',
  //       url: '/Liste-Factures',
  //     },
  //   ],
  // },
  {
    role: authRole.comptable,
    id: 'AlimenterUser',
    title: 'AlimenterUser',
    messageId: 'sidebar.AlimenterUser',
    type: 'collapse',
    icon: 'feed',
    children: [
      {
        role: authRole.comptable,
        id: 'feedUser',
        title: 'feedUser',
        messageId: 'sidebar.feeding.user',
        type: 'item',
        url: '/Alimentation-PDV'
      },
      {
        role: authRole.comptable,
        id: 'feedUserCash',
        title: 'feedUser',
        messageId: 'Alimentation-espèce',
        type: 'item',
        url: '/Alimentation-espèce'
      },
      {
        role: authRole.comptable,
        id: 'feedUser',
        title: 'feedUser',
        messageId: 'Alimentations espèce Commercial',
        type: 'item',
        url: '/Alimanation-Commercial'
      },
      {
        role: authRole.comptable,
        id: 'feedUser',
        title: 'feedUser',
        messageId: 'Alimentations espèce Fparcel',
        type: 'item',
        url: '/Alimanation-CashCollector'
      },
      /*{
        role: authRole.comptable,
        id: 'AlimenterUserEspèce',
        title: 'AlimenterUser',
        messageId: 'common.feed.livreur',
        type: 'collapse',
        icon: '',
        children: [
          {
            role: authRole.comptable,
            id: 'ListeAffectee',
            title: 'Liste Collecte Affectée',
            messageId: 'List Affectée',
            type: 'item',
            url: '/collect-Affecter',
          },
          {
            role: authRole.comptable,
            id: 'ListeRetard',
            title: 'Liste en retard',
            messageId: 'List en retard',
            type: 'item',
            url: '/collect-retard',
          },
          {
            role: authRole.comptable,
            id: 'ListeRefusee',
            title: 'Liste Collecte Refusée',
            messageId: 'List Refusée',
            type: 'item',
            url: '/collect-Refuser',
          },

          {
            role: authRole.comptable,
            id: 'ListeCollectee',
            title: 'Liste Collecte Collectée',
            messageId: 'List Collectée',
            type: 'item',
            url: '/list-collecter',
          },
        ],
      },*/
      {
        role: authRole.comptable,
        id: 'feedUser',
        title: 'feedUser',
        messageId: 'sidebar.feeding.user.remboursement',
        type: 'item',
        url: '/DemandeRemboursement'
      }
    ]
  },
  {
    role: authRole.onlYSupport,
    id: 'AlimenterUser',
    title: 'AlimenterUser',
    messageId: 'sidebar.AlimenterUser',
    type: 'collapse',
    icon: 'feed',
    children: [
      {
        role: authRole.onlYSupport,
        id: 'feedUser',
        title: 'feedUser',
        messageId: 'sidebar.feeding.user',
        type: 'item',
        url: '/Alimentation-PDV'
      },
      {
        role: authRole.onlYSupport,
        id: 'feedUserCash',
        title: 'feedUser',
        messageId: 'Alimentation-espèce',
        type: 'item',
        url: '/Alimentation-espèce'
      },
      {
        role: authRole.onlYSupport,
        id: 'feedUser',
        title: 'feedUser',
        messageId: 'Alimentations espèce Commercial',
        type: 'item',
        url: '/Alimanation-Commercial'
      },
      {
        role: authRole.onlYSupport,
        id: 'feedUser',
        title: 'feedUser',
        messageId: 'Alimentations espèce Fparcel',
        type: 'item',
        url: '/Alimanation-CashCollector'
      },

      /* {
        role: authRole.comptable,
        id: 'AlimenterUserEspèce',
        title: 'AlimenterUser',
        messageId: 'sidebar.AlimenterUser.espéce',
        type: 'collapse',
        icon: '',
        children: [
          {
            role: authRole.comptable,
            id: 'feedUserCash',
            title: 'feedUser',
            messageId: 'List',
            type: 'item',
            url: '/Alimentation-espèce',
          },
          {
            role: authRole.comptable,
            id: 'ListeAffectee',
            title: 'Liste Collecte Affectée',
            messageId: 'List Affectée',
            type: 'item',
            url: '/collect-Affecter',
          },

          {
            role: authRole.comptable,
            id: 'ListeRetard',
            title: 'Liste en retard',
            messageId: 'List en retard',
            type: 'item',
            url: '/collect-retard',
          },

          {
            role: authRole.comptable,
            id: 'ListeRefusee',
            title: 'Liste Collecte Refusée',
            messageId: 'List Refusée',
            type: 'item',
            url: '/collect-Refuser',
          },
          {
            role: authRole.comptable,
            id: 'ListeCollectee',
            title: 'Liste Collecte Collectée',
            messageId: 'List Collectée',
            type: 'item',
            url: '/list-collecter',
          },
        ],
      },*/

      {
        role: authRole.onlYSupport,
        id: 'feedUser',
        title: 'feedUser',
        messageId: 'sidebar.feeding.user.remboursement',
        type: 'item',
        url: '/DemandeRemboursement'
      }
    ]
  },
  {
    role: authRole.daf,
    id: 'history',
    title: 'History',
    messageId: 'sidebar.History',
    type: 'collapse',
    icon: 'history',
    children: [
      {
        role: authRole.daf,
        id: 'Topup',
        title: 'Topup',
        messageId: 'sidebar.history.topup',
        type: 'item',
        url: '/Historique-TOPUP'
      },
      {
        role: authRole.daf,
        id: 'TopupPoste',
        title: 'TopupPoste',
        messageId: 'sidebar.history.topup.poste',
        type: 'item',
        url: '/Historique-TOPUP-POSTE'
      },
      {
        role: authRole.daf,
        id: 'voucher',
        title: 'Voucher',
        messageId: 'sidebar.history.voucher',
        type: 'item',
        url: '/Historique-Voucher'
      },
      {
        role: authRole.daf,
        id: 'Topup',
        title: 'Topup',
        messageId: 'sidebar.history.topnet',
        type: 'item',
        url: '/Historique-TOPNET'
      },
      {
        role: authRole.daf,
        id: 'Topup',
        title: 'Topup',
        messageId: 'sidebar.history.bill',
        type: 'item',
        url: '/Historique-BILL'
      },
      {
        role: authRole.daf,
        id: 'recharge',
        title: 'Recharge',
        messageId: 'sidebar.history.Recharge',
        type: 'item',
        url: '/Historique-Recharge'
      },
      {
        role: authRole.daf,
        id: 'factures',
        title: 'Factures',
        messageId: 'sidebar.history.factures',
        type: 'item',
        url: '/Liste-Factures'
      },
      {
        role: authRole.daf,
        id: 'Command',
        title: 'Commade',
        messageId: 'sidebar.history.command',
        type: 'item',
        url: '/Historique-Command'
      }
    ]
  },
  {
    role: authRole.comptableAndSupport,
    id: 'history',
    title: 'History',
    messageId: 'sidebar.History',
    type: 'collapse',
    icon: 'history',
    children: [
      {
        role: authRole.comptableAndSupport,
        id: 'Topup',
        title: 'Topup',
        messageId: 'sidebar.history.topup',
        type: 'item',
        url: '/Historique-TOPUP'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'TopupPoste',
        title: 'TopupPoste',
        messageId: 'sidebar.history.topup.poste',
        type: 'item',
        url: '/Historique-TOPUP-POSTE'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'voucher',
        title: 'Voucher',
        messageId: 'sidebar.history.voucher',
        type: 'item',
        url: '/Historique-Voucher'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'Topup',
        title: 'Topup',
        messageId: 'sidebar.history.topnet',
        type: 'item',
        url: '/Historique-TOPNET'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'recharge',
        title: 'Recharge',
        messageId: 'sidebar.history.Recharge',
        type: 'item',
        url: '/Historique-Recharge'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'factures',
        title: 'Factures',
        messageId: 'sidebar.history.factures',
        type: 'item',
        url: '/Liste-Factures'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'Command',
        title: 'Commade',
        messageId: 'sidebar.history.command',
        type: 'item',
        url: '/Historique-Command'
      }
    ]
  },
  {
    role: authRole.daf,
    id: 'digidis',
    title: 'digidis',
    messageId: 'sidebar.digidis',
    type: 'collapse',
    icon: <StorefrontOutlinedIcon />,
    children: [
      // {
      //   role: authRole.daf,
      //   id: 'stockTopup',
      //   title: 'StockTopup',
      //   messageId: 'sidebar.Reservation',
      //   type: 'item',
      //   url: '/Digidis/Reservation',
      // },
      {
        role: authRole.daf,
        id: 'stockVoucher',
        title: 'StockVoucher',
        messageId: 'sidebar.History.Digidis',
        type: 'item',
        url: '/Digidis/Historique'
      }
    ]
  },
  {
    role: authRole.daf,
    id: 'stock',
    title: 'Stock',
    messageId: 'sidebar.Stock',
    type: 'collapse',
    icon: 'storage',
    children: [
      {
        role: authRole.daf,
        id: 'stockTopup',
        title: 'StockTopup',
        messageId: 'sidebar.stock.topup',
        type: 'item',
        url: '/Stock-TOPUP'
      },
      {
        role: authRole.daf,
        id: 'stockVoucher',
        title: 'StockVoucher',
        messageId: 'sidebar.stock.voucher',
        type: 'item',
        url: '/Stock-Voucher'
      },
      {
        role: authRole.daf,
        id: 'stockEdinar',
        title: 'stockEdinar',
        messageId: 'sidebar.stock.edinar',
        type: 'item',
        url: '/Stock-Recharge'
      },
      {
        role: authRole.daf,
        id: 'stockLimitVoucher',
        title: 'stockLimitVoucher',
        messageId: 'sidebar.stock.limit',
        type: 'item',
        url: '/Stock-Limit-Voucher'
      },
      {
        role: authRole.daf,
        id: 'Mintroute',
        title: 'Mintroute',
        messageId: 'sidebar.mintroute',
        type: 'item',
        url: '/Mintroute'
      },
      {
        role: authRole.daf,
        id: 'stockHotel',
        title: 'StockHotel',
        messageId: 'sidebar.stock.hotel',
        type: 'item',
        url: '/Stock-Hotel'
      }
    ]
  },
  {
    role: authRole.comptableAndSupport,
    id: 'stock',
    title: 'Stock',
    messageId: 'sidebar.Stock',
    type: 'collapse',
    icon: 'storage',
    children: [
      {
        role: authRole.comptableAndSupport,
        id: 'stockTopup',
        title: 'StockTopup',
        messageId: 'sidebar.stock.topup',
        type: 'item',
        url: '/Stock-TOPUP'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'stockVoucher',
        title: 'StockVoucher',
        messageId: 'sidebar.stock.voucher',
        type: 'item',
        url: '/Stock-Voucher'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'stockEdinar',
        title: 'stockEdinar',
        messageId: 'sidebar.stock.edinar',
        type: 'item',
        url: '/Stock-Recharge'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'Mintroute',
        title: 'Mintroute',
        messageId: 'sidebar.mintroute',
        type: 'item',
        url: '/Mintroute'
      },
      {
        role: authRole.comptableAndSupport,
        id: 'stockHotel',
        title: 'StockHotel',
        messageId: 'sidebar.stock.hotel',
        type: 'item',
        url: '/Stock-Hotel'
      }
    ]
  },
  {
    role: authRole.admin,
    id: 'mintroute',
    title: 'mintroute',
    messageId: 'sidebar.mintroute',
    type: 'collapse',
    icon: 'games',
    children: [
      {
        role: authRole.admin,
        id: 'addMintroute',
        title: 'addMintroute',
        messageId: 'sidebar.mintroute.list.marque',
        type: 'item',
        url: '/Liste-Marque'
      },
      {
        role: authRole.admin,
        id: 'add',
        title: 'addMintroute',
        messageId: 'sidebar.mintroute.transactions',
        type: 'item',
        url: '/Historique-Coupons'
      }
    ]
  },

  {
    role: authRole.admin,
    id: 'fournisseur',
    title: 'fournisseur',
    messageId: 'sidebar.fournisseur',
    type: 'item',
    icon: 'business',
    url: '/Fournisseurs'
  },
  {
    role: authRole.dafComptableAndSupport,
    id: 'mintroute',
    title: 'mintroute',
    messageId: 'sidebar.mintroute',
    type: 'collapse',
    icon: 'games',
    children: [
      {
        role: authRole.dafComptableAndSupport,
        id: 'add',
        title: 'addMintroute',
        messageId: 'sidebar.mintroute.transactions',
        type: 'item',
        url: '/Historique-Coupons'
      }
    ]
  },

  {
    role: authRole.admin,
    id: 'hotel',
    title: 'hotel',
    messageId: 'sidebar.hotel',
    type: 'collapse',
    icon: 'hotel',
    children: [
      {
        role: authRole.admin,
        id: 'importDataHotel',
        title: 'importDataHotel',
        messageId: 'sidebar.hotel.import',
        type: 'item',
        url: '/import/statics-data'
      },
      {
        role: authRole.admin,
        id: 'reservationTransaction',
        title: 'reservationTransaction',
        messageId: 'sidebar.hotel.transactions',
        type: 'item',
        url: '/Historique-reservation'
      },
      {
        role: authRole.admin,
        id: 'reservationAnnulation',
        title: 'reservationAnnulation',
        messageId: 'sidebar.hotel.annulation',
        type: 'item',
        url: '/Annulation-reservation'
      }
    ]
  },
  {
    role: authRole.dafComptableAndSupport,
    id: 'hotel',
    title: 'hotel',
    messageId: 'sidebar.hotel',
    type: 'collapse',
    icon: 'hotel',
    children: [
      {
        role: authRole.dafComptableAndSupport,
        id: 'reservationTransaction',
        title: 'reservationTransaction',
        messageId: 'sidebar.hotel.transactions',
        type: 'item',
        url: '/Historique-reservation'
      }
    ]
  },
  {
    role: authRole.dafAndAdmin,
    id: 'feeding',
    title: 'Feeding',
    messageId: 'sidebar.feeding',
    type: 'collapse',
    icon: 'money',
    children: [
      // {
      //   id: 'Topup',
      //   title: 'Topup',
      //   messageId: 'sidebar.feeding.topup',
      //   type: 'item',
      //   url: '/Feed/Topup',
      // },
      {
        role: authRole.daf,
        id: 'alimentationStock',
        title: 'alimentationStock',
        messageId: 'sidebar.feeding',
        type: 'item',
        url: '/Alimentations'
      }
    ]
  },
  {
    role: authRole.onlYSupperAdmin,
    id: 'feeding',
    title: 'Feeding',
    messageId: 'sidebar.feeding',
    type: 'collapse',
    icon: 'money',
    children: [
      // {
      //   id: 'Topup',
      //   title: 'Topup',
      //   messageId: 'sidebar.feeding.topup',
      //   type: 'item',
      //   url: '/Feed/Topup',
      // },
      {
        role: authRole.onlYSupperAdmin,
        id: 'alimentationStock',
        title: 'alimentationStock',
        messageId: 'sidebar.feeding',
        type: 'item',
        url: '/Alimentations'
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'configuration',
        title: 'configuration',
        messageId: 'sidebar.config',
        type: 'collapse',
        url: '/configuration/',
        children: [
          {
            role: authRole.onlYSupperAdmin,
            id: 'service',
            title: 'service',
            messageId: 'common.service',
            type: 'item',
            url: '/Alimentation/Config-service'
          },
          {
            role: authRole.onlYSupperAdmin,
            id: 'provider',
            title: 'provider',
            messageId: 'common.fournisseur',
            type: 'item',
            url: '/Config-fournisseur'
          },
          {
            role: authRole.onlYSupperAdmin,
            id: 'paymentType',
            title: 'paymentType',
            messageId: 'common.typePayment',
            type: 'item',
            url: '/Config-typePaiement'
          }
        ]
      }
    ]
  },

  {
    role: authRole.daf,
    id: 'team',
    title: 'Team',
    messageId: 'sidebar.team',
    type: 'collapse',
    icon: 'groupwork',
    children: [
      {
        role: authRole.daf,
        id: 'teamList',
        title: 'TeamList',
        messageId: 'sidebar.team.teamlist',
        type: 'item',
        url: '/Equipe'
      },
      {
        role: authRole.daf,
        id: 'teamList',
        title: 'TeamList',
        messageId: 'sidebar.team.commercialList',
        type: 'item',
        url: '/Liste-Commercials'
      }
    ]
  },
  {
    role: authRole.onlYSupperAdmin,
    id: 'config',
    title: 'Config',
    messageId: 'sidebar.config',
    type: 'collapse',
    icon: 'settings',
    children: [
      {
        role: authRole.onlYSupperAdmin,
        id: 'setting',
        title: 'setting',
        messageId: 'sidebar.setting',
        type: 'collapse',
        icon: 'settings',
        children: [
          {
            role: authRole.onlYSupperAdmin,
            id: 'service1',
            title: 'service',
            messageId: 'sidebar.config.service.list',
            type: 'item',
            url: '/Liste-services'
          },
          {
            role: authRole.onlYSupperAdmin,
            id: 'supplier',
            title: 'supplier',
            messageId: 'sidebar.config.supplier.list',
            type: 'item',
            url: '/Liste-Fournisseurs'
          },
          {
            role: authRole.onlYSupperAdmin,
            id: 'category1',
            title: 'category',
            messageId: 'sidebar.config.category.List',
            type: 'item',
            url: '/Liste-Categories'
          },
          {
            role: authRole.onlYSupperAdmin,
            id: 'intervenant',
            title: 'interveant',
            messageId: 'sidebar.config.intervenant.list',
            type: 'item',
            url: '/Liste-intervenants'
          },
          {
            role: authRole.onlYSupperAdmin,
            id: 'serviceSupp',
            title: 'serviceSupp',
            messageId: 'sidebar.config.serviceSupp.add',
            type: 'item',
            url: '/Liste-Service-Fournisseur'
          }
        ]
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'setting',
        title: 'setting',
        messageId: 'sidebar.invoice.payment',
        type: 'collapse',
        icon: 'settings',
        children: [
          {
            role: authRole.onlYSupperAdmin,
            id: 'service1',
            title: 'service',
            messageId: 'sidebar.config.organisms.list',
            type: 'item',
            url: '/Liste-organismes'
          }
        ]
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'setting',
        title: 'setting',
        messageId: 'sidebar.tpe.config',
        type: 'collapse',
        icon: 'settings',
        children: [
          {
            role: authRole.onlYSupperAdmin,
            id: 'service1',
            title: 'service',
            messageId: 'sidebar.tpe.config.version',
            type: 'item',
            url: '/Version-TPE'
          }
        ]
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'typevirement',
        title: 'typevirement',
        messageId: 'sidebar.config.typefeed',
        type: 'item',
        url: '/Config-TypeVirement'
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'config.internetSabba',
        title: 'ConfigInternetSabba',
        messageId: 'sidebar.config.internet.sabba',
        type: 'item',
        url: '/topup/sabba'
      }
    ]
  },

  {
    role: authRole.superAdmin,
    id: 'Topup',
    title: 'Topup',
    messageId: 'sidebar.history.topup',
    type: 'collapse',
    icon: 'settingscell',
    children: [
      {
        role: authRole.superAdmin,
        id: 'Topupconfig',
        title: 'Config',
        messageId: 'sidebar.topup.config',
        type: 'collapse',
        children: [
          {
            role: authRole.superAdmin,
            id: 'getOperator',
            title: 'Get operator',
            messageId: 'sidebar.topup.puce',
            type: 'item',
            url: '/topup/config/operator'
          },
          {
            role: authRole.superAdmin,
            id: 'getSim',
            title: 'Get sim',
            messageId: 'Liste carte sim',
            type: 'item',
            url: '/topup/config/simcard'
          },
          {
            role: authRole.superAdmin,
            id: 'getOperator',
            title: 'Get operator',
            messageId: 'Liste cartes',
            type: 'item',
            url: '/topup/config/card'
          },
          {
            role: authRole.superAdmin,
            id: 'getSim',
            title: 'Get sim',
            messageId: 'Liste organisme',
            type: 'item',
            url: '/topup/config/organisme'
          }
        ]
      },
      {
        role: authRole.superAdmin,
        id: 'getSim',
        title: 'Get sim',
        messageId: 'sidebar.history.modem.topup',
        type: 'item',
        url: '/Historique-modem-topup/super'
      }
    ]
  },
  {
    role: authRole.onlYAdmin,
    id: 'Topup',
    title: 'Topup',
    messageId: 'sidebar.history.topup',
    type: 'collapse',
    icon: 'settingscell',
    children: [
      {
        role: authRole.onlYAdmin,
        id: 'Lists',
        title: 'Config',
        messageId: 'sidebar.topup.sims',
        type: 'collapse',
        children: [
          // {
          //   id: 'getOperator',
          //   title: 'Get operator',
          //   messageId: 'Liste operatur',
          //   type: 'item',
          //   url: '/topup/operator',
          // },
          {
            role: authRole.onlYAdmin,
            id: 'getSim',
            title: 'Get sim',
            messageId: 'Liste carte sim',
            type: 'item',
            url: '/Config-Topup-simcard'
          },
          {
            role: authRole.onlYAdmin,
            id: 'getOperator',
            title: 'Get operator',
            messageId: 'Liste cartes',
            type: 'item',
            url: '/Config-Topup-Cartes'
          },
          {
            role: authRole.onlYAdmin,
            id: 'getSim',
            title: 'Get sim',
            messageId: 'sidebar.history.modem.topup',
            type: 'item',
            url: '/Historique-modem-topup'
          }
        ]
      }
    ]
  },
  {
    role: authRole.dafAndAdmin,
    id: 'Voucher',
    title: 'Voucher',
    messageId: 'sidebar.voucher',
    type: 'collapse',
    icon: 'phoneIphone',
    children: [
      {
        role: authRole.dafAndAdmin,
        id: 'getDocuments',
        title: 'Get documents',
        messageId: 'sidebar.config.documents.get',
        type: 'item',
        url: '/Liste-Documents'
      }
    ]
  },
  {
    role: authRole.onlYSupperAdmin,
    id: 'Voucher',
    title: 'Voucher',
    messageId: 'sidebar.voucher',
    type: 'collapse',
    icon: 'phoneIphone',
    children: [
      {
        role: authRole.onlYSupperAdmin,
        id: 'listFacial',
        title: 'List facial',
        messageId: 'sidebar.config.facial',
        type: 'item',
        url: '/Liste-Facials'
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'ListOperator',
        title: 'List operator',
        messageId: 'sidebar.config.operator',
        type: 'item',
        url: '/Liste-Operateurs'
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'getDocuments',
        title: 'Get documents',
        messageId: 'sidebar.config.documents.get',
        type: 'item',
        url: '/Liste-Documents'
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'settings',
        title: 'settings',
        messageId: 'sidebar.config.voucher.settings',
        type: 'item',
        url: '/Liste-Parametres'
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'stock',
        title: 'stock',
        messageId: 'sidebar.config.voucher.stock',
        type: 'item',
        url: '/Stock'
      }
    ]
  },
  {
    role: authRole.onlYSupperAdmin,
    id: 'Poste',
    title: 'Poste',
    messageId: 'poste',
    type: 'collapse',
    icon: 'home-work',
    children: [
      {
        role: authRole.onlYSupperAdmin,
        id: 'listCard',
        title: 'List card',
        messageId: 'cards',
        type: 'item',
        url: '/Liste-Cartes-Poste'
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'listAffiliation',
        title: 'List affiliation',
        messageId: 'affiliation',
        type: 'item',
        url: '/Liste-Affiliation'
      },
      {
        role: authRole.onlYSupperAdmin,
        id: 'transfer',
        title: 'Transfer balance',
        messageId: 'transfer',
        type: 'item',
        url: '/Poste-Transfert'
      }
    ]
  },
  {
    role: authRole.daf,
    id: 'importFromAPI',
    title: 'importFromAPI',
    messageId: 'sidebar.import.From.API',
    type: 'collapse',
    icon: 'home-work',
    children: [
      {
        role: authRole.daf,
        id: 'listCard',
        title: 'List card',
        messageId: 'sidebar.import.ooredoo',
        type: 'item',
        url: '/ImporterDeAPI-Ooredoo'
      },
      {
        role: authRole.daf,
        id: 'listAffiliation',
        title: 'List affiliation',
        messageId: 'sidebar.import.tt',
        type: 'item',
        url: '/importer/Telecom'
      },
      {
        role: authRole.daf,
        id: 'transfer',
        title: 'Transfer balance',
        messageId: 'sidebar.config.ooredoo',
        type: 'item',
        url: '/Config-ooredoo'
      }
    ]
  },
  {
    role: authRole.superAdmin,
    id: 'scopes',
    title: 'scopes',
    messageId: 'sidebar.config.scopes',
    type: 'item',
    url: '/scopes'
  }
];
export default routesConfig;
