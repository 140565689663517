import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Auth from './Auth';
import Configuration from './Configuration';
import StockVoucher from './StockVoucher';
import History from './History';
import Company from './Company';
import Poste from './Poste';
import Alimentation from './Alimentation';
import Mintroute from './Mintroute';
import Fournisseur from './Fournisseurs';
import Digidis from './Digidis';
import Invoices from './Invoices';
import Hotel from './Hotel';
import Abonnement from './Abonnement';
import reclamation from './Reclamation';
import reclamationreducer from './reclamationreducer';

import {listsReducer} from '../../modules/workspace/redux/lists';
import {usersReducer} from '../../modules/workspace/redux/users';
import {projectsReducer} from '../../modules/workspace/redux/projects';
import {listOrderReducer} from '../../modules/workspace/redux/listOrder';
import {ticketsReducer} from '../../modules/workspace/redux/tickets';
import {themeReducer} from '../../modules/workspace/redux/theme';
import listTeams from '../../modules/workspace/redux/listTeams';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    configuration: Configuration,
    voucher: StockVoucher,
    company: Company,
    history: History,
    poste: Poste,
    alimentation: Alimentation,
    invoices: Invoices,
    mintroute: Mintroute,
    hotel: Hotel,
    abonnement: Abonnement,
    reclamationreducer: reclamationreducer,
    reclamation: reclamation,
    projects: projectsReducer,
    lists: listsReducer,
    listOrder: listOrderReducer,
    users: usersReducer,
    tickets: ticketsReducer,
    appTheme: themeReducer,
    fournisseur: Fournisseur,
    digidis: Digidis,
    listTeams
  });
export default reducers;
