import React from 'react';
import {Redirect} from 'react-router-dom';
import {CompanyConfig} from './customers';
import {createRoutes} from '../@crema/utility/Utils';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {homePageConfig} from './home';
import {historyPagesConfig} from './history';
import {stockPagesConfig} from './stock';
import {FeedingPagesConfig} from './alimentation';
import {teamPagesConfig} from './team';
import {PagesConfig} from './configuration';
import {posteRouteConfig} from './poste';
import {AlimentationUserPagesConfig} from './AlimentationUser';
import {ImportAPIPagesConfig} from './ImportFromAPI';
import {MintrouteConfig} from './Mintroute';
import {HotelConfig} from './Hotel';
import {InvoicePaymentConfig} from './InvoicePayment';
import {CommercialConfig} from './Commercial';
import {AbonnementConfig} from './Abonnement';
import {ReclamationConfig} from './workspace';
import {AddReclamation} from './addReclamation';
import {ReclamationRouting} from './reclamation';
import {FournisseurConfig} from './Fournisseurs';
//import {LivraisonRouting} from './Livreur';
import {DigidisConfig} from './Digidis';
import {RecouvrementFparcelRouting} from './CashCollectorFprcel';
import {SuivisRouting} from './SuivisStatistique';
import {RapprochementFparcelRouting} from './RapprochementFparcel';
import {NotificationRouting} from './notification';
import {CompanyConfigV2} from './pdv_V2';

const routeConfigs = [
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...CompanyConfig,
  ...homePageConfig,
  ...historyPagesConfig,
  ...stockPagesConfig,
  ...FeedingPagesConfig,
  ...teamPagesConfig,
  ...PagesConfig,
  ...posteRouteConfig,
  ...AlimentationUserPagesConfig,
  ...ImportAPIPagesConfig,
  ...CommercialConfig,
  ...MintrouteConfig,
  ...HotelConfig,
  ...InvoicePaymentConfig,
  ...AbonnementConfig,
  ...ReclamationConfig,
  ...AddReclamation,
  ...ReclamationRouting,
  ...FournisseurConfig,
  // ...LivraisonRouting,
  ...DigidisConfig,
  ...RecouvrementFparcelRouting,
  ...SuivisRouting,
  ...NotificationRouting,
  ...CompanyConfigV2,
  ...RapprochementFparcelRouting
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />
  }
];

export default routes;
