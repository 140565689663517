import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const SuivisRouting = [
  {
    auth: authRole.dafAndSupport,
    routes: [
      {
        exact: true,
        path: '/duplicated-transaction',
        component: React.lazy(() => import('./page/TransactionDuplicated'))
      },
      {
        exact: true,
        path: '/voucher_notInserted',
        component: React.lazy(() => import('../customers/statistic/voucherNotInsered'))
      },
      {
        exact: true,
        path: '/voucher_Reserved',
        component: React.lazy(() => import('./page/ReservedVoucher'))
      },
      {
        exact: true,
        path: '/corrupted_wallet',
        component: React.lazy(() => import('./page/CorruptedWallet'))
      }
    ]
  }
];
