import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const RecouvrementFparcelRouting = [
  {
    auth: authRole.dafAndSupport,
    routes: [
      {
        exact: true,
        path: '/liste_Affecter',
        component: React.lazy(() => import('./pages/listFparcel'))
      }
    ]
  }
];
