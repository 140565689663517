import IntlMessages from '@crema/utility/IntlMessages';
import {Box, Slide} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
var currentdate = new Date();
currentdate.setMinutes(currentdate.getMinutes() + 10);

export const currentDate = (sp) => {
  var dd = currentdate.getDate();
  var mm = currentdate.getMonth() + 1;
  var yyyy = currentdate.getFullYear();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return yyyy + sp + mm + sp + dd;
};

export const formatDate = (date) => {
  return date ? date.slice(0, 10) : date;
};
export const formatDateAndTime = (date) => {
  var d = new Date(date);
  d = moment(d).format();
  return date ? d.slice(0, 10) + ' , ' + d.slice(11, 16) : date;
};
export const getOperatorImgbyNameDigidis = (operatorName) => {
  const opert = operatorName?.replace(/\s/g, '');
  var operator = {
    orange: '/assets/images/Operateurs/orange.jpg',
    ooredoo: '/assets/images/Operateurs/ooredooNEw.jpg',
    telecom: '/assets/images/Operateurs/tt.jpg',
    télécom: '/assets/images/Operateurs/tt.jpg',
    elissa: '/assets/images/Operateurs/tt.jpg',
    ORANGE: '/assets/images/Operateurs/orange.jpg',
    OOREDOO: '/assets/images/Operateurs/ooredooNEw.jpg',
    TELECOM: '/assets/images/Operateurs/tt.jpg',
    LYCAMOBILE: '/assets/images/Operateurs/lyca.png',
    ASELMOBILE: '/assets/images/Operateurs/AselMobile.png'
  };
  return operator[opert];
};
export const getLabel = (row, arrayValues) => {
  let label;
  if (arrayValues) {
    arrayValues.filter((value) => {
      if (value.id === row) {
        label = value.label;
        return label;
      }
    });
    return label;
  } else return null;
};

export const getOperatorImgbyName = (operatorName) => {
  const opert = operatorName?.replace(/\s/g, '').replace(/\s+/, '');
  var operator = {
    orange: '/assets/images/Operateurs/orange.jpg',
    ooredoo: '/assets/images/Operateurs/ooredooo.jpg',
    telecom: '/assets/images/Operateurs/tt.jpg',
    télécom: '/assets/images/Operateurs/tt.jpg',
    elissa: '/assets/images/Operateurs/tt.jpg',
    ORANGE: '/assets/images/Operateurs/orange.jpg',
    OOREDOO: '/assets/images/Operateurs/ooredooo.jpg',
    TELECOM: '/assets/images/Operateurs/tt.jpg',
    LYCAMOBILE: '/assets/images/Operateurs/lyca.png',
    ASELMOBILE: '/assets/images/Operateurs/asel.jpg',
    INTERNETSABATELECOM: '/assets/images/Operateurs/internetsabatt.png',
    aselmobile: 'assets/images/Operateurs/asel.jpg'
  };
  return operator[opert];
};
const getPaymentStatusColor = (status) => {
  switch (status) {
    case '1000': {
      return '#e69d07';
    }
    case '1100': {
      return '#3db87c';
    }
    case '1101': {
      return '#F84E4E';
    }
    default: {
      return '';
    }
  }
};
const getTransactionsStatusColor = (status) => {
  switch (status) {
    case '1101': {
      return '#e69d07';
    }
    case '1011': {
      return '#3db87c';
    }
    case '1000': {
      return '#F84E4E';
    }
    case '1111': {
      return '#3db87c';
    }
    case '1001': {
      return '#F84E4E';
    }
    default: {
      return '';
    }
  }
};

export const documentVoucherStatus = (statusCode) => {
  var status = {
    1000: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          color: getPaymentStatusColor(statusCode),
          backgroundColor: getPaymentStatusColor(statusCode) + '20'
        }}>
        Importé
      </Box>
    ),
    1100: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          color: getPaymentStatusColor(statusCode),
          backgroundColor: getPaymentStatusColor(statusCode) + '20'
        }}>
        Activé
      </Box>
    ),
    1101: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          color: getPaymentStatusColor(statusCode),
          backgroundColor: getPaymentStatusColor(statusCode) + '20'
        }}>
        <IntlMessages id='common.reject' />
      </Box>
    ),
    null: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          color: getPaymentStatusColor('1101'),
          backgroundColor: getPaymentStatusColor('1101') + '20'
        }}>
        <IntlMessages id='common.reject' />
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          color: getPaymentStatusColor('1000'),
          backgroundColor: getPaymentStatusColor('1000') + '20'
        }}>
        Importé
      </Box>
    )
  };
  return status[statusCode];
};

export const voucherStockStatus = (statusCode) => {
  var status = {
    1000: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          color: getTransactionsStatusColor('1000'),
          fontWeight: 500,
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.initial' />
      </Box>
    ),
    1100: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Validé
      </Box>
    ),
    1001: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        Réservé
      </Box>
    ),
    1101: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1001'),
          backgroundColor: getTransactionsStatusColor('1001') + '20'
        }}>
        <IntlMessages id='common.reject' />
      </Box>
    ),
    1111: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1111'),
          backgroundColor: getTransactionsStatusColor('1111') + '20'
        }}>
        Vendu
      </Box>
    )
  };
  return status[statusCode];
};
export const voucherTransactionsStatus = (statusCode) => {
  var status = {
    1000: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.echoue' />
      </Box>
    ),
    false: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.echoue' />
      </Box>
    ),
    true: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Succès
      </Box>
    ),

    1011: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Accepté
      </Box>
    ),
    1101: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),
    null: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),

    1001: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1001'),
          backgroundColor: getTransactionsStatusColor('1001') + '20'
        }}>
        <IntlMessages id='common.reject' />
      </Box>
    ),
    1111: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1111'),
          backgroundColor: getTransactionsStatusColor('1111') + '20'
        }}>
        Success
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1111'),
          backgroundColor: getTransactionsStatusColor('1111') + '20'
        }}>
        Complet
      </Box>
    ),
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Non complet
      </Box>
    )
  };
  return status[statusCode];
};

export const voucherTransactionsMintrouteStatus = (statusCode) => {
  var status = {
    1000: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.echoue' />
      </Box>
    ),
    false: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.echoue' />
      </Box>
    ),
    true: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Succès
      </Box>
    ),

    1011: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Succès
      </Box>
    ),
    1101: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),
    null: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),

    1001: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1001'),
          backgroundColor: getTransactionsStatusColor('1001') + '20'
        }}>
        <IntlMessages id='common.reject' />
      </Box>
    ),
    1111: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1111'),
          backgroundColor: getTransactionsStatusColor('1111') + '20'
        }}>
        Success
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1111'),
          backgroundColor: getTransactionsStatusColor('1111') + '20'
        }}>
        Succès
      </Box>
    ),
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.echoue' />
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.echoue' />
      </Box>
    )
  };
  return status[statusCode];
};

export const getStatusEnabled = (stat) => {
  var status = {
    true: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Activé
      </Box>
    ),
    false: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Déactivé
      </Box>
    )
  };
  return status[stat];
};
export const times = (x) => (f) => {
  if (x > 0) {
    f();
    times(x - 1)(f);
  }
};
export const getCompletedProfile = (stat) => {
  var status = {
    true: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Fini
      </Box>
    ),
    false: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    )
  };
  return status[stat];
};
const getTransactionReclamationStatus = (status) => {
  switch (status) {
    case '0': {
      return '#003255';
    }
    case '1': {
      return '#e69d07';
    }
    case '4': {
      return '#3DB87C';
    }
    case '3': {
      return '#f84e4e';
    }
    case '2': {
      return '#003255';
    }
  }
};
const getPayoutsStatus = (status) => {
  switch (status) {
    case '1': {
      return '#3db87c';
    }
    case '0': {
      return '#f84e4e';
    }
  }
};
const serviceColor = () => {
  switch (status) {
    case 'TECH': {
      return '#003255';
    }
    case 'FINANCE': {
      return '#e69d07';
    }
    case 'Commercial': {
      return '#3DB87C';
    }
  }
};
export const ReclamationEtat = (statusCode) => {
  var etat = {
    1: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('1'),
          backgroundColor: getTransactionReclamationStatus('1') + '20'
        }}>
        En cours
      </span>
    ),
    0: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('0'),
          backgroundColor: getTransactionReclamationStatus('0') + '20'
        }}>
        Initial
      </span>
    ),
    2: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('2'),
          backgroundColor: getTransactionReclamationStatus('2') + '20'
        }}>
        Traitée
      </span>
    ),
    3: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('3'),
          backgroundColor: getTransactionReclamationStatus('3') + '20'
        }}>
        Annulée
      </span>
    ),
    4: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionReclamationStatus('4'),
          backgroundColor: getTransactionReclamationStatus('4') + '20'
        }}>
        Cloturée
      </span>
    )
  };
  return etat[statusCode];
};
export const PayoutsEtat = (statusCode) => {
  var etat = {
    true: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getPayoutsStatus('1'),
          backgroundColor: getPayoutsStatus('1') + '20'
        }}>
        Rapprochée
      </span>
    ),
    false: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getPayoutsStatus('0'),
          backgroundColor: getPayoutsStatus('0') + '20'
        }}>
        Non rapprochée
      </span>
    )
  };
  return etat[statusCode];
};
export const formatDateExtract = (date) => {
  var d = new Date(date);
  d = moment(d).format();
  return d.slice(0, 10) + ', ' + d.slice(11, 13) + ':' + d.slice(14, 16);
};

export const getCompletedemail = (stat) => {
  var status = {
    true: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Confirmé
      </Box>
    ),
    false: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    )
  };
  return status[stat];
};
export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});
export const FormatSolde = (props) => {
  var sld = '';
  var sold = '';
  if (props) {
    sld = props.toString();
    var position = sld.length - 3;
    var dt = sld.substr(0, position);
    var mm = sld.substr(position, sld.length);
    sold = dt + mm + ' TND';
  } else {
    sold = '0 TND';
  }
  return sold;
};
export const hasSillFunction = (stat) => {
  var status = {
    true: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Vrai
      </Box>
    ),
    false: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Faux
      </Box>
    )
  };
  return status[stat];
};
export const paymentfactureStatus = (statusCode) => {
  var status = {
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Accepté
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Rejeté
      </Box>
    )
  };
  return status[statusCode];
};
export const hotelStatus = (statusCode) => {
  var status = {
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Réservé
      </Box>
    ),
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Annulé
      </Box>
    )
  };
  return status[statusCode];
};

export const historyTopupStatus = (statusCode) => {
  var status = {
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Succèss
      </Box>
    ),
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Echoué
      </Box>
    )
  };
  return status[statusCode];
};
export const simState = (statusCode) => {
  var status = {
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Occupée
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Libre
      </Box>
    )
  };
  return status[statusCode];
};
export const simActivate = (statusCode) => {
  var status = {
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Déactivé
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Activé
      </Box>
    )
  };
  return status[statusCode];
};
export const yesAndNoInfo = (statusCode) => {
  var status = {
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '5px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        NON
      </Box>
    ),
    success: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Success
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '5px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        OUI
      </Box>
    ),
    interested: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '5px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        OUI
      </Box>
    ),
    oui: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '5px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        OUI
      </Box>
    ),
    'not interested': (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '5px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        NON
      </Box>
    ),
    non: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '5px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        NON
      </Box>
    ),
    hesitated: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        Hésité
      </Box>
    )
  };
  return status[statusCode];
};
export const statusDemandPos = (statusCode) => {
  var status = {
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Rejeté
      </Box>
    ),
    3: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Accepté
      </Box>
    )
  };
  return status[statusCode];
};
export const AlimenterUserStatus = (statusCode) => {
  var status = {
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.reject' />
      </Box>
    ),
    3: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Accepté
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    )
  };
  return status[statusCode];
};

export const TypeWalletStatus = (statusCode) => {
  var status = {
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '130px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Encaissement
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '130px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        Crédit
      </Box>
    )
  };
  return status[statusCode];
};

export const getBankImgbyName = (bankName) => {
  var bank = {
    'TIJARRI BANK': '/assets/images/organismes/BAnk attijari.jpg',
    CASH: '/assets/images/organismes/Billet4.jpeg',
    STB: '/assets/images/organismes/stb bank.jpg',
    ATTIJARI: '/assets/images/organismes/BAnk attijari.jpg',
    TIJERI: '/assets/images/organismes/BAnk attijari.jpg',
    BH: '/assets/images/organismes/BH.png',
    POSTE: '/assets/images/organismes/poste.jpg',
    BIAT: '/assets/images/organismes/biat.jpg',
    ZITOUNA: '/assets/images/organismes/banque zitouna.jpg',
    UIB: '/assets/images/organismes/uib.jpg',
    CLICTOPAY: '/assets/images/organismes/clicktopay.jpg',
    SONEDE: '/assets/images/organismes/sonede.jpg',
    null: '/assets/images/organismes/Billet4.jpeg'
  };

  return bankName ? bank[bankName] : bank.CASH;
};
export const getSalesNamebyCode = (sales) => {
  var name = {
    1000: 'Paiement de facture',
    1001: 'Vouchers',
    1010: 'Topup'
  };

  return sales ? name[sales] : name.CASH;
};
export const cities = [
  {
    value: 'Tunis',
    label: 'Tunis'
  },
  {
    value: 'Ariana',
    label: 'Ariana'
  },
  {
    value: 'Ben Arous',
    label: 'Ben Arous'
  },
  {
    value: 'Manouba',
    label: 'Manouba'
  },
  {
    value: 'Nabeul',
    label: 'Nabeul'
  },
  {
    value: 'Zaghouan',
    label: 'Zaghouan'
  },
  {
    value: 'Bizerte',
    label: 'Bizerte'
  },
  {
    value: 'Béja',
    label: 'Béja'
  },
  {
    value: 'Jendouba',
    label: 'Jendouba'
  },
  {
    value: 'Kef',
    label: 'Kef'
  },
  {
    value: 'Siliana',
    label: 'Siliana'
  },
  {
    value: 'Sousse',
    label: 'Sousse'
  },

  {
    value: 'Monastir',
    label: 'Monastir'
  },
  {
    value: 'Mahdia',
    label: 'Mahdia'
  },
  {
    value: 'Sfax',
    label: 'Sfax'
  },
  {
    value: 'Kairouan',
    label: 'Kairouan'
  },
  {
    value: 'Kasserine',
    label: 'Kasserine'
  },
  {
    value: 'Sidi Bouzid',
    label: 'Sidi Bouzid'
  },
  {
    value: 'Gabès',
    label: 'Gabès'
  },
  {
    value: 'Mednine',
    label: 'Mednine'
  },
  {
    value: 'Tataouine',
    label: 'Tataouine'
  },
  {
    value: 'Gafsa',
    label: 'Gafsa'
  },
  {
    value: 'Tozeur',
    label: 'Tozeur'
  },
  {
    value: 'Kebili',
    label: 'Kebili'
  }
];

export const iziPay = [
  {
    value: '',
    label: 'Aucun'
  },
  {
    value: 'true',
    label: 'Agent IziPay'
  },
  {
    value: 'false',
    label: 'Agent non IziPay'
  }
];

export const status = [
  {
    value: '1',
    label: 'Actif'
  },
  {
    value: '0',
    label: 'Inactif'
  }
];
const getTransactionTpeStatus = (status) => {
  switch (status) {
    case '0': {
      return '#e69d07';
    }
    case '1': {
      return '#3DB87C';
    }
    case '2': {
      return '#f84e4e';
    }
  }
};
const getClotureJourney = (etat) => {
  switch (etat) {
    case 'true': {
      return '#e69d07';
    }
    case 'false': {
      return '#3DB87C';
    }
  }
};
const ETATTPESALES = (status) => {
  switch (status) {
    case '1': {
      return '#3db87c';
    }
    case '0': {
      return '#f84e4e';
    }
  }
};
// cash collect etat :
const getTransactionCollectStatus = (status) => {
  switch (status) {
    case '0': {
      return '#e69d07';
    }
    case '1': {
      return '#3DB87C';
    }
    case '2': {
      return '#f84e4e';
    }
    case '3': {
      return '#4e4ef8';
    }
    case '4': {
      return '#3DB87C';
    }
  }
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const uploadMultipleFiles = async (e) => {
  e.preventDefault();
  let f;
  f = await getBase64(e.target.files[0]);
  return f;
};

export const getGoldSilverIcons = (name) => {
  switch (name.toUpperCase()) {
    case 'GOLD': {
      return <img style={{width: '25px'}} alt='GOLD' src='/assets/images/icons/gold.png' />;
    }
    case 'BRONZE': {
      return <img style={{width: '25px'}} alt='bronze' src='/assets/images/icons/bronz.png' />;
    }
    case 'SILVER': {
      return <img style={{width: '25px'}} alt='silver' src='/assets/images/icons/silver.png' />;
    }
    default: {
      return '';
    }
  }
};
export const LogoOrganisme = (props) => {
  if (props === 'steg' || props === '0701' || props === 'STEG')
    return '/assets/images/organismes/steg.jpg';
  if (props === 'topnet' || props === 'TOPNET') return '/assets/images/organismes/topnet.jpg';
  if (props === 'CNSS' || props === 'cnss') return '/assets/images/organismes/cnss.jpg';
  if (props === 'radar' || props === '0502' || props === 'RADAR')
    return '/assets/images/organismes/amendeRadar.webp';
  if (props === 'tunisie-autoroute' || props === '0206' || props === 'AUTOROUTE')
    return '/assets/images/organismes/tunisieAutoroute.png';
  if (
    props === 'telecom' ||
    props === '0151' ||
    props === '0152' ||
    props === '0153' ||
    props === '0154' ||
    props === '0380' ||
    props === '0381' ||
    props === '0382' ||
    props === '0383' ||
    props === '0384' ||
    props === '0385' ||
    props === 'TELECOM'
  )
    return '/assets/images/organismes/tt.jpg';
  if (props === 'orange' || props === '0737' || props === '0763' || props === 'ORANGE')
    return '/assets/images/organismes/orange.jpg';
  if (props === 'ooredoo' || props === '0777' || props === 'OOREDOO')
    return '/assets/images/organismes/ooredooo.jpg';
  if (props === '7' || props === '0770') return '/assets/images/organismes/topnet.jpg';
  if (props === 'SONEDE') return '/assets/images/organismes/sonede.jpg';
};
export const invoicePaymentsStatus = (statusCode) => {
  var status = {
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        <IntlMessages id='common.echoue' />
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Succès
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),
    null: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        En attente
      </Box>
    ),
    1001: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1001'),
          backgroundColor: getTransactionsStatusColor('1001') + '20'
        }}>
        <IntlMessages id='common.reject' />
      </Box>
    ),
    1111: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1111'),
          backgroundColor: getTransactionsStatusColor('1111') + '20'
        }}>
        Success
      </Box>
    )
  };
  return status[statusCode];
};

export const downloadImage = (e, path) => {
  console.log(path, 'pathpathpathpathpathpath');
  fetch(path, {
    method: 'GET',
    headers: {}
  })
    .then((response) => {
      console.log('responseresponseresponseresponse', response);
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'image.' + path.split('.').pop());
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => console.log('error in download image'));
};

export const historiquecommercialStatus = (datetable) => {
  const dweek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const dmonth = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  var status =
    new Date(datetable) < dmonth ? (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '200px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1000'),
          backgroundColor: getTransactionsStatusColor('1000') + '20'
        }}>
        Plus d'un mois
      </Box>
    ) : new Date(datetable) < dweek ? (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '200px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1101'),
          backgroundColor: getTransactionsStatusColor('1101') + '20'
        }}>
        Plus d'une semaine
      </Box>
    ) : (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '200px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionsStatusColor('1011'),
          backgroundColor: getTransactionsStatusColor('1011') + '20'
        }}>
        Nouveau
      </Box>
    );
  return status;
};
export const TpeVenteStatus = (statusCode) => {
  var status = {
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionTpeStatus('1'),
          backgroundColor: getTransactionTpeStatus('1') + '20'
        }}>
        Accepté
      </Box>
    ),
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionTpeStatus('0'),
          backgroundColor: getTransactionTpeStatus('0') + '20'
        }}>
        Demande
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionTpeStatus('2'),
          backgroundColor: getTransactionTpeStatus('2') + '20'
        }}>
        rejeter
      </Box>
    )
  };
  return status[statusCode];
};
export const TpeVenEtatPaiement = (statusCode) => {
  var status = {
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: ETATTPESALES('1'),
          backgroundColor: ETATTPESALES('1') + '20'
        }}>
        <IntlMessages id='common.etat.payed' />
      </Box>
    ),
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: ETATTPESALES('0'),
          backgroundColor: ETATTPESALES('0') + '20'
        }}>
        <IntlMessages id='commmon.etat.notpayed' />
      </Box>
    )
  };
  return status[statusCode];
};

export const collectStatus = (statusCode) => {
  var status = {
    0: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionCollectStatus('0'),
          backgroundColor: getTransactionCollectStatus('0') + '20'
        }}>
        Initial
      </Box>
    ),
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionCollectStatus('1'),
          backgroundColor: getTransactionCollectStatus('1') + '20'
        }}>
        Affectée
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionCollectStatus('2'),
          backgroundColor: getTransactionCollectStatus('2') + '20'
        }}>
        Refusée
      </Box>
    ),
    3: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionCollectStatus('3'),
          backgroundColor: getTransactionCollectStatus('3') + '20'
        }}>
        Collectée
      </Box>
    ),
    4: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '10px',
          fontWeight: 500,
          color: getTransactionCollectStatus('4'),
          backgroundColor: getTransactionCollectStatus('4') + '20'
        }}>
        Alimentée
      </Box>
    )
  };
  return status[statusCode];
};
export const statusHistory = (statusCode) => {
  var status = {
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionCollectStatus('2'),
          backgroundColor: getTransactionCollectStatus('2') + '20'
        }}>
        Collectée
      </Box>
    ),

    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getTransactionCollectStatus('1'),
          backgroundColor: getTransactionCollectStatus('1') + '20'
        }}>
        Non collecter
      </Box>
    )
  };
  return status[statusCode];
};

export const AvisReclamation = (avis) => {
  var emoji = {
    1: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '18px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500
        }}>
        😡
      </Box>
    ),
    2: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '18px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500
          //color: getTransactionTpeStatus('1'),
          //backgroundColor: getTransactionTpeStatus('1') + '20',
        }}>
        😔
      </Box>
    ),
    3: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '18px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500
          //color: getTransactionTpeStatus('1'),
          //backgroundColor: getTransactionTpeStatus('1') + '20',
        }}>
        🤨
      </Box>
    ),
    4: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '18px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          //color: getTransactionTpeStatus('1'),
          backgroundColor: '#D3D3D3'
        }}>
        🙂
      </Box>
    ),
    5: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '18px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500
          //color: getTransactionTpeStatus('1'),
          //backgroundColor: getTransactionTpeStatus('1') + '20',
        }}>
        😄
      </Box>
    )
  };
  return emoji[avis];
};
export const getServiceSupp = (serviceSupp, amount) => {
  if (amount === 5) {
    if (serviceSupp === 'VoucherTT') {
      return 'VOUCHER_TT_5DT';
    } else if (serviceSupp === 'VoucherOO') {
      return 'VOUCHER_OO_5DT';
    } else if (serviceSupp === 'VoucherOR') {
      return 'VOUCHER_OR_5DT';
    }
  } else return serviceSupp;
};

export const convertServiceSupplierArrayToObject = (array, key) => {
  const initialValue = {};
  ////console.log(array,key,"heneeee")
  return array.reduce((obj, item) => {
    ////console.log(obj,item,"heneeee")
    return {
      ...obj,
      [item[key]]: item.id
    };
  }, initialValue);
};

export const getMessageByCode = (code) => {
  switch (code) {
    case 'TPE': {
      return 'Problème TPE';
    }
    case 'VCI': {
      return 'Problème technique lié à impression des vouchers';
    }
    case 'SWA': {
      return 'Problème de solde Wallet';
    }
    case 'VCU': {
      return 'Code voucher utilisé';
    }
  }
};
/*export const EtatJourney = (cloture) => {
  return cloture ? (
    <Box
      style={{
        pointerEvents: 'none',
        fontSize: '18px',
        width: '100px',
        padding: '10px',
        textAlign: 'center',
        borderRadius: '5px',
        fontWeight: 500,
        color: getClotureJourney('true'),
        backgroundColor: getClotureJourney('true'),
      }}
    >
      Clôturée
    </Box>
  ) : (
    <Box
      style={{
        pointerEvents: 'none',
        fontSize: '18px',
        width: '100px',
        padding: '10px',
        textAlign: 'center',
        borderRadius: '5px',
        fontWeight: 500,
        color: getClotureJourney("false"),
        backgroundColor: getClotureJourney("false"),
      }}
    >
      Non clôturée
    </Box>
  )}*/
export const EtatJourney = (statusCode) => {
  var status = {
    false: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getClotureJourney(false),
          backgroundColor: getClotureJourney(false) + '20'
        }}>
        Non Cloturé
      </Box>
    ),

    true: (
      <Box
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          width: '100px',
          padding: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: getClotureJourney(true),
          backgroundColor: getClotureJourney(true) + '20'
        }}>
        Cloturée
      </Box>
    )
  };
  return status[statusCode];
};
export const serviceComercial = (statusCode) => {
  var etat = {
    TECH: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: serviceColor('TECH'),
          backgroundColor: serviceColor('TECH') + '20'
        }}>
        Technique
      </span>
    ),
    FINANCE: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: serviceColor('FINANCE'),
          backgroundColor: serviceColor('FINANCE') + '20'
        }}>
        Finance
      </span>
    ),
    Commercial: (
      <span
        style={{
          pointerEvents: 'none',
          fontSize: '15px',
          paddingLeft: '10px',
          paddingRight: '10px',
          textAlign: 'center',
          borderRadius: '5px',
          fontWeight: 500,
          color: serviceColor('COMMERCIAL'),
          backgroundColor: serviceColor('COMMERCIAL') + '20'
        }}>
        Commercial
      </span>
    )
  };
  return etat[statusCode];
};
export const getServiceByColor = (code) => {
  switch (code) {
    case 'TECH': {
      return (
        <>
          <span
            style={{
              pointerEvents: 'none',
              fontSize: '15px',
              paddingLeft: '10px',
              paddingRight: '10px',
              textAlign: 'center',
              borderRadius: '5px',
              fontWeight: 500,
              color: '#333333',
              backgroundColor: '#5784BA'
            }}>
            Technique
          </span>
        </>
      );
    }
    case 'FINANCE': {
      return (
        <>
          <span
            style={{
              pointerEvents: 'none',
              fontSize: '15px',
              paddingLeft: '10px',
              paddingRight: '10px',
              textAlign: 'center',
              borderRadius: '5px',
              fontWeight: 500,
              color: '#333333',
              backgroundColor: '#E58685'
            }}>
            FINANCE
          </span>
        </>
      );
    }
    case 'Commercial': {
      return (
        <>
          <span
            style={{
              pointerEvents: 'none',
              fontSize: '15px',
              paddingLeft: '10px',
              paddingRight: '10px',
              textAlign: 'center',
              borderRadius: '5px',
              fontWeight: 500,
              color: '#333333',
              backgroundColor: '#A4D4A2'
            }}>
            Commercial
          </span>
        </>
      );
    }
  }
};
