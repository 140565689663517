import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const PagesConfig = [
  {
    auth: authRole.admin,
    routes: [
      {
        exact: true,
        path: '/Liste-organismes',
        component: React.lazy(() => import('./invoicePayment/organisms.js'))
      },
      {
        exact: true,
        path: '/service/add',
        component: React.lazy(() => import('./Services/form'))
      },
      {
        exact: true,
        path: '/Liste-Fournisseurs/Modifier',
        component: React.lazy(() => import('./Supplier/update'))
      },
      {
        exact: true,
        path: '/Liste-Fournisseurs',
        component: React.lazy(() => import('./Supplier/list'))
      },
      {
        exact: true,
        path: '/Liste-Categories',
        component: React.lazy(() => import('./Category/ListCategory'))
      },
      {
        exact: true,
        path: '/Liste-services',
        component: React.lazy(() => import('./Services/list'))
      },
      {
        exact: true,
        path: '/service/details',
        component: React.lazy(() => import('./Supplier/Detail/index'))
      },
      {
        exact: true,
        path: '/Liste-Fournisseurs/Ajouter',
        component: React.lazy(() => import('./Supplier/supplier'))
      },
      {
        exact: true,
        path: '/Liste-Fournisseurs/details',
        component: React.lazy(() => import('./Supplier/Detail/index'))
      },
      {
        exact: true,
        path: '/Liste-intervenants',
        component: React.lazy(() => import('./Intervenant/ListIntervenants'))
      },
      {
        exact: true,
        path: '/Config-TypeVirement',
        component: React.lazy(() => import('./typeVirement/Pages/Listtypes'))
      },
      {
        exact: true,
        path: '/Liste-Service-Fournisseur',
        component: React.lazy(() => import('./ServiceSupplier/ServiceSupplierList/index'))
      },
      {
        exact: true,
        path: '/Liste-Service-Fournisseur/Ajouter',
        component: React.lazy(() => import('./ServiceSupplier/AddServiceSupplier/index'))
      },
      {
        exact: true,
        path: '/Liste-Service-Fournisseur/Modifier',
        component: React.lazy(() => import('./ServiceSupplier/Update/Update'))
      },
      {
        exact: true,
        path: '/Liste-Service-Fournisseur/Details',
        component: React.lazy(() => import('./ServiceSupplier/Details/index'))
      },
      {
        exact: true,
        path: '/Liste-Facials',
        component: React.lazy(() => import('./VoucherSettings/Facial/Listfacials'))
      },
      {
        exact: true,
        path: '/Liste-Operateurs',
        component: React.lazy(() => import('./VoucherSettings/Operator/ListOperators'))
      }
    ]
  },

  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/change-pwd',
        component: React.lazy(() => import('./changePassword/ChangePwd'))
      }
    ]
  },
  {
    auth: authRole.admin,
    routes: [
      {
        exact: true,
        path: '/Version-TPE',
        component: React.lazy(() => import('./TPEVersion/Pages/Version'))
      },
      {
        exact: true,
        path: '/topup/sabba',
        component: React.lazy(() => import('./TopupSettings/AmountInternetSabba/index'))
      }
    ]
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Liste-Documents/Importer',
        component: React.lazy(() => import('./VoucherSettings/AddLot/AddLot'))
      },
      {
        exact: true,
        path: '/Liste-Documents',
        component: React.lazy(() => import('./VoucherSettings/AddLot/ValidateDocument'))
      },
      {
        exact: true,
        path: '/Liste-Parametres',
        component: React.lazy(() => import('./VoucherSettings/Vsettings/LitSettings'))
      },
      {
        exact: true,
        path: '/Stock',
        component: React.lazy(() => import('./VoucherSettings/Stock/stock'))
      },
      {
        exact: true,
        path: '/VerifierVoucher',
        component: React.lazy(() => import('./VoucherSettings/verification/verif_voucher.js'))
      },
      {
        exact: true,
        path: '/topup/operator/add',
        component: React.lazy(() => import('./TopupSettings/Config/Operator/AddOperator'))
      },
      {
        exact: true,
        path: '/topup/operator/update',
        component: React.lazy(() => import('./TopupSettings/Config/Operator/UpdateOperator'))
      }

      // {exact: true,
      //   path: '/topup/operator',
      //   component: React.lazy(() =>
      //     import('./TopupSettings/SIM/Operator/getOperator'),
      //   ),
      // },
    ]
  },
  {
    auth: authRole.onlYAdmin,
    routes: [
      {
        exact: true,
        path: '/Config-Topup-simcard',
        component: React.lazy(() => import('./TopupSettings/SIM/SimCard/getSim'))
      },
      {
        exact: true,
        path: '/Config-Topup-Cartes',
        component: React.lazy(() => import('./TopupSettings/SIM/Card/getCard'))
      },
      {
        exact: true,
        path: '/Historique-modem-topup',
        component: React.lazy(() => import('./TopupSettings/HistoryTopup/TopupModem'))
      }
    ]
  },
  {
    auth: ['ROLE_SUPER_ADMIN'],
    routes: [
      {
        exact: true,
        auth: ['ROLE_SUPER_ADMIN'],
        path: '/topup/config/operator',
        component: React.lazy(() => import('./TopupSettings/Config/Operator/getOperator'))
      },
      {
        exact: true,
        auth: ['ROLE_SUPER_ADMIN'],
        path: '/topup/config/simcard',
        component: React.lazy(() => import('./TopupSettings/Config/SimCard/getSim'))
      },
      {
        exact: true,
        auth: ['ROLE_SUPER_ADMIN'],
        path: '/topup/config/organisme',
        component: React.lazy(() => import('./TopupSettings/Config/Organisme/getOrganisme'))
      },
      {
        exact: true,
        auth: ['ROLE_SUPER_ADMIN'],
        path: '/topup/config/card',
        component: React.lazy(() => import('./TopupSettings/Config/Card/getCard'))
      },
      {
        exact: true,
        auth: ['ROLE_SUPER_ADMIN'],
        path: '/Historique-modem-topup/super',
        component: React.lazy(() => import('./TopupSettings/HistoryTopup/TopupModem'))
      },
      {
        exact: true,
        path: '/scopes',
        component: React.lazy(() => import('./scopes/index'))
      }
    ]
  }
];
