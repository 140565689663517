import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const ImportAPIPagesConfig = [
  {
    auth: authRole.daf,
    routes: [
      {
        path: '/ImporterDeAPI-Ooredoo',
        component: React.lazy(() => import('./Pages/ImportListOO'))
      }
    ]
  },
  {
    auth: authRole.daf,
    routes: [
      {
        path: '/importer/Telecom',
        component: React.lazy(() => import('./Pages/ImportTT'))
      }
    ]
  },
  {
    auth: authRole.daf,
    routes: [
      {
        path: '/Config-ooredoo',
        component: React.lazy(() => import('./Pages/ConfigOO'))
      }
    ]
  }
];
